import React from 'react';

const TextArea = ({ label, value, required = false, myCHange, ...props }) => {
  let handleChange = (cChange) => {
    myCHange = cChange;
  };
  return (
    <div className="form-group">
      {label && <label htmlFor="comment">{label}</label>}
      <span className="required-star">{required ? '*' : ''}</span>
      <textarea
        className="form-control"
        id="comment"
        rows="2"
        onChange={handleChange(myCHange)}
        {...props}
        defaultValue={value ? value : ''}
      ></textarea>
    </div>
  );
};

export default TextArea;

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import { resourceService } from '../../service/resource.service';
import TinyLoader from './components/loaders/TinyLoader';

const AllResourceType = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [resourceTypeProgress, setResourceTypeProgress] = useState(false);
  const [openResourceTypeModal, setOpenResourceTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedResource, setSelectedResource] = useState();
  const [resourceTypeInput, setResourceTypeInput] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    getAllResourceType();
  }, []);

  const getAllResourceType = () => {
    setInProgress(true);
    resourceService
      .getAllResourceType()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createResourceType = () => {
    if (resourceTypeInput.title === '' || resourceTypeInput.title.length < 3)
      toast.error('Provide valid title');
    else if (
      resourceTypeInput.description === '' ||
      resourceTypeInput.description.length < 3
    )
      toast.error('Valid description required');

    if (
      resourceTypeInput.title !== '' &&
      resourceTypeInput.title.length >= 3 &&
      resourceTypeInput.description !== '' &&
      resourceTypeInput.description.length >= 3
    ) {
      const request = {
        title: resourceTypeInput.title,
        description: resourceTypeInput.description,
      };
      setResourceTypeProgress(true);

      if (isEditing) {
        if (selectedResource) {
          resourceService
            .updateResourceType(selectedResource.rtuid, request)
            .then((result) => {
              setOpenResourceTypeModal(false);
              setResourceTypeProgress(false);
              if (result.data.success) {
                toast.success('Updated successful');
                getAllResourceType();
              } else {
                toast.error(result.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error(e.response.data.message);
              setOpenResourceTypeModal(false);
              setResourceTypeProgress(false);
            });
        } else {
          toast.error('No product selected');
        }
      } else {
        resourceService
          .createResourceType(request)
          .then((result) => {
            setOpenResourceTypeModal(false);
            setResourceTypeProgress(false);
            if (result.data.success) {
              toast.success('Created successful');
              getAllResourceType();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenResourceTypeModal(false);
            setResourceTypeProgress(false);
          });
      }
    }
  };

  const performDelete = () => {
    console.log(selectedResource);
    if (selectedResource) {
      setDeleteProgress(true);
      resourceService
        .deleteResourceType(selectedResource.rtuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success && result.data.data.deletedCount > 0) {
            getAllResourceType();
            toast.success('Deleted successful');
          } else {
            toast.error('Unable to delete resource type');
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No resource type selected');
    }
  };

  const closeResourceTypeModal = () => {
    setIsEditing(false);
    setOpenResourceTypeModal(false);
  };

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openResourceTypeModal}>
          <DialogTitle>{isEditing ? 'Update Type' : 'Add Type'}</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="title"
              value={resourceTypeInput.title}
              placeholder="Title"
              label="Type Title"
              onChange={(e) =>
                setResourceTypeInput({
                  ...resourceTypeInput,
                  title: e.target.value,
                })
              }
              required
            />

            <InputField
              name="description"
              label="Description"
              placeholder="Description"
              value={resourceTypeInput.description}
              onChange={(e) =>
                setResourceTypeInput({
                  ...resourceTypeInput,
                  description: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={closeResourceTypeModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={!resourceTypeProgress ? createResourceType : null}
              className="btn btn-primary btn-round"
            >
              {resourceTypeProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteModal}>
          <DialogTitle>Delete Resource Type</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this resource type?</p>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : 'Yes'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Resource type</h4>

                    <button
                      type="button"
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setResourceTypeInput({
                          title: '',
                          description: '',
                        });
                        setIsEditing(false);
                        setOpenResourceTypeModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Resource Type
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {inProgress ? (
                        <Loader></Loader>
                      ) : response.length > 0 ? (
                        <table
                          id="add-row"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>S/n</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>ResourceType ID</th>
                              <th>Status</th>
                              <th>Date Added</th>
                              <th style={{ width: '10%' }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {response.map((product, index) => {
                              return (
                                <tr key={product.ruid}>
                                  <td>{++index}</td>
                                  <td>{product.title}</td>
                                  <td>{product.description}</td>
                                  <td>{product.rtuid}</td>
                                  <td
                                    className={
                                      product.status === 'ACTIVE'
                                        ? 'text-success'
                                        : 'text-danger'
                                    }
                                  >
                                    {product.status}
                                  </td>
                                  <td>{format(product.createdAt)}</td>

                                  <td>
                                    <div className="form-button-action">
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-primary  "
                                          data-original-title="Edit Task"
                                          onClick={() => {
                                            setResourceTypeInput({
                                              title: product.title,
                                              description: product.description,
                                            });
                                            setIsEditing(true);
                                            setSelectedResource(product);
                                            setOpenResourceTypeModal(true);
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-danger"
                                        onClick={() => {
                                          console.log(product);
                                          setSelectedResource(product);
                                          setOpenDeleteModal(true);
                                        }}
                                        data-original-title="Remove"
                                      >
                                        <i className="  fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center message-box">
                          <p>No resource type found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default AllResourceType;

import React, { useState, useEffect } from 'react';
import { resourceService } from '../../service/resource.service';
import OtherPageBody from '../users/OtherPageBody';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import { ToastContainer, toast } from 'react-toastify';
import OptionField from './components/select/OptionField';
import FileInput from './components/inputs/FileInput';
import { util } from '../../utils/constants/constants';
import { location } from '../../utils/constants/location';
import { ResourceCategory } from '../../enums/ResourceCategory';
import { DocumentType } from '../../enums/DocumentType';
import { fileService } from '../../service/file.service';
import { useNavigate } from 'react-router-dom';
import { titleCase } from 'title-case';
import TinyLoader from './components/loaders/TinyLoader';
var FormData = require('form-data');

const AddResource = () => {
  const inputData = {
    name: '',
    model: '',
    color: '',
    description: '',
    identityNumber: '',
    carton: 'No',
    serialNumber: '',
    picture: '',
    type: '',
    size: '',
    ownedDate: '',
    receipt: '',
    legalDocuments: '',
    state: '',
    lga: '',
    address: '',
    landmark: '',
    witness1Name: '',
    witness1PhoneNumber: '',
    witness1Address: '',
    witness2Name: '',
    witness2PhoneNumber: '',
    witness2Address: '',
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingState, setProcessingState] = useState('Preparing...');
  const [resType, setResType] = useState(['Select Resource type...']);
  const [selectedType, setSelectedType] = useState();
  const [input, setInput] = useState(inputData);
  const [step, setStep] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const [area, setAreas] = useState(['Select area...']);
  const [receiptFile, setReceiptFile] = useState();
  const [cartonFile, setCartonFile] = useState();
  const [legalFiles, setLegalFiles] = useState();
  const navigate = useNavigate();

  const onSelectFile = (e, type) => {
    var fileData = [];
    const files = e.target.files;

    if (files.length > 0) {
      if (files.length === 1) {
        fileData.push(files[0]);
      } else {
        for (let i = 0; i < files.length; i++) {
          let file = files.item(i);
          fileData.push(file);
        }
      }

      console.log('fileData', fileData);
      if (type === DocumentType.Receipt) setReceiptFile(fileData);
      if (type === DocumentType.CartonPicture) setCartonFile(fileData);
      if (type === DocumentType.LegalDocument) setLegalFiles(fileData);
    }
  };

  const smartDeviceValid = () => {
    if (input.color === '' || input.color === 'Select Color...')
      toast.error('Device color required');
    else if (input.identityNumber === '' || input.identityNumber.length < 3)
      toast.error('Device IMEI required');
    else if (input.carton === '' || input.carton === 'Does it have carton?')
      toast.error('Does it have carton?');

    if (input.carton === 'Yes') {
      if (input.cartonSerial === '' || input.cartonSerial.length < 3)
        toast.error('Carton Serial required');
      else if (input.picture === '' || input.picture === null)
        toast.error('Carton Picture required');
    }

    if (
      input.color !== '' &&
      input.color !== 'Select Color...' &&
      input.identityNumber !== '' &&
      input.identityNumber.length >= 3 &&
      input.carton !== '' &&
      input.carton !== 'Does it have carton?'
    ) {
      if (input.carton === 'Yes') {
        if (
          input.cartonSerial !== '' &&
          input.cartonSerial.length >= 3 &&
          input.picture !== '' &&
          input.picture !== null
        ) {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }

    return false;
  };

  const vehicleValid = () => {
    if (input.color === '' || input.color === 'Select Color...')
      toast.error('Vehicle color required');
    else if (input.identityNumber === '' || input.identityNumber.length < 3)
      toast.error('Chassis number required');
    if (
      input.color !== '' &&
      input.color !== 'Select Color...' &&
      input.identityNumber !== '' &&
      input.identityNumber.length >= 3
    ) {
      return true;
    } else {
      return false;
    }
  };

  const landHouseValid = () => {
    if (input.size === '' || input.size.length < 2)
      toast.error('Valid size required');
    else if (input.state === '' || input.state === 'Select location...')
      toast.error('Location required');
    else if (input.lga === '' || input.lga === 'Select area...')
      toast.error('Local area required');
    else if (input.landmark === '' || input.landmark.length < 3)
      toast.error('Valid land mark required');
    else if (input.address === '' || input.address.length < 3)
      toast.error('Valid address required');
    else return true;

    return false;
  };

  const addResource = () => {
    if (input.type === '' || input.type === 'Select Resource type...')
      toast.error('Resource type required');
    else if (input.name === '' || input.name.length < 3)
      toast.error('Valid Resource name required');
    else if (input.ownedDate === '' || input.ownedDate.length < 3)
      toast.error('When did you own this resource?');

    let selectedType = input.type;
    if (
      input.type !== '' &&
      input.type !== 'Select Resource type...' &&
      input.name !== '' &&
      input.name.length >= 3 &&
      input.ownedDate !== '' &&
      input.ownedDate.length >= 3
    ) {
      selectedType = input.type.replace(/ /g, '').toUpperCase();
      setSelectedType(selectedType);
      setStep({ step2: true });
    }

    if (selectedType === ResourceCategory.SMARTDEVICE) {
      if (smartDeviceValid()) {
        setStep({ step3: true });
        //validate last form of smartdevice
        if (input.receipt === '' || input.receipt === null)
          toast.error('Receipt required');
        else performResourceRegistration();
      }
    }

    if (selectedType === ResourceCategory.VEHICLE) {
      if (vehicleValid()) {
        setStep({ step3: true });
        //validate last form of vehicle
        if (input.legalDocuments === '' || input.legalDocuments === null)
          toast.error('Legal documents required');
        else performResourceRegistration();
      }
    }

    if (
      selectedType === ResourceCategory.LAND ||
      selectedType === ResourceCategory.HOUSE
    ) {
      if (landHouseValid()) {
        setStep({ step3: true });
        //validate last form of vehicle
        if (input.legalDocuments === '' || input.legalDocuments === null)
          toast.error('Legal documents required');
        else setStep({ step4: true });
      }

      if (
        input.legalDocuments !== '' &&
        input.legalDocuments !== null &&
        step.step4
      ) {
        if (input.witness1Name === '' || input.witness1Name.length < 3)
          toast.error('Valid person name required (Witness 1)');
        else if (
          input.witness1PhoneNumber === '' ||
          input.witness1PhoneNumber.length < 3
        )
          toast.error('Valid Phone number required (Witness 1)');
        else if (input.witness2Name === '' || input.witness2Name.length < 3)
          toast.error('Valid person name required (Witness 2)');
        else if (
          input.witness2PhoneNumber === '' ||
          input.witness2PhoneNumber.length < 3
        )
          toast.error('Valid Phone number required (Witness 2)');
        else performResourceRegistration();
      }
    }
  };

  const performRegistration = (request) => {
    setIsProcessing(true);
    setProcessingState('Registering resource...');
    resourceService
      .createResource(request)
      .then((result) => {
        setIsProcessing(false);
        if (result.data.success) {
          toast.success('Registration successful');
          setInput(inputData);
          navigate('/resource/all');
        } else {
          toast.error(result.data.message);
        }
      })
      .then((e) => {
        setIsProcessing(false);
        toast.error(e.data.message);
      });
  };
  const performResourceRegistration = async () => {
    toast.success('Processing...');
    setIsProcessing(true);
    setProcessingState('Preparing proof...');

    if (selectedType === ResourceCategory.SMARTDEVICE) {
      const filesData = new FormData();
      if (receiptFile) filesData.append('files[]', receiptFile[0]);
      if (input.carton === 'Yes') {
        if (cartonFile) filesData.append('files[]', cartonFile[0]);
      }
      const fileResponse = await fileService.uploadFiles(filesData);
      if (fileResponse.data.success) {
        const fileURLs = fileResponse.data.data;

        const request = {
          name: input.name,
          type: selectedType,
          color: input.color,
          description: input.description,
          model: input.model,
          identityNumber: input.identityNumber,
          carton: input.carton === 'Yes' ? true : false,
          ownedDate: input.ownedDate,
          ownershipProof: {
            receipt: fileURLs[0].url,
          },
        };

        if (request.carton === true) {
          const cartonDetail = {
            serialNumber: input.serialNumber,
            picture: fileURLs[1].url,
          };
          request.cartonDetail = cartonDetail;
        }

        await performRegistration(request);
      } else {
        setIsProcessing(false);
        toast.error(fileResponse.data.message);
      }
    } else if (selectedType === ResourceCategory.VEHICLE) {
      const filesData = new FormData();
      if (receiptFile) filesData.append('files[]', receiptFile[0]);
      if (legalFiles)
        legalFiles.forEach((file) => {
          filesData.append('files[]', file);
        });

      const fileResponse = await fileService.uploadFiles(filesData);
      if (fileResponse.data.success) {
        const fileURLs = fileResponse.data.data;
        let receiptUri;
        let legalUris = [];

        if (fileURLs.length > 0) {
          if (receiptFile) {
            receiptUri = fileURLs[0].url;
            fileURLs.shift();
          }
          fileURLs.forEach((urlData) => {
            legalUris.push(urlData.url);
          });
        }
        const request = {
          name: input.name,
          model: input.model,
          type: selectedType,
          color: input.color,
          description: input.description,
          identityNumber: input.identityNumber,
          ownedDate: input.ownedDate,
          ownershipProof: {
            receipt: receiptUri ? receiptUri : null,
            legalFiles: legalUris.length > 0 ? legalUris : null,
          },
        };
        await performRegistration(request);
      } else {
        setIsProcessing(false);
        toast.error(fileResponse.data.message);
      }
    } else if (
      selectedType === ResourceCategory.LAND ||
      selectedType === ResourceCategory.HOUSE
    ) {
      const filesData = new FormData();
      if (receiptFile) filesData.append('files[]', receiptFile[0]);
      if (legalFiles)
        legalFiles.forEach((file) => {
          filesData.append('files[]', file);
        });

      const fileResponse = await fileService.uploadFiles(filesData);
      if (fileResponse.data.success) {
        const fileURLs = fileResponse.data.data;
        let receiptUri;
        let legalUris = [];

        if (fileURLs.length > 0) {
          if (receiptFile) {
            receiptUri = fileURLs[0].url;
            fileURLs.shift();
          }
          fileURLs.forEach((urlData) => {
            legalUris.push(urlData.url);
          });
        }
        const request = {
          name: input.name,
          type: selectedType,
          color: input.color ? input.color : null,
          description: input.description,
          identityNumber: util.getSerialNumber(),
          ownedDate: input.ownedDate,
          size: input.size,
          locationDetail: {
            lga: input.lga,
            state: input.state,
            address: input.address,
            landmark: input.landmark,
          },
          ownershipProof: {
            receipt: receiptUri ? receiptUri : null,
            legalFiles: legalUris.length > 0 ? legalUris : null,
          },
          witnesses: [
            {
              name: input.witness1Name,
              phoneNumber: input.witness1PhoneNumber,
              address: input.witness1Address,
            },
            {
              name: input.witness2Name,
              phoneNumber: input.witness2PhoneNumber,
              address: input.witness2Address,
            },
          ],
        };
        await performRegistration(request);
      } else {
        setIsProcessing(false);
        toast.error(fileResponse.data.message);
      }
    }
  };

  const getAllResourceType = () => {
    resourceService
      .getAllResourceType()
      .then((result) => {
        const types = ['Select Resource type...'];
        if (result.data.success && result.data.data) {
          result.data.data.forEach((item) => {
            if (item.title !== 'SERVICE') {
              types.push(titleCase(item.title));
            }
          });
        }
        setResType(types);
      })
      .catch((e) => {
        console.log(e.data.message);
      });
  };

  useEffect(() => {
    getAllResourceType();
  }, []);

  return (
    <>
      <ToastContainer />
      <OtherPageBody>
        <div className="page-inner">
          <div className="row">
            <div className="col-xl-12">
              <div className="header-card">
                <div className="card-header">
                  <h4 className="text-left">
                    <i className="fa fa-pencil"></i> Register Resource
                  </h4>
                  <small>
                    By providing your resource information below, we help you
                    manage it
                  </small>
                </div>
              </div>

              <div className="col-xl-8">
                <div className="panel">
                  <div
                    className={
                      step.step1
                        ? 'card-header btn-primary collapse-form-header'
                        : 'card-header btn-outline-secondary collapse-form-header'
                    }
                    onClick={() => {
                      setStep({ step1: !step.step1 });
                    }}
                  >
                    <h5 className="mb-0">Resource</h5>
                  </div>
                  <div
                    className={step.step1 ? 'collapse show' : 'collapse'}
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="col-md-6">
                          <OptionField
                            children={resType}
                            label="Resource Type"
                            name="type"
                            onChange={(e) => {
                              const selectedType = e.target.value
                                .replace(/ /g, '')
                                .toUpperCase();
                              setSelectedType(selectedType);

                              setInput({
                                ...input,
                                type: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            type="text"
                            value={input.name}
                            placeholder="Title"
                            label="Resource Title"
                            name="name"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                name: e.target.value,
                              })
                            }
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            type="date"
                            max={new Date().toLocaleDateString('en-ca')}
                            value={input.ownedDate}
                            placeholder="ownedDate"
                            label="When did you own this resource?"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                ownedDate: e.target.value,
                              })
                            }
                            required={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextArea
                            value={input.description}
                            placeholder="Description"
                            label="Tell us about this resource"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                description: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step2
                        ? 'card-header btn-primary collapse-form-header'
                        : 'card-header btn-outline-secondary collapse-form-header'
                    }
                    onClick={() => {
                      if (input.type !== '') setStep({ step2: !step.step2 });
                    }}
                  >
                    <h5 className="mb-0">More Information</h5>
                  </div>
                  <div
                    className={step.step2 ? 'collapse show' : 'collapse'}
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        {selectedType === ResourceCategory.LAND ||
                          selectedType === ResourceCategory.HOUSE ? (
                          <></>
                        ) : (
                          <div className="row">
                            <div className="col-md-6">
                              <OptionField
                                children={util.getColors()}
                                value={input.color}
                                placeholder="Color"
                                label={
                                  selectedType === ResourceCategory.VEHICLE
                                    ? 'Vehicle Color'
                                    : 'Device Color'
                                }
                                name="color"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    color: e.target.value,
                                  })
                                }
                                required={true}
                              />
                            </div>

                            <div className="col-md-6">
                              <InputField
                                type="text"
                                value={input.identityNumber}
                                placeholder="Identity Number"
                                label={
                                  selectedType === ResourceCategory.VEHICLE
                                    ? 'Vehicle Chassis number'
                                    : 'Device IMEI or Serial Number'
                                }
                                name="identityNumber"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    identityNumber: e.target.value,
                                  })
                                }
                                required={true}
                              />
                            </div>
                          </div>
                        )}

                        {selectedType === ResourceCategory.VEHICLE ? (
                          <div className="col-md-6">
                            <InputField
                              type="text"
                              value={input.model}
                              placeholder="Model"
                              label={
                                selectedType === ResourceCategory.SMARTDEVICE
                                  ? 'Device Model'
                                  : 'Vehicle Model'
                              }
                              name="model"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  model: e.target.value,
                                })
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {selectedType === ResourceCategory.SMARTDEVICE ? (
                          <div className="row">
                            <div className="col-md-6">
                              <OptionField
                                children={['Does it have carton?', 'No', 'Yes']}
                                label="Is Carton Available?"
                                name="carton"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    carton: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>

                            <div className="col-md-6">
                              <InputField
                                type="text"
                                value={input.model}
                                placeholder="Model"
                                label="Device Model"
                                name="model"
                                required={true}
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    model: e.target.value,
                                  })
                                }
                              />
                            </div>

                            {input.carton === 'No' ||
                              input.carton === 'Does it have carton?' ||
                              input.carton === '' ? (
                              <div className="col-md-6"></div>
                            ) : (
                              <div className="row">
                                <div className="col-md-6">
                                  <InputField
                                    type="text"
                                    value={input.cartonSerial}
                                    placeholder="Carton Serial Number"
                                    label="Carton Serial"
                                    onChange={(e) =>
                                      setInput({
                                        ...input,
                                        cartonSerial: e.target.value,
                                      })
                                    }
                                    required={true}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <FileInput
                                    label="Carton Picture"
                                    text="Select Carton picture"
                                    name="picture"
                                    onChange={(e) => {
                                      onSelectFile(
                                        e,
                                        DocumentType.CartonPicture,
                                      );
                                      setInput({
                                        ...input,
                                        picture: e.target.value,
                                      });
                                    }}
                                    required={true}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}

                        {selectedType === ResourceCategory.LAND ||
                          selectedType === ResourceCategory.HOUSE ? (
                          <div className="row">
                            <div className="col-md-6">
                              <InputField
                                type="text"
                                value={input.size}
                                placeholder="Size e.g 50/50"
                                label="Plot size"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    size: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>

                            <div className="col-md-6">
                              <OptionField
                                children={location.getCities()}
                                value={input.state}
                                placeholder="State"
                                label="Location"
                                name="state"
                                onChange={(e) => {
                                  setAreas(location.getArea(e.target.value));
                                  setInput({
                                    ...input,
                                    state: e.target.value,
                                  });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <OptionField
                                children={area}
                                value={input.lga}
                                label="Local Area"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    lga: e.target.value,
                                  })
                                }
                                required={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <InputField
                                type="text"
                                value={input.landmark}
                                placeholder="Land Mark"
                                label="Land Mark"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    landmark: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-md-12">
                              <InputField
                                type="text"
                                value={input.address}
                                placeholder="Address"
                                label="Address"
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    address: e.target.value,
                                  })
                                }
                                required={true}
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step3
                        ? 'card-header btn-primary collapse-form-header'
                        : 'card-header btn-outline-secondary collapse-form-header'
                    }
                    onClick={() => {
                      if (input.type !== '') setStep({ step3: !step.step3 });
                    }}
                  >
                    <h5 className="mb-0">Prove of Ownership</h5>
                  </div>
                  <div
                    className={step.step3 ? 'collapse show' : 'collapse'}
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="col-md-6">
                          <FileInput
                            id="receipt"
                            label="Receipt"
                            text="Upload receipt"
                            name="receipt"
                            onChange={(e) => {
                              onSelectFile(e, DocumentType.Receipt);
                              setInput({
                                ...input,
                                receipt: e.target.value,
                              });
                            }}
                            required={
                              selectedType === ResourceCategory.SMARTDEVICE
                                ? true
                                : false
                            }
                          />
                        </div>

                        {selectedType === ResourceCategory.SMARTDEVICE ? (
                          <></>
                        ) : (
                          <div className="col-md-6">
                            <FileInput
                              label="Legal Documents"
                              text="Upload additional documents"
                              multiple={true}
                              name="legalDocuments[]"
                              onChange={(e) => {
                                onSelectFile(e, DocumentType.LegalDocument);
                                setInput({
                                  ...input,
                                  legalDocuments: e.target.value,
                                });
                              }}
                              required={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {selectedType === ResourceCategory.LAND ||
                  selectedType === ResourceCategory.HOUSE ? (
                  <div className="panel">
                    <div
                      className={
                        step.step4
                          ? 'card-header btn-primary collapse-form-header'
                          : 'card-header btn-outline-secondary collapse-form-header'
                      }
                      onClick={() => {
                        setStep({ step4: !step.step4 });
                      }}
                    >
                      <h5 className="mb-0">Witnesses</h5>
                    </div>
                    <div
                      className={step.step4 ? 'collapse show' : 'collapse'}
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body collapse-form-body">
                        <h3>First Witness</h3>

                        <div className="row">
                          <div className="col-md-6">
                            <InputField
                              value={input.witness1Name}
                              label="Witness1 Name"
                              placeholder="Witness1 Fullname"
                              name="witness1Name"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  witness1Name: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="tel"
                              value={input.witness1PhoneNumber}
                              placeholder="Witness1 Number"
                              label="Witness1 Phone Number"
                              name="witness1PhoneNumber"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  witness1PhoneNumber: e.target.value,
                                })
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TextArea
                            value={input.witness1Address}
                            placeholder="Witness1 Address"
                            label="Witness1 Address"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                witness1Address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="card-body collapse-form-body">
                        <h3>Second Witness</h3>

                        <div className="row">
                          <div className="col-md-6">
                            <InputField
                              value={input.witness2Name}
                              label="Witness2 Name"
                              placeholder="Witness2 Fullname"
                              name="witness2Name"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  witness2Name: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="tel"
                              value={input.witness2PhoneNumber}
                              placeholder="Witness2 Number"
                              label="Witness2 Phone Number"
                              name="witness2PhoneNumber"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  witness2PhoneNumber: e.target.value,
                                })
                              }
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TextArea
                            value={input.witness2Address}
                            placeholder="Witness2 Address"
                            label="Witness2 Address"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                witness2Address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row ">
                  <button
                    className="btn btn-primary p-3 btn-round ml-auto m-3  long-button"
                    width="100"
                    onClick={isProcessing ? null : addResource}
                  >
                    {isProcessing
                      ? processingState
                        ? processingState
                        : <TinyLoader />
                      : (selectedType === ResourceCategory.HOUSE ||
                        selectedType === ResourceCategory.LAND) &&
                        step.step4
                        ? 'Register Resource'
                        : step.step3
                          ? 'Register Resource'
                          : 'Next'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default AddResource;

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let trackingService = {
    getAllTrackingRequest: async (currentPage) => {
        return await http.get(`${util.endPoint}/tracking/request/list?page=${currentPage}`, {
          headers: util.getAuthorizedHeaders(),
        });
      },
      createTrackingRequest: async (body) => {
        return await http.post(`${util.endPoint}/tracking/request`, body, {
          headers: util.getAuthorizedHeaders(),
        });
      },
      updateTrackingRequest: async (id, body) => {
        return await http.put(`${util.endPoint}/tracking/request/status/${id}`, body, {
          headers: util.getAuthorizedHeaders(),
        });
      }
  };
  
  export { trackingService };
import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
let businessService = {
  getAllBusinessType: async () => {
    return await http.get(`${util.endPoint}/business-type/list`, util.getHeaders());
  },
  createBusinessType: async (body) => {
    return await http.post(`${util.endPoint}/business-type`, body, {
      headers: util.getAuthorizedHeaders(),
    });

  },
  deleteBusinessType: async (businessId) => {
    return await http.delete(`${util.endPoint}/business-type/${businessId}`, {
      headers: util.getAuthorizedHeaders(),
    });
  }
};
export { businessService };

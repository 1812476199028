import React, { useState, useEffect, useContext, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { inventoryService } from '../../service/inventory.service';
import OtherPageBody from './OtherPageBody';
import Loader from './../users/components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import toMoney from 'number-to-money';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../Access';
import { UserRole } from '../../enums/UserRole';
import InputField from './components/inputs/InputField';
import { useReactToPrint } from 'react-to-print';
import signature from './../../assets/img/signature.png';
import OptionField from './components/select/OptionField';
import { userService } from '../../service/user.service';
import { toast } from 'react-toast';

const InventorySales = () => {

  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const [productsModal, setProductsModal] = useState(false);
  const [productsInfo, setProductsInfo] = useState([]);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: '', to: '', actionBy: '' });
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState();
  const [transactionDetail, setTransactionDetail] = useState();
  const [allUsers, setAllUsers] = useState(['All...']);
  const dataRef = useRef();

  useEffect(() => {
    getUsers();
    getAllSales();
  }, []);


  const handlePrint = useReactToPrint({
    content: () => dataRef.current,
  });

  const getUsers = () => {
    userService
      .getAllUsers(0)
      .then((result) => {
        if (result.data.success) {
          const users = result.data.data.page;
          const list = ['All...'];
          users.forEach((user) => {
            list.push(user.name)
          })
          setAllUsers(list);

        } else {
          toast.error(result.data.message)
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };
  const getAllSales = () => {
    const request = {
      from: input.from,
      to: input.to,
      actionBy: input.actionBy,
    };
    setInProgress(true);
    inventoryService
      .getAllInventorySales(request)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data.page);
        }
      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllSales();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {

      const request = {
        from: input.from,
        to: input.to,
        actionBy: input.actionBy,
      };
      setInProgress(true);
      inventoryService
        .searchSales(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const closeProductModal = () => {
    setProductsModal(false);
  };

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openTransactionDetailModal && transactionDetail !== null}>
        <DialogTitle>Transaction Receipt</DialogTitle>
        <DialogContent>
          {transactionDetail ? (
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default invoice" ref={dataRef}>
                  <div className="panel-body  p-3">
                    <div className="invoice-ribbon">
                      <div className="ribbon-inner  bg-primary">Paid</div>
                    </div>
                    <div className="row  p-5">
                      <div className="row">
                        <div className={width > 800 ? 'col-sm-6  mt-5' : 'col-sm-6'}>
                          <h3 className="marginright">{user.business}</h3>
                          <span className="marginright">
                            Executed by {transactionDetail.createdBy}
                          </span>
                        </div>
                        <div className={width > 800 ? 'col-sm-6  mt-5 top-right' : 'col-sm-6 top-right'}>
                          <h3 className="marginright">
                            REF-{transactionDetail.code}
                          </h3>
                          <span className="marginright">
                            {new Date(transactionDetail.createdAt).toISOString()}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4  mb-3">
                        <span className="marginright">
                          Ordered by{' '}
                          <strong>{transactionDetail.customerName}</strong> with phone
                          number{' '}
                          <strong>{transactionDetail.customerPhoneNumber}</strong>
                        </span>
                      </div>
                      <div className="row">
                        <table className="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Quantity</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionDetail.items.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-center">{++index}</td>
                                  <td>{item.name}</td>
                                  <td className="text-right">{item.type}</td>
                                  <td className="text-right">
                                    {item.quantity}
                                  </td>
                                  <td className="text-right">
                                    ₦{toMoney(item.totalPayable)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <img src={signature} alt="signature mt-3" />
                        </div>
                        <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                          <p>Subtotal : ₦{toMoney(transactionDetail.totalAmount)}</p>
                          <p>
                            Discount : ₦{toMoney(transactionDetail.totalDiscount)}
                          </p>
                          <p>VAT : ₦0 </p>
                          <p>
                            Total : ₦
                            {toMoney(
                              transactionDetail.totalAmount -
                              transactionDetail.totalDiscount,
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <p>Unable to generate data</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary btn-round mr-2"
            onClick={() => handlePrint()}
          >
            Print
          </button>

          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {

              setOpenTransactionDetailModal(false)
            }}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={productsModal}>
        <DialogTitle>Products Info</DialogTitle>
        <DialogContent>
          <div className="table-responsive">
            {productsInfo.length > 0 ? (
              <table
                id="add-row"
                className="display table table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>S/n</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {productsInfo.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>{item.type}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td>₦{toMoney(item.totalPayable)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center message-box">
                <small>No Products found</small>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeProductModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Sales</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search Sales e.g Tecno or active"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <Link
                    to="/inventory/sale"
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                    Create Order
                  </Link>
                </div>
              </div>
            </div>
            <div className="filter-container d-flex align-item-center text-align-center">
              <OptionField
                children={allUsers.length > 0 ? allUsers : ['All...']}
                name="actionBy"
                value={input.actionBy}
                placeholder="Action by"
                label="Action by"
                onChange={(e) =>
                  setInput({
                    ...input,
                    actionBy: e.target.value,
                  })
                }
              />
              <InputField
                type="date"
                name="from"
                value={input.from}
                placeholder="From"
                label="From"
                onChange={(e) =>
                  setInput({
                    ...input,
                    from: e.target.value,
                  })
                }
              /><InputField
                type="date"
                name="to"
                value={input.to}
                placeholder="To"
                label="To"
                onChange={(e) =>
                  setInput({
                    ...input,
                    to: e.target.value,
                  })
                }
              />

              <div className="filter-button text-align-right">
                <button
                  type="button"
                  onClick={() => {
                    getAllSales()
                  }}
                  className="btn default-btn btn-primary btn-large p-2"
                >

                  Filter
                </button>
              </div>
            </div>

            <div className="row data-wrapper">
              {inProgress ? (
                <div className="row card">
                  <div className="card-body">
                    <Loader></Loader>
                  </div>
                </div>
              ) : response.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <table
                      id="add-row"
                      className="display table table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>S/n</th>
                          <th>Customer Name</th>
                          <th>Phone Number</th>
                          <th>Discount</th>
                          <th>Total Amount</th>
                          <th>Total Revenue</th>
                          <th>Created By</th>
                          <th>Created At</th>
                          <th>Products</th>
                        </tr>
                      </thead>

                      <tbody>
                        {response.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{++index}</td>
                              <td>{item.customerName}</td>
                              <td>{item.customerPhoneNumber}</td>
                              <td>₦{toMoney(item.totalDiscount)}</td>
                              <td>₦{toMoney(item.totalAmount)}</td>
                              <td>₦{user.role === UserRole.User ? '****' : item.totalRevenue ? toMoney(item.totalRevenue) : '0'}</td>
                              <td>{item.createdBy}</td>
                              <td>{item.createdAt}</td>
                              <td>
                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-primary"
                                    data-original-title="view"
                                    onClick={() => {
                                      setTransactionDetail(item);
                                      setOpenTransactionDetailModal(true);
                                    }}
                                  >
                                    <i className="fa fa-eye"></i> View Receipt
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  response.map((item, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    Paid
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {item.name}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      <h4>{item.customerName}</h4>
                                    </li>
                                    <li>
                                      Total Amount:{' '}
                                      <span className="text-danger">
                                        ₦{toMoney(item.totalAmount)}
                                      </span>
                                    </li>
                                    <li>
                                      Total Revenue:{' '}
                                      <span className="text-success">
                                        ₦{user.role === UserRole.User ? '****' : item.totalRevenue ? toMoney(item.totalRevenue) : '0'}
                                      </span>
                                    </li>
                                    <li>
                                      Discount:{' '}
                                      <span className="text-danger">
                                        {' '}
                                        ₦{toMoney(item.totalDiscount)}
                                      </span>
                                    </li>
                                    <li>Created By: <span className="text-primary">{item.createdBy}</span></li>
                                    <li>Created At: <span className="text-primary">{item.createdAt}</span></li>

                                    <li>
                                      Status :{' '}
                                      <span className="text-success">
                                        Successful
                                      </span>
                                    </li>
                                  </ul>

                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setTransactionDetail(item);
                                        setOpenTransactionDetailModal(true);
                                      }}
                                    >
                                      <i className="fa fa-industry"></i> View Receipt
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>No Sales found</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(-1)}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((e, i) => (
                <li className="page-item" key={i}>
                  <button
                    className={
                      i === currentPage
                        ? 'page-link btn-primary text-white'
                        : 'page-link'
                    }
                    onClick={() => changePage(i)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(+1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </OtherPageBody >
  );
};

export default InventorySales;

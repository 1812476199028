import React from "react";
import "../../../../assets/css/inputs.css";

const PasswordInputField = ({
  label,
  value,
  myCHange,
  option,
  required = false,
  ...props
}) => {
  let handleChange = (cChange) => {
    myCHange = cChange;
  };
  return (
    <div className="form-group">
      {label && (
        <div>
          <label htmlFor="input-field">
            {label} <span className="required-star">{required ? "*" : ""}</span>
          </label>
        </div>
      )}
      <div className="d-flex form-control icon-input-wrapper" >
        <i className="fa fa-lock icon"></i>
        <input
          id="myField"
          value={value ? value : ''}
          onChange={handleChange(myCHange)}
          {...props}
          required={required}
        />
      </div>
    </div>
  );
};

export default PasswordInputField;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { format } from "timeago.js";
import OtherPageBody from "./OtherPageBody";
import Loader from "./components/loaders/Loader";
import SearchField from "./components/inputs/SearchField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import toMoney from "number-to-money";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UserContext } from "../../Access";
import { useContext } from "react";
import InputField from "./components/inputs/InputField";
import TinyLoader from "./components/loaders/TinyLoader";
import { expenseService } from "../../service/expense.service";

const ManageExpenses = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [openRegisterExpenseModal, setOpenRegisterExpenseModal] = useState(false);
  const [openExpenseUpdateModal, setOpenExpenseEditModal] = useState(false);
  const [openDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: "", to: "" });
  const [registerExpenseInput, setRegisterExpenseInput] = useState({
    title: "",
    amount: 0,
    expenseDate: "",
    description: "",
  });
  const [registerExpenseProgress, setRegisterExpenseProgress] = useState(false);
  const [updateExpenseProgress, setUpdateExpenseProgress] = useState(false);

  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(false);

  useEffect(() => {
    getAllExpenses();
  }, []);

  const getAllExpenses = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setInProgress(true);
    expenseService
      .getAllExpense(request, currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data.page);
        }
      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllExpenses();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      const request = {
        from: input.from,
        to: input.to,
      };
      setInProgress(true);
      expenseService.searchExpenses(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const registerExpense = () => {
    if (
      registerExpenseInput.title === "" ||
      registerExpenseInput.title.length < 3
    )
      toast.error("Provide valid Expense Title");
    else if (
      registerExpenseInput.amount === "" ||
      registerExpenseInput.amount <= 0
    )
      toast.error("Provide valid amount");
    else {
      const request = {
        title: registerExpenseInput.title,
        amount: Number(registerExpenseInput.amount),
        description: registerExpenseInput.description,
        expenseDate: registerExpenseInput.expenseDate,
      };
      setRegisterExpenseProgress(true);
      expenseService
        .createExpense(request)
        .then((result) => {
          setRegisterExpenseProgress(false);
          if (result.data.success) {
            getAllExpenses();
            setOpenRegisterExpenseModal(false);
            toast.success("Expense Added");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setRegisterExpenseProgress(false);
        });
    }
  };

  const performDelete = () => {
    setDeleteProgress(true);
    expenseService
      .deleteExpense(selectedExpense._id)
      .then((result) => {
        setOpenDeleteExpenseModal(false);
        setDeleteProgress(false);
        if (result.data.success) {
          toast.success("Expense Deleted successful");
          getAllExpenses();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeleteExpenseModal(false);
        setDeleteProgress(false);
      });
  };
  const performUpdate = () => {
    setUpdateExpenseProgress(true);
    expenseService
      .updateExpense(selectedExpense._id, selectedExpense)
      .then((result) => {
        setOpenExpenseEditModal(false);
        setUpdateExpenseProgress(false);
        if (result.data.success) {
          toast.success("Expense Update successful");
          getAllExpenses();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenExpenseEditModal(false);
        setUpdateExpenseProgress(false);
      });
  };
  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openRegisterExpenseModal}>
        <DialogTitle>Register Expense</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={registerExpenseInput.title}
            placeholder="Title E.G Fuel"
            label="Expense Title"
            onChange={(e) =>
              setRegisterExpenseInput({
                ...registerExpenseInput,
                title: e.target.value,
              })
            }
            required
          />

          <InputField
            type="number"
            name="amount"
            value={registerExpenseInput.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setRegisterExpenseInput({
                ...registerExpenseInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="date"
            name="repaymentDate"
            value={registerExpenseInput.expenseDate}
            placeholder="Date"
            label="Date (Optional)"
            onChange={(e) =>
              setRegisterExpenseInput({
                ...registerExpenseInput,
                expenseDate: e.target.value,
              })
            }
          />
          <InputField
            type="text"
            name="description"
            value={registerExpenseInput.description}
            placeholder="Description"
            label="Remark"
            onChange={(e) =>
              setRegisterExpenseInput({
                ...registerExpenseInput,
                description: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenRegisterExpenseModal(false)}
            className="btn btn-primary btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!registerExpenseProgress ? registerExpense : null}
            className="btn btn-primary btn-round"
          >
            {registerExpenseProgress ? <TinyLoader /> : "Continue"}
          </button>
        </DialogActions>
      </Dialog>

      {/* Edit Product */}
      <Dialog open={openExpenseUpdateModal}>
        <DialogTitle>Update Expense</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={selectedExpense.title}
            placeholder="Title E.G Fuel"
            label="Expense Title"
            onChange={(e) =>
              setSelectedExpense({
                ...selectedExpense,
                title: e.target.value,
              })
            }
            required
          />

          <InputField
            type="number"
            name="amount"
            value={selectedExpense.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setSelectedExpense({
                ...registerExpenseInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="date"
            name="repaymentDate"
            value={selectedExpense.expenseDate}
            placeholder="Date"
            label="Date (Optional)"
            onChange={(e) =>
              setSelectedExpense({
                ...selectedExpense,
                expenseDate: e.target.value,
              })
            }
          />
          <InputField
            type="text"
            name="description"
            value={selectedExpense.description}
            placeholder="Description"
            label="Remark"
            onChange={(e) =>
              setSelectedExpense({
                ...selectedExpense,
                description: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenExpenseEditModal(false)}
            className="btn btn-primary btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!updateExpenseProgress ? performUpdate : null}
            className="btn btn-primary btn-round"
          >
            {updateExpenseProgress ? <TinyLoader /> : "Continue"}
          </button>
        </DialogActions>
      </Dialog>
      {/* Delete */}
      <Dialog open={openDeleteExpenseModal}>
        <DialogTitle>Delete Expense</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this expense?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteExpenseModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : "Yes"}
          </button>
        </DialogActions>
      </Dialog>

      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Expenses</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search expense e.g Fuel"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <button
                    onClick={() => setOpenRegisterExpenseModal(true)}
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: "5px" }}></i>
                    Register Expense
                  </button>
                </div>
              </div>
            </div>
            <div className="filter-container d-flex align-item-center text-align-center">
              <InputField
                type="date"
                name="from"
                value={input.from}
                placeholder="From"
                label="From"
                onChange={(e) =>
                  setInput({
                    ...input,
                    from: e.target.value,
                  })
                }
              />
              <InputField
                type="date"
                name="to"
                value={input.to}
                placeholder="To"
                label="To"
                onChange={(e) =>
                  setInput({
                    ...input,
                    to: e.target.value,
                  })
                }
              />
              <div className="filter-button text-align-right">
                <button
                  type="button"
                  onClick={() => {
                    getAllExpenses();
                  }}
                  className="btn default-btn btn-primary btn-large p-2"
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="row data-wrapper">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : response.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="card-body">
                      <table id="add-row" className=" table table-striped">
                        <thead>
                          <tr>
                            <th>S/n</th>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Added Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {response.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{item.title}</td>

                                <td>₦{toMoney(item.amount)}</td>
                                <td>
                                  {item.description ? item.description : "N/A"}
                                </td>
                                <td>{item.expenseDate ?? ""}</td>
                                <td>{format(item.createdAt)}</td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary btn-outline bg-danger"
                                      data-original-title="edit"
                                      onClick={() => {
                                        setSelectedExpense(item);
                                        setOpenDeleteExpenseModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary "
                                      data-original-title="repay"
                                      onClick={() => {
                                        setSelectedExpense(item);
                                        setOpenExpenseEditModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  response.map((item, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    {item.clearedAmount >= item.amount
                                      ? "Closed"
                                      : "Open"}
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {item.name}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      <h2>
                                        <strong>{item.title}</strong>
                                      </h2>
                                    </li>

                                    <li>
                                      Total Amount:{" "}
                                      <span className="text-danger">
                                        ₦{toMoney(item.amount)}
                                      </span>
                                    </li>

                                    <li>
                                      Created :
                                      <span className="text-primary">
                                        {format(item.createdAt)}
                                      </span>
                                    </li>
                                    <li>
                                      <h2>
                                        Description: <strong>{item.description?item.description:'N/A'}</strong>
                                      </h2>
                                    </li>
                                  </ul>

                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary bg-danger"
                                      data-original-title="view"
                                      onClick={() => {
                                        setSelectedExpense(item);
                                        setOpenDeleteExpenseModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i> Delete
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setSelectedExpense(item);
                                        setOpenExpenseEditModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i> Edit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>No Expense found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(-1)}>
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((e, i) => (
              <li className="page-item" key={i}>
                <button
                  className={
                    i === currentPage
                      ? "page-link btn-primary text-white"
                      : "page-link"
                  }
                  onClick={() => changePage(i)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(+1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </OtherPageBody>
  );
};

export default ManageExpenses;

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let inventoryService = {
  getInventoryProducts: async (currentPage) => {
    return await http.get(`${util.endPoint}/inventory/product/list?page=${currentPage}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchInventoryProducts: async (currentPage, searchText) => {
    return await http.get(`${util.endPoint}/inventory/product/search?page=${currentPage}&q=${searchText}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getInventoryServices: async (currentPage) => {
    return await http.get(`${util.endPoint}/inventory/service/list?page=${currentPage}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchInventoryServices: async (currentPage, searchText) => {
    return await http.get(`${util.endPoint}/inventory/service/search?page=${currentPage}&q=${searchText}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  getAllInventorySales: async (filter) => {
    let url = `/inventory/sale/list?type=PRODUCT`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllInventoryTransactions: async (filter) => {
    let url = `/inventory/sale/list?type=SERVICE`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createService: async (body) => {
    return await http.post(`${util.endPoint}/inventory/service`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateService: async (id, body) => {
    return await http.put(`${util.endPoint}/inventory/service/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createProduct: async (body) => {
    return await http.post(`${util.endPoint}/inventory/product`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateProduct: async (id, body) => {
    return await http.post(`${util.endPoint}/inventory/product/upload/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  adjustProduct: async (id, body) => {
    return await http.post(`${util.endPoint}/inventory/product/adjust/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });

  },
  searchSales: async (filter, currentPage, searchText) => {
    let url = `/inventory/sale/search?type=PRODUCT&page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchTransactions: async (filter, currentPage, searchText) => {
    let url = `/inventory/sale/search?type=SERVICE&page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`


    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createSale: async (body) => {
    return await http.post(`${util.endPoint}/inventory/sale`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createTransaction: async (body) => {
    return await http.post(`${util.endPoint}/inventory/sale/transaction`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getInventoryAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/inventory/analytics`
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`


    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  downloadInventoryAnalytics: async (body) => {
    let url = `${util.endPoint}/report/inventory/analytics/download`
    if (body && body.from && body.to) {
      url += `?from=${body.from}&to=${body.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (body && body.actionBy)
      url += `&actionBy=${body.actionBy}`

    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getProductInventory: async () => {
    let url = `${util.endPoint}/report/product/inventory`
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getServiceInventory: async () => {
    let url = `${util.endPoint}/report/service/inventory`
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  }
}

export { inventoryService };
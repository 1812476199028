import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let expenseService = {
  createExpense: async (body) => {
    return await http.post(`${util.endPoint}/expense`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  updateExpense: async (id, body) => {
    return await http.put(`${util.endPoint}/expense/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteExpense: async (id) => {
    return await http.delete(`${util.endPoint}/expense/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllExpense: async (filter, currentPage) => {
    let url = `/expense/list?page=${currentPage}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchExpenses: async (filter, currentPage, searchText) => {
    let url = `/expense/search?page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getExpenseAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/expense/analytics`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { expenseService };

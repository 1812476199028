import React from "react";
import { Navigate } from "react-router-dom";
import { storage } from "./utils/constants/storage";

export default function ProtectedRoute({ children }) {
  const isAuthenticated = storage.get('userToken') != null;

  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate to={"/user/login"} replace />
  );
}
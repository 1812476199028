import React from "react";
import "../../../../assets/css/inputs.css";

const InputField = ({
  label,
  value,
  myCHange,
  option,
  required = false,
  ...props
}) => {
  // const [changeVal, setChangeVal] = useState("");
  let handleChange = (cChange) => {
    myCHange = cChange;
  };
  return (
    <div className="form-group">
      {label && (
        <div>
          <label htmlFor="input-field">
            {label} <span className="required-star">{required ? "*" : ""}</span>
          </label>
        </div>
      )}
      <input
        className="form-control input-field" id="myField"
        value={value ? value : ''}
        onChange={handleChange(myCHange)}
        {...props}
        required={required}
      />
    </div>
  );
};

export default InputField;

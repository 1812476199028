import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { serviceService } from '../../service/service.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import { BusinessTarget } from '../../enums/BusinessTarget';
import { UserContext } from '../../Access';
import TinyLoader from './components/loaders/TinyLoader';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const AllServiceType = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [serviceTypeProgress, setServiceTypeProgress] = useState(false);
  const [openServiceTypeModal, setOpenServiceTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [serviceTypeInput, setServiceTypeInput] = useState({
    title: '',
    description: '',
  });
  const { width } = useWindowDimensions();
  const userSession = useContext(UserContext);
  const user = userSession.user;

  useEffect(() => {
    getAllServicesType();
  }, []);

  const getAllServicesType = () => {
    setInProgress(true);
    serviceService
      .getAllServiceType()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createServiceType = () => {
    if (serviceTypeInput.title === '' || serviceTypeInput.title.length < 3)
      toast.error('Provide valid title');

    if (serviceTypeInput.title !== '' && serviceTypeInput.title.length >= 3) {
      const request = {
        title: serviceTypeInput.title,
        category: serviceTypeInput.category,
        description: 'N/A',
      };
      setServiceTypeProgress(true);

      if (isEditing) {
        if (selectedService) {
          serviceService
            .updateServiceType(selectedService.stuid, request)
            .then((result) => {
              setOpenServiceTypeModal(false);
              setServiceTypeProgress(false);
              if (result.data.success) {
                toast.success('Updated successful');
                getAllServicesType();
              } else {
                toast.error(result.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error(e.response.data.message);
              setOpenServiceTypeModal(false);
              setServiceTypeProgress(false);
            });
        } else {
          toast.error('No service selected');
        }
      } else {
        serviceService
          .createServiceType(request)
          .then((result) => {
            setOpenServiceTypeModal(false);
            setServiceTypeProgress(false);
            if (result.data.success) {
              toast.success('Created successful');
              getAllServicesType();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenServiceTypeModal(false);
            setServiceTypeProgress(false);
          });
      }
    }
  };

  const performDelete = () => {
    if (selectedService) {
      setDeleteProgress(true);
      serviceService
        .deleteServiceType(selectedService.stuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success && result.data.data.deletedCount > 0) {
            toast.success('Deleted successful');
          } else {
            toast.error('Unable to delete service type');
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No service type selected');
    }
  };

  const closeServiceTypeModal = () => {
    setIsEditing(false);
    setOpenServiceTypeModal(false);
  };

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openServiceTypeModal}>
          <DialogTitle>{isEditing ? 'Update Type' : 'Add Type'}</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="title"
              value={serviceTypeInput.title}
              placeholder="Provide service type title e.g POS"
              label="Type"
              onChange={(e) =>
                setServiceTypeInput({
                  ...serviceTypeInput,
                  title: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={closeServiceTypeModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={!serviceTypeProgress ? createServiceType : null}
              className="btn btn-primary btn-round"
            >
              {serviceTypeProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteModal}>
          <DialogTitle>Delete Type</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this service type?</p>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : 'Yes'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Service Types</h4>

                    <button
                      type="button"
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setServiceTypeInput({
                          title: '',
                          description: '',
                        });
                        setIsEditing(false);
                        setOpenServiceTypeModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Service Type
                    </button>
                  </div>
                </div>
                {inProgress ? (
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Loader></Loader>
                      </div>
                    </div>
                  </div>
                ) : response.length > 0 ?

                  width > 800 ?
                    (
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">

                            <table
                              id="add-row"
                              className="display table table-striped table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>S/n</th>
                                  <th>Type</th>
                                  {user.businessTarget !==
                                    BusinessTarget.Service ? (
                                    <th>Category</th>
                                  ) : (
                                    <></>
                                  )}
                                  <th>Description</th>
                                  <th>Type ID</th>
                                  <th>Status</th>
                                  <th>Date Added</th>
                                  <th style={{ width: '10%' }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {response.map((service, index) => {
                                  return (
                                    <tr key={service.ruid}>
                                      <td>{++index}</td>
                                      <td>{service.title}</td>
                                      {user.businessTarget !==
                                        BusinessTarget.Service ? (
                                        <td>{service.category}</td>
                                      ) : (
                                        <></>
                                      )}
                                      <td>{service.description}</td>
                                      <td>{service.stuid}</td>
                                      <td
                                        className={
                                          service.status === 'ACTIVE'
                                            ? 'text-success'
                                            : 'text-danger'
                                        }
                                      >
                                        {service.status}
                                      </td>
                                      <td>{format(service.createdAt)}</td>

                                      <td>
                                        <div className="form-button-action">
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn  btn-primary  "
                                            data-original-title="Edit Task"
                                            onClick={() => {
                                              setServiceTypeInput({
                                                title: service.title,
                                                description: service.description,
                                              });
                                              setIsEditing(true);
                                              setSelectedService(service);
                                              setOpenServiceTypeModal(true);
                                            }}
                                          >
                                            <i className="fa fa-edit"></i>
                                          </button>
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn  btn-danger"
                                            onClick={() => {
                                              setSelectedService(service);
                                              setOpenDeleteModal(true);
                                            }}
                                            data-original-title="Remove"
                                          >
                                            <i className="  fa fa-times"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) :
                    (
                      response.map((item, index) => {
                        return (
                          <div
                            className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                            key={index}
                          >
                            <div className="image-flip">
                              <div className="mainflip flip-0">
                                <div className="frontside">
                                  <div className="card">
                                    <div className="invoice-ribbon">
                                      <div className="ribbon-inner  bg-primary">
                                        {++index}
                                      </div>
                                    </div>
                                    <div className="card-body text-left">
                                      <h4 className="card-title text-primary">
                                        {item.type}
                                      </h4>
                                      <ul className="card-text">
                                        <li>
                                          <span className="text-danger">
                                            {item.title}
                                          </span>
                                        </li>
                                        <li>
                                          <span className="text-success">
                                            {item.category}
                                          </span>
                                        </li>
                                        <li>
                                          Status :{' '}
                                          <span className="text-success">
                                            {item.status}
                                          </span>
                                        </li>
                                        <li>
                                          Created : {format(item.createdAt)}
                                        </li>
                                      </ul>

                                      <div className="form-button-action">
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-primary  "
                                          data-original-title="Edit Task"
                                          onClick={() => {
                                            setServiceTypeInput({
                                              title: item.title,
                                              description: item.description,
                                            });
                                            setIsEditing(true);
                                            setSelectedService(item);
                                            setOpenServiceTypeModal(true);
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-danger"
                                          onClick={() => {
                                            setSelectedService(item);
                                            setOpenDeleteModal(true);
                                          }}
                                          data-original-title="Remove"
                                        >
                                          <i className="  fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="text-center message-box">
                            <p>No service type found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody >
    </>
  );
};

export default AllServiceType;

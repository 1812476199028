import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { resourceService } from '../../service/resource.service';
import OtherPageBody from './OtherPageBody';
import Loader from './../users/components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import OptionField from './components/select/OptionField';
import { userService } from '../../service/user.service';
import SearchField from './components/inputs/SearchField';
import { Status } from '../../enums/Status';
import Land from '../../assets/img/land.jpg';
import House from '../../assets/img/house.jpg';
import Vehicle from '../../assets/img/vehicle.jpg';
import SmartDevice from '../../assets/img/smartdevice.jpg';
import Unknown from '../../assets/img/unknown.jpg';
import TinyLoader from './components/loaders/TinyLoader';

const AllResources = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedResource, setSelectedResource] = useState();
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [blockProgress, setBlockProgress] = useState(false);
  const [blockInput, setBlockInput] = useState({
    reason: '',
    description: '',
  });
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [transferProgress, setTransferProgress] = useState(false);
  const [transferInput, setTransferInput] = useState({
    reason: '',
    newOwner: '',
    description: '',
  });
  const [inquiryProgress, setInquiryProgress] = useState(false);
  const [inquiredUser, setInquiredUser] = useState();
  const [searchText, setSearchText] = useState('');
  const [unblockProgress, setUnblockProgress] = useState(false);

  useEffect(() => {
    getAllResources();
  }, []);

  const getAllResources = () => {
    setInProgress(true);
    resourceService
      .getAllMyResources()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const selectResource = (resource, operation) => {
    setSelectedResource(resource);

    if (operation === 'delete') setOpenDeleteModal(true);
    if (operation === 'block') setOpenBlockModal(true);
    if (operation === 'transfer') setOpenTransferModal(true);
  };

  const performAccountInquiry = () => {
    setInquiryProgress(true);
    userService
      .getUserByCode(transferInput.newOwner)
      .then((result) => {
        setInquiryProgress(false);
        if (result.data.success) {
          setInquiredUser(result.data.data);
        } else {
          setInquiredUser(null);
        }
      })
      .catch((e) => {
        console.log(e);
        setInquiredUser(null);
        setInquiryProgress(false);
      });
  };

  const performDelete = () => {
    if (selectedResource) {
      setDeleteProgress(true);
      resourceService
        .deleteResource(selectedResource.ruid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success) {
            toast.success('Deleted successful');
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No resource selected');
    }
  };

  const performBlock = () => {
    if (selectedResource) {
      if (blockInput.reason === '' || blockInput.reason === 'Select reason...')
        toast.error('Why are you blocking this resource?');
      else if (
        blockInput.description === '' ||
        blockInput.description.length < 3
      )
        toast.error('Please provide more information');

      if (
        blockInput.reason !== '' &&
        blockInput.reason !== 'Select reason...' &&
        blockInput.description !== '' &&
        blockInput.description.length >= 3
      ) {
        const request = {
          reason: blockInput.reason,
          description: blockInput.description,
        };
        setBlockProgress(true);
        resourceService
          .blockResource(selectedResource.ruid, request)
          .then((result) => {
            setOpenBlockModal(false);
            setBlockProgress(false);
            if (result.data.success) {
              toast.success('Blocked successful');
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenBlockModal(false);
            setBlockProgress(false);
          });
      }
    } else {
      toast.error('No resource selected');
    }
  };

  const performUnblock = () => {
    setUnblockProgress(true);
    const request = {
      reason: 'Unblocked',
      description: 'None',
    };
    resourceService
      .unBlockResource(selectedResource.ruid, request)
      .then((result) => {
        setUnblockProgress(false);
        if (result.data.success) {
          toast.success('Unblocked successful');
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setUnblockProgress(false);
      });
  };

  const performTransfer = () => {
    if (selectedResource) {
      if (transferInput.newOwner === '' || transferInput.newOwner.length < 6)
        toast.error('Enter valid account code');
      else if (
        transferInput.reason === '' ||
        transferInput.reason === 'Select reason...'
      )
        toast.error('Why are you transferring this resource?');
      else if (
        transferInput.description === '' ||
        transferInput.description.length < 3
      )
        toast.error('Please provide add remark');

      if (
        transferInput.reason !== '' &&
        transferInput.reason !== 'Select reason...' &&
        transferInput.newOwner !== '' &&
        transferInput.newOwner.length >= 6 &&
        transferInput.description !== '' &&
        transferInput.description.length >= 3
      ) {
        const request = {
          newOwner: transferInput.newOwner,
          reason: transferInput.reason,
          description: transferInput.description,
        };
        setTransferProgress(true);
        resourceService
          .transferResource(selectedResource.ruid, request)
          .then((result) => {
            setOpenTransferModal(false);
            setTransferProgress(false);
            if (result.data.success) {
              toast.success('Transfer successful');
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenTransferModal(false);
            setTransferProgress(false);
          });
      }
    } else {
      toast.error('No resource selected');
    }
  };

  const onAccountCodeInput = (e) => {
    setTransferInput({
      ...transferInput,
      newOwner: e.target.value,
    });

    if (e.target.value && e.target.value.length === 6) {
      performAccountInquiry();
    } else {
      setInquiredUser(null);
      setInProgress(false);
    }
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      setInProgress(true);
      resourceService
        .getAllMyResources()
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            setResponse(result.data.data);
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setInProgress(false);
          setResponse([]);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  return (
    <OtherPageBody>
      <ToastContainer />

      <Dialog open={openDeleteModal}>
        <DialogTitle>Delete Resource</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this resource?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader/> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBlockModal}>
        <DialogTitle>Block Resource</DialogTitle>
        <DialogContent>
          <OptionField
            name="reason"
            children={['Select reason...', 'Missing', 'Stolen', 'Protection']}
            placeholder="Why are you blocking this resource?"
            label="Why are you blocking this resource?"
            onChange={(e) =>
              setBlockInput({
                ...blockInput,
                reason: e.target.value,
              })
            }
            required
          />
          <TextArea
            name="description"
            label="More information"
            placeholder="Remark"
            onChange={(e) =>
              setBlockInput({
                ...blockInput,
                description: e.target.value,
              })
            }
            required
          />

          <div className="ml-em">
            <p>Are you sure you want to block this resource?</p>
            <small className="text-danger">
              Note: if you block resource it can't be traded, unless you can
              unblock
            </small>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenBlockModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!blockProgress ? performBlock : null}
            className="btn btn-primary btn-round"
          >
            {blockProgress ? <TinyLoader/> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTransferModal}>
        <DialogTitle>Transfer Resource</DialogTitle>
        <DialogContent>
          <InputField
            maxLength="6"
            max="6"
            min="6"
            name="newOwner"
            placeholder="Recipient account code"
            label="Account code"
            onChange={onAccountCodeInput}
            required
          />

          {inquiryProgress ? (
            <span className="text-warning ml-em inquired-name">
              Checking account code...
            </span>
          ) : inquiredUser != null ? (
            <span className="text-success ml-em inquired-name">
              {' '}
              {inquiredUser.name}
            </span>
          ) : (
            <span className="text-danger ml-em inquired-name">
              Account not found
            </span>
          )}

          <OptionField
            name="reason"
            children={['Select reason...', 'Sold', 'Gifted', 'Stolen']}
            placeholder="Why are you blocking this resource?"
            label="Why are you transferring this resource?"
            onChange={(e) =>
              setTransferInput({
                ...transferInput,
                reason: e.target.value,
              })
            }
            required
          />
          <TextArea
            name="description"
            label="Description"
            placeholder="Narration"
            onChange={(e) =>
              setTransferInput({
                ...transferInput,
                description: e.target.value,
              })
            }
            required
          />

          <div className="ml-em">
            <p>Are you sure you want to transfer this resource?</p>
            <small className="text-danger">
              Note: if you transfer this resource, you will no longer have
              access to it.
            </small>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenTransferModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!transferProgress ? performTransfer : null}
            className="btn btn-primary btn-round"
          >
            {transferProgress ? <TinyLoader/> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Resources</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search Resources e.g Tecno or active"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <button className="btn btn-primary btn-round ml-auto">
                    <i className="fa fa-plus"></i>
                    <Link
                      className="btn btn-primary btn-round ml-auto"
                      to="/resource/add"
                    >
                      Add Resource
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : response.length > 0 ? (
                response.map((resource, index) => {
                  console.log(resource);
                  return (
                    <div
                      className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                      key={index}
                    >
                      <div className="image-flip">
                        <div className="mainflip flip-0">
                          <div className="frontside">
                            <div className="card">
                              {resource.status === Status.ACTIVE ? (
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    Active
                                  </div>
                                </div>
                              ) : (
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner bg-danger">
                                    Blacklisted
                                  </div>
                                </div>
                              )}
                              <div className="card-body text-left">
                                
                                <h4 className="card-title">
                                  {resource.name}{' '}
                                  {resource.model ? resource.model : ''}
                                </h4>
                                <ul className="card-text">
                                  <li>Type: {resource.type}</li>

                                  <li>
                                    Code:{' '}
                                    <span className="text-danger">
                                      {resource.code}
                                    </span>
                                  </li>
                                  <li>
                                    IMEI:{' '}
                                    <strong>
                                      {resource.identityNumber.substring(0, 10)}
                                      ***
                                    </strong>
                                  </li>
                                  {resource.color ? (
                                    <li>
                                      {' '}
                                      Color: <span>{resource.color}</span>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  <li>
                                    Activated : {format(resource.createdAt)}
                                  </li>
                                </ul>

                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn  btn-primary   mr-3 action-icon"
                                    data-original-title="Transfer resource"
                                    onClick={() =>
                                      selectResource(resource, 'transfer')
                                    }
                                  >
                                    <i className="fa  fa-reply-all"></i>
                                  </button>

                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    className={
                                      resource.status === Status.ACTIVE
                                        ? 'btn  btn-danger'
                                        : 'btn  btn-success'
                                    }
                                    data-original-title="Deactivate"
                                    onClick={(e) => {
                                      selectResource(resource);
                                      resource.status === Status.ACTIVE
                                        ? setOpenBlockModal(true)
                                        : performUnblock();
                                    }}
                                  >
                                    <i
                                      className={
                                        resource.status === Status.ACTIVE
                                          ? '  fa fa-ban'
                                          : ' fa fa-check-square'
                                      }
                                    ></i>
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() =>
                                      selectResource(resource, 'delete')
                                    }
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn  btn-danger"
                                    data-original-title="Remove"
                                  >
                                    <i className="border action-icon border-danger fa fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row card text-center message-box">
                  <p>No resource found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </OtherPageBody>
  );
};

export default AllResources;

import React from "react";
import { PropTypes } from "prop-types";
import TinyLoader from '../loaders/TinyLoader';

const Button = ({
  value,
  name,
  btnType,
  contain,
  isLoading = false,
  ...props
}) => {
  return (
    <div className="form-group">
      <button
        {...props}
        type="submit"
        className={
          "w-100 default-btn btn-block sign-button"
        }
      >
        {isLoading ? <TinyLoader/> : value}
      </button>
    </div>
  );
};

Button.propTypes = {
  // key is the name of the prop and
  // value is the PropType
  contain: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default Button;

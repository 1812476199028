import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let resourceService = {
  createResource: async (body) => {
    return await http.post(`${util.endPoint}/resource`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateResource: async (id, body) => {
    return await http.put(`${util.endPoint}/resource/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  blockResource: async (id, body) => {
    return await http.put(`${util.endPoint}/resource/status/update/${id}?status=BLOCKED`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  unBlockResource: async (id, body) => {
    return await http.put(`${util.endPoint}/resource/status/update/${id}?status=ACTIVE`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteResource: async (id) => {
    return await http.delete(`${util.endPoint}/resource/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateResourceStatus: async (body, id) => {
    return await http.put(
      `${util.endPoint}/resource/status/update/${id}`,
      body,
      {
        headers: util.getAuthorizedHeaders(),
      }
    );
  },
  transferResource: async (id, body) => {
    return await http.post(`${util.endPoint}/resource/ownership/change/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getResourceById: async (id) => {
    return await http.get(
      `${util.endPoint}/resource/detail/${id}`, {
      headers: util.getAuthorizedHeaders(),
    }
    );
  },
  findResourceByCode: async (code) => {
    return await http.get(
      `${util.endPoint}/resource/search/bycode/${code}`,
      util.getHeaders()
    );
  },
  getAllMyResources: async () => {
    return await http.get(`${util.endPoint}/resource/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchAllMyResources: async (searchText) => {
    return await http.get(`${util.endPoint}/resource/search?q=${searchText}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getBlockedResources: async () => {
    return await http.get(`${util.endPoint}/resource?status=blocked`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getOwnershipHistory: async () => {
    return await http.get(`${util.endPoint}/resource/ownership/logs`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  verifyResource: async (body) => {
    return await http.post(`${util.endPoint}/resource/verification`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  createResourceType: async (body) => {
    return await http.post(`${util.endPoint}/resource-type`, body, {
      headers: util.getAuthorizedHeaders(),
    })
  },
  updateResourceType: async (id, body) => {
    return await http.put(`${util.endPoint}/resource-type/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteResourceType: async (id) => {
    return await http.delete(`${util.endPoint}/resource-type/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllResourceType: async () => {
    return await http.get(`${util.endPoint}/resource-type/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  getResourceAnalytics: async () => {
    let url = `${util.endPoint}/report/resource/analytics`
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  }


};

export { resourceService };

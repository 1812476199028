import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { productService } from '../../service/product.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import OptionField from './components/select/OptionField';
import TinyLoader from './components/loaders/TinyLoader';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const AllProductType = () => {
  const [response, setResponse] = useState([]);
  const [categories, setCategories] = useState(['Select product category...']);
  const [inProgress, setInProgress] = useState(true);
  const [productTypeProgress, setProductTypeProgress] = useState(false);
  const [openProductTypeModal, setOpenProductTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const { width } = useWindowDimensions();
  const [productTypeInput, setProductTypeInput] = useState({
    title: '',
    category: '',
    description: '',
  });

  useEffect(() => {
    getAllProductsType();
    getAllProductsCategory();
  }, []);

  const getAllProductsType = () => {
    setInProgress(true);
    productService
      .getAllProductType()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const getAllProductsCategory = () => {
    productService
      .getAllProductCategory()
      .then((result) => {
        if (result.data.success) {
          let categoryList = ['Select product category...'];
          const data = result.data.data;
          data.forEach((category) => {
            categoryList.push(category.title);
            setCategories(categoryList);
          });
          setCategories(categoryList);
          console.log('categories', categoryList);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createProductType = () => {
    if (productTypeInput.category === 'Select product category...')
      toast.error('Select product category');
    else if (productTypeInput.title === '' || productTypeInput.title.length < 3)
      toast.error('Provide valid title');

    if (
      productTypeInput.title !== '' &&
      productTypeInput.title.length >= 3 &&
      productTypeInput.category !== 'Select product category...'
    ) {
      const request = {
        title: productTypeInput.title,
        category: productTypeInput.category,
        description: 'N/A',
      };
      setProductTypeProgress(true);

      if (isEditing) {
        if (selectedProduct) {
          productService
            .updateProductType(selectedProduct.ptuid, request)
            .then((result) => {
              setOpenProductTypeModal(false);
              setProductTypeProgress(false);
              if (result.data.success) {
                toast.success('Updated successful');
                getAllProductsType();
              } else {
                toast.error(result.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error(e.response.data.message);
              setOpenProductTypeModal(false);
              setProductTypeProgress(false);
            });
        } else {
          toast.error('No product selected');
        }
      } else {
        productService
          .createProductType(request)
          .then((result) => {
            setOpenProductTypeModal(false);
            setProductTypeProgress(false);
            if (result.data.success) {
              toast.success('Created successful');
              getAllProductsType();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenProductTypeModal(false);
            setProductTypeProgress(false);
          });
      }
    }
  };

  const performDelete = () => {
    if (selectedProduct) {
      setDeleteProgress(true);
      productService
        .deleteProductType(selectedProduct.ptuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success && result.data.data.deletedCount > 0) {
            toast.success('Deleted successful');
          } else {
            toast.error('Unable to delete product type');
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No product type selected');
    }
  };

  const closeProductTypeModal = () => {
    setIsEditing(false);
    setOpenProductTypeModal(false);
  };

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openProductTypeModal}>
          <DialogTitle>{isEditing ? 'Update Type' : 'Add Type'}</DialogTitle>
          <DialogContent>
            <OptionField
              name="category"
              value={productTypeInput.category}
              children={categories ? categories : []}
              label="Category"
              onChange={(e) =>
                setProductTypeInput({
                  ...productTypeInput,
                  category: e.target.value,
                })
              }
              required
            />

            <InputField
              type="text"
              name="title"
              value={productTypeInput.title}
              placeholder="Provide product type title e.g Android"
              label="Type"
              onChange={(e) =>
                setProductTypeInput({
                  ...productTypeInput,
                  title: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={closeProductTypeModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={!productTypeProgress ? createProductType : null}
              className="btn btn-primary btn-round"
            >
              {productTypeProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteModal}>
          <DialogTitle>Delete Type</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this product type?</p>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : 'Yes'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Product Types</h4>

                    <button
                      type="button"
                      className="btn default-btn btn-primary btn-round btn-large ml-auto"
                      onClick={() => {
                        setProductTypeInput({
                          title: '',
                          description: '',
                        });
                        setIsEditing(false);
                        setOpenProductTypeModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Product Type
                    </button>
                  </div>
                </div>

                {inProgress ? (
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Loader></Loader>
                      </div>
                    </div>
                  </div>
                ) : response.length > 0 ?

                  width > 800 ?
                    (
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              id="add-row"
                              className="display table table-striped table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>S/n</th>
                                  <th>Type</th>
                                  <th>Category</th>
                                  <th>Description</th>
                                  <th>Type ID</th>
                                  <th>Status</th>
                                  <th>Date Added</th>
                                  <th style={{ width: '10%' }}>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {response.map((product, index) => {
                                  return (
                                    <tr key={product.ruid}>
                                      <td>{++index}</td>
                                      <td>{product.title}</td>
                                      <td>{product.category}</td>
                                      <td>{product.description}</td>
                                      <td>{product.ptuid}</td>
                                      <td
                                        className={
                                          product.status === 'ACTIVE'
                                            ? 'text-success'
                                            : 'text-danger'
                                        }
                                      >
                                        {product.status}
                                      </td>
                                      <td>{format(product.createdAt)}</td>

                                      <td>
                                        <div className="form-button-action">
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn  btn-primary  "
                                            data-original-title="Edit Task"
                                            onClick={() => {
                                              setProductTypeInput({
                                                title: product.title,
                                                description: product.description,
                                              });
                                              setIsEditing(true);
                                              setSelectedProduct(product);
                                              setOpenProductTypeModal(true);
                                            }}
                                          >
                                            <i className="fa fa-edit"></i>
                                          </button>
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn  btn-danger"
                                            onClick={() => {
                                              setSelectedProduct(product);
                                              setOpenDeleteModal(true);
                                            }}
                                            data-original-title="Remove"
                                          >
                                            <i className="  fa fa-times"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      response.map((item, index) => {
                        return (
                          <div
                            className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                            key={index}
                          >
                            <div className="image-flip">
                              <div className="mainflip flip-0">
                                <div className="frontside">
                                  <div className="card">
                                    <div className="invoice-ribbon">
                                      <div className="ribbon-inner  bg-primary">
                                        {++index}
                                      </div>
                                    </div>
                                    <div className="card-body text-left">
                                      <h4 className="card-title text-primary">
                                        {item.type}
                                      </h4>
                                      <ul className="card-text">
                                        <li>
                                          <span className="text-danger">
                                            {item.title}
                                          </span>
                                        </li>
                                        <li>
                                          <span className="text-success">
                                            {item.category}
                                          </span>
                                        </li>
                                        <li>
                                          Status :{' '}
                                          <span className="text-success">
                                            {item.status}
                                          </span>
                                        </li>
                                        <li>
                                          Created : {format(item.createdAt)}
                                        </li>
                                      </ul>

                                      <div className="form-button-action">
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-primary  "
                                          data-original-title="Edit Task"
                                          onClick={() => {
                                            setProductTypeInput({
                                              title: item.title,
                                              description: item.description,
                                            });
                                            setIsEditing(true);
                                            setSelectedProduct(item);
                                            setOpenProductTypeModal(true);
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-danger"
                                          onClick={() => {
                                            setSelectedProduct(item);
                                            setOpenDeleteModal(true);
                                          }}
                                          data-original-title="Remove"
                                        >
                                          <i className="  fa fa-times"></i>
                                        </button>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )
                  : (
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="text-center message-box">
                            <p>No product type found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody >
    </>
  );
};

export default AllProductType;

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let serviceService = {
  createServiceType: async (body) => {
    return await http.post(`${util.endPoint}/service-type`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateServiceType: async (id, body) => {
    return await http.put(`${util.endPoint}/service-type/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteServiceType: async (id) => {
    return await http.delete(`${util.endPoint}/service-type/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllServiceType: async () => {
    return await http.get(`${util.endPoint}/service-type/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  }
};

export { serviceService };

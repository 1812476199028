import React, { useEffect } from 'react';
import OtherPageBody from './OtherPageBody';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { resourceService } from '../../service/resource.service';
import Loader from './components/loaders/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import QRCode from 'react-qr-code';
import { titleCase } from 'title-case';
import File from '../../assets/img/file.png';
import Switch from 'react-switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import OptionField from './components/select/OptionField';
import { userService } from '../../service/user.service';
import TinyLoader from './components/loaders/TinyLoader';

const ViewResource = () => {
  const { id } = useParams();
  const [resource, setResource] = useState();
  const [inProgress, setInProgress] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [blockProgress, setBlockProgress] = useState(false);
  const [blockInput, setBlockInput] = useState({
    reason: '',
    description: '',
  });
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [transferProgress, setTransferProgress] = useState(false);
  const [transferInput, setTransferInput] = useState({
    reason: '',
    newOwner: '',
    description: '',
  });
  const [inquiryProgress, setInquiryProgress] = useState(false);
  const [inquiredUser, setInquiredUser] = useState();
  const [isChecked, setIsChecked] = useState(true);

  const operate = (operation) => {
    if (operation === 'delete') setOpenDeleteModal(true);
    if (operation === 'block') setOpenBlockModal(true);
    if (operation === 'transfer') setOpenTransferModal(true);
  };

  const performAccountInquiry = () => {
    setInquiryProgress(true);
    userService
      .getUserByCode(transferInput.newOwner)
      .then((result) => {
        setInquiryProgress(false);
        if (result.data.success) {
          setInquiredUser(result.data.data);
        } else {
          setInquiredUser(null);
        }
      })
      .catch((e) => {
        console.log(e);
        setInquiredUser(null);
        setInquiryProgress(false);
      });
  };

  const performDelete = () => {
    if (resource) {
      setDeleteProgress(true);
      resourceService
        .deleteResource(resource.ruid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success) {
            toast.success('Deleted successful');
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No resource selected');
    }
  };

  const performBlock = () => {
    if (resource) {
      if (blockInput.reason === '' || blockInput.reason === 'Select reason...')
        toast.error('Why are you blocking this resource?');
      else if (
        blockInput.description === '' ||
        blockInput.description.length < 3
      )
        toast.error('Please provide more information');

      if (
        blockInput.reason !== '' &&
        blockInput.reason !== 'Select reason...' &&
        blockInput.description !== '' &&
        blockInput.description.length >= 3
      ) {
        setIsChecked(false);
        const request = {
          reason: blockInput.reason,
          description: blockInput.description,
        };
        setBlockProgress(true);
        resourceService
          .blockResource(resource.ruid, request)
          .then((result) => {
            setOpenBlockModal(false);
            setBlockProgress(false);
            if (result.data.success) {
              toast.success('Blocked');
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenBlockModal(false);
            setBlockProgress(false);
          });
      }
    } else {
      toast.error('No resource selected');
    }
  };

  const performUnblock = () => {
    if (resource) {
      const request = {
        reason: 'N/A',
        description: 'N/A',
      };
      setBlockProgress(true);
      resourceService
        .unBlockResource(resource.ruid, request)
        .then((result) => {
          setBlockProgress(false);
          if (result.data.success) {
            toast.success('Unblocked');
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setBlockProgress(false);
        });
    } else {
      toast.error('No resource selected');
    }
  };

  const performTransfer = () => {
    if (resource) {
      if (transferInput.newOwner === '' || transferInput.newOwner.length < 6)
        toast.error('Enter valid account code');
      else if (
        transferInput.reason === '' ||
        transferInput.reason === 'Select reason...'
      )
        toast.error('Why are you transferring this resource?');
      else if (
        transferInput.description === '' ||
        transferInput.description.length < 3
      )
        toast.error('Please provide add remark');

      if (
        transferInput.reason !== '' &&
        transferInput.reason !== 'Select reason...' &&
        transferInput.newOwner !== '' &&
        transferInput.newOwner.length >= 6 &&
        transferInput.description !== '' &&
        transferInput.description.length >= 3
      ) {
        const request = {
          newOwner: transferInput.newOwner,
          reason: transferInput.reason,
          description: transferInput.description,
        };
        setTransferProgress(true);
        resourceService
          .transferResource(resource.ruid, request)
          .then((result) => {
            setOpenTransferModal(false);
            setTransferProgress(false);
            if (result.data.success) {
              toast.success('Transfer successful');
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenTransferModal(false);
            setTransferProgress(false);
          });
      }
    } else {
      toast.error('No resource selected');
    }
  };

  const onAccountCodeInput = (e) => {
    setTransferInput({
      ...transferInput,
      newOwner: e.target.value,
    });

    if (e.target.value && e.target.value.length === 6) {
      performAccountInquiry();
    } else {
      setInquiredUser(null);
      setInProgress(false);
    }
  };

  const fetchResourceDetails = () => {
    setInProgress(true);
    resourceService
      .getResourceById(id)
      .then((result) => {
        console.log(result);
        setInProgress(false);
        if (result.data.success) {
          const resourceData = result.data.data;
          setResource(resourceData);
          setIsChecked(resourceData.status === 'BLOCKED' ? false : true);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  useEffect(() => {
    fetchResourceDetails();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />

      <Dialog open={openDeleteModal}>
        <DialogTitle>Delete Resource</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this resource?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBlockModal}>
        <DialogTitle>Block Resource</DialogTitle>
        <DialogContent>
          <OptionField
            name="reason"
            children={['Select reason...', 'Missing', 'Stolen', 'Protection']}
            placeholder="Why are you blocking this resource?"
            label="Why are you blocking this resource?"
            onChange={(e) =>
              setBlockInput({
                ...blockInput,
                reason: e.target.value,
              })
            }
            required
          />
          <TextArea
            name="description"
            label="More information"
            placeholder="Remark"
            onChange={(e) =>
              setBlockInput({
                ...blockInput,
                description: e.target.value,
              })
            }
            required
          />

          <div className="ml-em">
            <p>Are you sure you want to block this resource?</p>
            <small className="text-danger">
              Note: if you block resource it can't be traded, unless you
              unblock it
            </small>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenBlockModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!blockProgress ? performBlock : null}
            className="btn btn-primary btn-round"
          >
            {blockProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTransferModal}>
        <DialogTitle>Transfer Resource</DialogTitle>
        <DialogContent>
          <InputField
            maxLength="6"
            max="6"
            min="6"
            name="newOwner"
            placeholder="Recipient account code"
            label="Account code"
            onChange={onAccountCodeInput}
            required
          />

          {inquiryProgress ? (
            <span className="text-warning ml-em inquired-name">
              Checking account code...
            </span>
          ) : inquiredUser != null ? (
            <span className="text-success ml-em inquired-name">
              {' '}
              {inquiredUser.name}
            </span>
          ) : (
            <span className="text-danger ml-em inquired-name">
              Account not found
            </span>
          )}

          <OptionField
            name="reason"
            children={['Select reason...', 'Sold', 'Gifted', 'Stolen']}
            placeholder="Why are you blocking this resource?"
            label="Why are you transferring this resource?"
            onChange={(e) =>
              setTransferInput({
                ...transferInput,
                reason: e.target.value,
              })
            }
            required
          />
          <TextArea
            name="description"
            label="Description"
            placeholder="Narration"
            onChange={(e) =>
              setTransferInput({
                ...transferInput,
                description: e.target.value,
              })
            }
            required
          />

          <div className="ml-em">
            <p>Are you sure you want to transfer this resource?</p>
            <small className="text-danger">
              Note: if you transfer this resource, you will no longer have
              access to it.
            </small>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenTransferModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!transferProgress ? performTransfer : null}
            className="btn btn-primary btn-round"
          >
            {transferProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>

      <div className="page-inner">
        <div className="header-card">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <h4 className="card-title">Resource Preview</h4>

              <button className="btn btn-primary btn-round ml-auto">
                <i className="fa fa-plus"></i>
                <Link
                  className="btn btn-primary btn-round ml-auto"
                  to="/resource/add"
                >
                  {' '}
                  Add Resource
                </Link>
              </button>
            </div>
          </div>
        </div>
        {inProgress ? (
          <Loader />
        ) : resource ? (
          <div className="row mb-5">
            <div className="col-xl-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="card ">
                    <div className="card-body">
                      <div className="meta">
                        <div className="data">
                          <h4 className="title">Resource Information</h4>
                        </div>
                      </div>
                      <ul className="data-ul">
                        <li>
                          <label>Title</label>
                          <p>{resource.name}</p>
                        </li>
                        {resource.model ? (
                          <li>
                            <label>Model</label>
                            <p className="text-success code-text">
                              {resource.model}
                            </p>
                          </li>
                        ) : (
                          <></>
                        )}
                        <li>
                          <label>Code</label>
                          <p className="code-text">{resource.code}</p>
                        </li>
                        {resource.color ? (
                          <li>
                            <label>Color</label>
                            <p>{resource.color} </p>
                          </li>
                        ) : (
                          <></>
                        )}
                        {resource.size ? (
                          <li>
                            <label>Size</label>
                            <p>{resource.size}</p>
                          </li>
                        ) : (
                          <></>
                        )}
                        <li>
                          <label>Owned Date</label>
                          <p>{resource.ownedDate}</p>
                        </li>
                        <li>
                          <label>Registered</label>
                          <p>{format(resource.createdAt)}</p>
                        </li>
                        <li>
                          <label>Description</label>
                          <p>{resource.description}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {resource.ownershipProof ? (
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="meta">
                          <div className="data">
                            <h4 className="title">Proof of Ownership</h4>
                          </div>
                        </div>
                        <div className="data-ul">
                          {resource.ownershipProof &&
                            resource.ownershipProof.receipt ? (
                            <Link
                              to={resource.ownershipProof.receipt}
                              target="_blank"
                              download={
                                'receipt' +
                                resource.ownershipProof.receipt.substring(
                                  resource.ownershipProof.receipt.lastIndexOf(
                                    '.',
                                  ),
                                )
                              }
                            >
                              <img
                                src={File}
                                alt="receipt"
                                className="ownership-proof-doc"
                              />
                            </Link>
                          ) : (
                            <></>
                          )}

                          {resource.cartonDetail &&
                            resource.cartonDetail.picture ? (
                            <Link
                              to={resource.cartonDetail.picture}
                              target="_blank"
                              download={
                                'carton-picture' +
                                resource.cartonDetail.picture.substring(
                                  resource.cartonDetail.picture.lastIndexOf(
                                    '.',
                                  ),
                                )
                              }
                            >
                              <img
                                src={File}
                                alt="receipt"
                                className="ownership-proof-doc"
                              />
                            </Link>
                          ) : (
                            <></>
                          )}

                          {resource.ownershipProof &&
                            resource.ownershipProof.legalDocuments ? (
                            resource.ownershipProof.legalDocuments.map(
                              (file, index) => (
                                <Link
                                  to={file}
                                  target="_blank"
                                  download={
                                    'ownership-doc' +
                                    file.substring(file.lastIndexOf('.'))
                                  }
                                >
                                  <img
                                    key={index}
                                    src={File}
                                    alt="Proof of ownership"
                                    className="ownership-proof-doc"
                                  />
                                </Link>
                              ),
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {resource.witnesses.length > 0 ? (
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="meta">
                          <div className="data">
                            <h4 className="title">Witnesses Information</h4>
                          </div>
                        </div>
                        {resource.witnesses.map((witness, index) => (
                          <>
                            <ul className="data-ul" ke={index}>
                              <li>
                                <label>Name of Witness {++index}</label>
                                <p> {witness.name} </p>
                              </li>
                              <li>
                                <label>Phone Number</label>
                                <p>{witness.phoneNumber} </p>
                              </li>
                              <li>
                                <label>Address</label>
                                <p> {witness.address} </p>
                              </li>
                            </ul>
                            {index < resource.witnesses.length ? (
                              <hr />
                            ) : (
                              <div></div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {resource.locationDetail ? (
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="meta">
                          <div className="data">
                            <h4 className="title">Location Details</h4>
                          </div>
                        </div>
                        <ul className="data-ul">
                          <li>
                            <label>Location</label>
                            <p>{resource.locationDetail.state} </p>
                          </li>
                          <li>
                            <label>L.G.A</label>
                            <p>{resource.locationDetail.lga}</p>
                          </li>
                          <li>
                            <label>Landmark</label>
                            <p>{resource.locationDetail.landmark} </p>
                          </li>
                          <li>
                            <label>Street</label>
                            <p>{resource.locationDetail.address}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="col-xl-2">
              <div className="card">
                <div className="card-body">
                  <div className="responsive-qr-code">
                    <QRCode value={resource.ruid} />
                  </div>
                  <div className="text">
                    <ul className="data-ul">
                      <li>
                        <label>Identity Number</label>
                        <p>{resource.identityNumber.substring(0, 2)}****</p>
                      </li>

                      {resource.cartonDetail ? (
                        <li>
                          <label>Serial Number</label>
                          <p>
                            {resource.cartonDetail.serialNumber.substring(0, 2)}
                            ****
                          </p>
                        </li>
                      ) : (
                        <></>
                      )}

                      <li>
                        <label>Status</label>
                        <p
                          className={
                            resource.status === 'ACTIVE'
                              ? 'text-success'
                              : 'text-danger'
                          }
                        >
                          {titleCase(resource.status)}
                        </p>
                      </li>
                      <li>
                        <label>Type</label>
                        <p className="text-primary">{resource.type}</p>
                      </li>
                    </ul>
                    <div className="actions">
                      <label htmlFor={resource.ruid}>
                        <Switch
                          id={resource.ruid}
                          offColor="#dc3545"
                          onColor="#141c9c"
                          onChange={() => {
                            if (isChecked === true) {
                              operate('block');
                            } else {
                              performUnblock();
                              setIsChecked(true);
                            }
                          }}
                          checked={isChecked}
                        />
                      </label>
                      <br />
                      <br />
                      <button
                        type="button"
                        onClick={() => {
                          operate('delete');
                        }}
                        data-toggle="tooltip"
                        title=""
                        className="btn  btn-danger"
                        data-original-title="Remove"
                      >
                        <i className="border action-icon border-danger fa fa-times"></i>{' '}
                        Delete
                      </button>
                      <br />
                      <br />
                      <button
                        type="button"
                        data-toggle="tooltip"
                        title=""
                        className="btn  btn-primary   mr-3 action-icon"
                        data-original-title="Transfer resource"
                        onClick={() => {
                          operate('transfer');
                        }}
                      >
                        <i className="fa  fa-reply-all"></i>{' '}
                        Transfer
                      </button>

                      <br />
                      <br />
                      <button
                        type="button"
                        data-toggle="tooltip"
                        title=""
                        className="btn  btn-secondary   mr-3 action-icon"
                        data-original-title="Track resource"
                        onClick={() => {
                          operate('track');
                        }}
                      >
                        <i className="fa  fa-map-pin"></i>{' '}
                        Track
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h2>Resource not found</h2>
          </div>
        )}
      </div>
    </OtherPageBody>
  );
};

export default ViewResource;

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { trackingService } from '../../service/tracking.service';
import { resourceService } from '../../service/resource.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import OptionField from './components/select/OptionField';
import { UserType } from '../../enums/UserType';
import { useContext } from 'react';
import { UserContext } from '../../Access';
import TinyLoader from './components/loaders/TinyLoader';

const AllTrackingRequests = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [createTrackingProgress, setCreateTrackingProgress] = useState(false);
  const [openCreateTrackingModal, setOpenCreateTrackingModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [resources, setResources] = useState([
    'Select the missing resource...',
  ]);
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const [newInput, setNewInput] = useState({
    resourceId: '',
    missingReason: '',
    missingDate: '',
    missingArea: '',
    description: '',
  });
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(false);
  const [updateInput, setUpdateInput] = useState({
    reason: '',
    description: '',
  });

  useEffect(() => {
    getTrackingRequests();
  }, []);

  const getTrackingRequests = () => {
    setInProgress(true);
    trackingService
      .getAllTrackingRequest()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          const { data } = result.data;
          setResponse(page);
          if (page.length > 0) {
            setTotalPage(data.totalPages);
            setCurrentPage(data.currentPage);
          }
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createTrackingRequest = () => {
    if (newInput.description === '' || newInput.description.length < 3)
      toast.error('Valid description required');
    else if (newInput.missingDate === '' || newInput.missingDate.length < 3)
      toast.error('Valid Date required');
    else if (newInput.missingArea === '' || newInput.missingArea.length < 3)
      toast.error('Valid Area required');
    else if (newInput.missingReason === '' || newInput.missingReason.length < 3)
      toast.error('Valid Reason required');

    if (
      newInput.description !== '' &&
      newInput.description.length >= 3 &&
      newInput.missingArea !== '' &&
      newInput.missingArea.length >= 3 &&
      newInput.missingReason !== '' &&
      newInput.missingReason.length >= 3 &&
      newInput.missingDate !== '' &&
      newInput.missingDate.length >= 3
    ) {
      const request = {
        resourceId: newInput.resourceId,
        description: newInput.description,
        missingDate: newInput.missingDate,
        missingArea: newInput.missingArea,
        missingReason: newInput.missingReason,
      };
      setCreateTrackingProgress(true);
      trackingService
        .createTrackingRequest(request)
        .then((result) => {
          setOpenCreateTrackingModal(false);
          setCreateTrackingProgress(false);
          if (result.data.success) {
            toast.success('Created successful');
            getTrackingRequests();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.message
              ? Array.isArray(e.response?.data?.message)
                ? e.response?.data?.message.join(' ')
                : e.response?.data?.message
              : e.message,
          );
          setCreateTrackingProgress(false);
        });
    }
  };

  const updateTrackingRequest = () => {
    const request = {
      status: updateInput.title,
      description: updateInput.reason,
    };
    if (selectedResource) {
      setUpdateProgress(true);
      trackingService
        .updateTrackingRequest(selectedResource.puid, request)
        .then((result) => {
          setOpenUpdateModal(false);
          setUpdateProgress(false);
          if (result.data.success) {
            toast.success('Updated successful');
            getTrackingRequests();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenUpdateModal(false);
          setUpdateProgress(false);
        });
    } else {
      toast.error('No product selected');
    }
  };

  const getAllResources = () => {
    resourceService
      .getAllMyResources()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          const resourceList = ['Select the missing resource...'];
          resources.forEach((resource) => {
            resourceList.push({ name: resource.name, id: resource.ruid });
          });

          setResources(resourceList);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  useEffect(() => {
    getAllResources();
  }, []);

  const closeOpenCreateTrackingModal = () => {
    setOpenCreateTrackingModal(false);
  };
  const closeOpenUpdateModal = () => {
    setOpenUpdateModal(false);
  };
  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openCreateTrackingModal}>
          <DialogTitle>Add Tracking Request</DialogTitle>
          <DialogContent>
            <OptionField
              children={resources}
              label="Resource"
              name="resource"
              onChange={(e) => {
                setNewInput({
                  ...newInput,
                  resourceId: e.target.value,
                });
              }}
              required
            />

            <OptionField
              name="missingReason"
              children={['Stolen', 'Missing', 'Robbed']}
              label="Missing Reason"
              placeholder="Missing Reason"
              value={newInput.missingReason}
              onChange={(e) =>
                setNewInput({
                  ...newInput,
                  missingReason: e.target.value,
                })
              }
              required
            />

            <InputField
              type="date"
              name="missingDate"
              label="Missing Date"
              placeholder="Missing Date"
              min={new Date('2000/01/01')}
              max={new Date().toLocaleDateString('en-ca')}
              value={newInput.missingDate}
              onChange={(e) =>
                setNewInput({
                  ...newInput,
                  missingDate: e.target.value,
                })
              }
              required
            />

            <InputField
              type="text"
              name="missingArea"
              label="Missing Area"
              placeholder="Missing Area"
              value={newInput.missingArea}
              onChange={(e) =>
                setNewInput({
                  ...newInput,
                  missingArea: e.target.value,
                })
              }
              required
            />
            <InputField
              type="text"
              name="description"
              label="Description"
              placeholder="Description"
              value={newInput.description}
              onChange={(e) =>
                setNewInput({
                  ...newInput,
                  description: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeOpenCreateTrackingModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={createTrackingRequest}
              className="btn btn-primary btn-round"
            >
              {createTrackingProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={openUpdateModal}>
          <DialogTitle>Update Tracking Request</DialogTitle>
          <DialogContent>
            <OptionField
              children={
                user.accountType === UserType.Admin
                  ? ['Select missing reason...', 'In Progress', 'Found']
                  : ['Select missing reason...', 'Found']
              }
              name="status"
              onChange={(e) => {
                setUpdateInput({
                  ...updateInput,
                  status: e.target.value,
                });
              }}
              required
            />

            <InputField
              type="text"
              name="missingReason"
              label="Missing Reason"
              placeholder="Missing Reason"
              value={updateInput.reason}
              onChange={(e) =>
                setUpdateInput({
                  ...updateInput,
                  reason: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeOpenUpdateModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={updateTrackingRequest}
              className="btn btn-primary btn-round"
            >
              {updateProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Tracking Request</h4>
                    <button
                      className="btn default-btn btn-primary btn-round  btn-large ml-auto"
                      onClick={() => {
                        setNewInput({
                          resourceId: '',
                          description: '',
                          missingArea: '',
                          missingDate: '',
                          missingReason: '',
                        });
                        setOpenCreateTrackingModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Tracking Request
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {inProgress ? (
                        <Loader></Loader>
                      ) : response.length > 0 ? (
                        <table
                          id="add-row"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>S/n</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Reason</th>
                              <th>Status</th>
                              <th>Date Added</th>
                              <th style={{ width: '10%' }} align="center">
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {response.map((resource, index) => {
                              return (
                                <tr key={index}>
                                  <td>{++index}</td>
                                  <td>{resource.resourceName}</td>
                                  <td>{resource.resourceType}</td>
                                  <td>{resource.missingReason}</td>
                                  <td
                                    className={
                                      resource.status === 'ACTIVE'
                                        ? 'text-success'
                                        : 'text-danger'
                                    }
                                  >
                                    {resource.status}
                                  </td>
                                  <td>{format(resource.createdAt)}</td>
                                  <td>
                                    <div className="form-button-action">
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-primary  "
                                        data-original-title="Edit Task"
                                        onClick={() => {
                                          setSelectedResource(resource);
                                          setOpenUpdateModal(true);
                                        }}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center message-box">
                          <p>No resource type found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default AllTrackingRequests;

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let userService = {

  createUser: async (body) => {
    return await http.post(`${util.endPoint}/user/add`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateUser: async (body, userId) => {
    return await http.put(`${util.endPoint}/user/${userId}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  addAdditionalInfo: async (body) => {
    return await http.post(`${util.endPoint}/user/additional/information`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteAdditionalInfo: async (body) => {
    return await http.put(`${util.endPoint}/user/additional/information`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createPIN: async (pin) => {
    return await http.post(`${util.endPoint}/user/create/pin/${pin}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  verifyPIN: async (pin) => {
    return await http.post(`${util.endPoint}/user/verify/pin/${pin}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deactivateUser: async (userId) => {
    return await http.put(`${util.endPoint}/user/deactivate/${userId}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  deleteUser: async (userId) => {
    return await http.delete(`${util.endPoint}/user/${userId}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  activateUser: async (userId) => {
    return await http.put(`${util.endPoint}/user/activate/${userId}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  getUserByCode: async (code) => {
    return await http.get(`${util.endPoint}/user/inquiry/${code}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllUsers: async (currentPage) => {
    return await http.get(`${util.endPoint}/user/list?page=${currentPage}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchUsers: async (currentPage, searchText) => {
    return await http.get(`${util.endPoint}/user/search?page=${currentPage}&q=${searchText}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchBusinesses: async (searchText) => {
    return await http.get(`${util.endPoint}/user/business/search?q=${searchText}`, {
      headers: util.getHeaders(),
    });
  },
  getActiveUsers: async (currentPage) => {
    return await http.get(`${util.endPoint}/user/list?page=${currentPage}&status=ACTIVE`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getInActiveUsers: async (currentPage) => {
    return await http.get(`${util.endPoint}/user/list?page=${currentPage}&status=INACTIVE`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};
export { userService };

import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="copyright ml-auto">
            Copyright © 2022 Phopis. All Rights Reserved. Developed by{' '}
            <a href="https://www.syroltech.com"> Syrol Technologies LTD</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

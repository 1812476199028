import React, { useState, useEffect, useContext } from 'react';
import OtherPageBody from './OtherPageBody';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { inventoryService } from '../../service/inventory.service';
import InputField from './components/inputs/InputField';
import { ToastContainer, toast } from 'react-toastify';
import toMoney from 'number-to-money';
import { userService } from '../../service/user.service';
import SearchField from './components/inputs/SearchField';
import Loader from './components/loaders/Loader';
import signature from './../../assets/img/signature.png';
import { useRef } from 'react';
import { UserContext } from '../../Access';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TinyLoader from './components/loaders/TinyLoader';
import { useReactToPrint } from 'react-to-print';
import * as htmlToImage from 'html-to-image';
import { useNavigate } from 'react-router-dom';
import { storage } from '../../utils/constants/storage';

const InventorySale = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [orderResult, setOrderResult] = useState(null);
  const [openReceiptModal, setOpenReceiptModal] = useState(true);
  const receiptRef = useRef();

  const [allProductsModal, setAllProductsModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [productsSelected, setProductsSelected] = useState([]);
  const [salesProgress, setSalesProgress] = useState(false);
  const [inquiryProgress, setInquiryProgress] = useState(false);
  const [inquiredUser, setInquiredUser] = useState(null);
  const [totalPayable, setTotalPayable] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [checked, setChecked] = useState(false);
  const [openBackdateModal, setOpenBackdateModal] = useState(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [select, setSelect] = useState(
    Array.from({ length: allProducts.length }).fill(false),
  );
  const [salesInput, setSalesInput] = useState({
    customerAccountCode: 0,
    customerPhoneNumber: '',
    customerName: '',
    discount: 0,
    products: [],
    transactionDate: '',
  });

  const [printerType, setPrinterType] = useState(storage.get('printer'));
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(receiptRef.current);
    const link = document.createElement('a');
    link.download = "receipt.png";
    link.href = dataUrl;
    link.click();
    setOpenReceiptModal(false)
    createNewSale();
  }


  const getAllProducts = () => {
    setInProgress(true);
    inventoryService
      .getInventoryProducts(0)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          setAllProducts(page);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const performSearch = (q) => {
    setInProgress(true);
    inventoryService
      .searchInventoryProducts(0, q)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          setAllProducts(page);
        } else {
          setAllProducts([]);
        }
      })
      .catch((e) => {
        setAllProducts([]);
        setInProgress(false);
      });
  };

  const performAccountInquiry = (accountId) => {
    setInquiryProgress(true);
    userService
      .getUserByCode(accountId)
      .then((result) => {
        setInquiryProgress(false);
        if (result.data.success) {
          const user = result.data.data;
          setInquiredUser(user);
          setSalesInput({
            ...salesInput,
            customerName: user.name,
            customerPhoneNumber: user.phoneNumber,
            customerAccountCode: user.code,
          });
        } else {
          setInquiredUser(null);
        }
      })
      .catch((e) => {
        console.log(e);
        setInquiredUser(null);
        setInquiryProgress(false);
      });
  };

  const onAccountCodeInput = (e) => {
    let accountId = e.target.value;
    if (accountId && accountId.length >= 6) {
      setTimeout(() => {
        performAccountInquiry(accountId);
      }, 500);
    } else {
      setInquiredUser(null);
    }
  };

  const createNewSale = () => {
    setInquiredUser(null);
    setSelectedProducts(null);
    setProductsSelected([]);
    setOpenReceiptModal(false);
    setOpenBackdateModal(false);
    getAllProducts();
    setSalesInput({
      customerAccountCode: 0,
      customerPhoneNumber: '',
      customerName: '',
      discount: 0,
      products: [],
      transactionDate: '',
    });
  };
  const handleQuantity = (type, productID) => {    const products = productsSelected.map((item) =>
      item._id === productID
        ? {
          ...item,
          quantity:
            type === 'dec'
              ? item.quantity > 1
                ? item.quantity - 1
                : item.quantity
              : (item.quantity += 1),
          totalPayable: item.sellingPrice * item.quantity,
        }
        : item,
    );

    let tp = 0;
    products.forEach((item) => {
      tp += item.sellingPrice * item.quantity;
    });
    setTotalPayable(tp);
    setProductsSelected(products);
  };

  const handleDelete = (productID) => {
    setProductsSelected((prev) =>
      prev.filter((item) => item._id !== productID),
    );

    const product = productsSelected.find((item) => item._id === productID);
    if (product) {
      let tp = totalPayable - product.sellingPrice * product.quantity;
      setTotalPayable(tp);
    }
  };

  const createSale = () => {
    var validProducts = false;

    if (productsSelected.length > 0) {
      validProducts = true;
    } else {
      toast.error('Select product to sell');
    }

    if (validProducts === true) {
      const request = {
        customerAccountCode: salesInput.customerAccountCode,
        customerPhoneNumber: salesInput.customerPhoneNumber ? salesInput.customerPhoneNumber : '***********',
        customerName: salesInput.customerName ? salesInput.customerName : 'Untitled',
        discount: salesInput.discount,
        transactionDate: salesInput.transactionDate,
        products: productsSelected.map((elem) => ({
          productId: elem.puid,
          quantity: elem.quantity,
        })),
      };

      setSalesProgress(true);
      inventoryService
        .createSale(request)
        .then((result) => {
          setAllProductsModal(false);
          setSalesProgress(false);
          if (result.data.success) {
            setOrderResult(result.data.data);
            setOpenReceiptModal(true);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setSalesProgress(false);
          toast.error(
            e.response?.data?.message
              ? Array.isArray(e.response?.data?.message)
                ? e.response?.data?.message.join(' ')
                : e.response?.data?.message
              : e.message,
          );
        });
    }
  };

  useEffect(() => {
    if (selectedProducts) {
      const isExist = productsSelected.find(
        (item) => item._id === selectedProducts._id,
      );
      if (!isExist) {
        //set quantity
        selectedProducts.totalPayable =
          selectedProducts.sellingPrice * selectedProducts.quantity;
        setProductsSelected((prev) => [...prev, selectedProducts]);

        let tp = totalPayable;
        tp += selectedProducts.sellingPrice * selectedProducts.quantity;
        setTotalPayable(tp);
      }
      setSelectedProducts(null);
    }
  }, [selectedProducts]);

  useEffect(() => {
    getAllProducts();
  }, []);
  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openBackdateModal}>
        <DialogTitle>Backdate Transaction</DialogTitle>
        <DialogContent>
          <InputField
            type="date"
            name="transactionDate"
            value={salesInput.transactionDate}
            autoComplete="off"
            placeholder="Backdate"
            label="Provide transaction date"
            className="form-control"
            onChange={(e) => setSalesInput({
              ...salesInput,
              transactionDate: e.target.value,
            })}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {
              setOpenBackdateModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={!salesProgress ? createSale : null}
          >
            {salesProgress === true ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openReceiptModal && orderResult !== null}>
        <DialogTitle>RECEIPT</DialogTitle>
        <DialogContent>
          {orderResult ? (
            <div className="row">
              <div className="col-md-12">
                {printerType === 'SMALL' ?
                  <div className="panel  small-panel invoice" ref={receiptRef}>
                    <div className="panel-body p-3">
                      <div className="invoice-ribbon">
                        <div className="ribbon-inner  bg-primary">Paid</div>
                      </div>
                      <div className="row  p-5">
                        <div className="row">
                          <div className="col-12">
                            <h1 className="marginright">{user.business}</h1>
                            <span className="marginright">
                              Executed by {orderResult.createdBy}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="mt-3  mb-2">
                          <h2 className="marginright">
                            Ordered by {orderResult.customerName}
                          </h2>
                        </div>
                        <div className="row table-row">
                          <table className="table table-striped table-responsive">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderResult.items.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.name}</td>
                                    <td className="text-left">
                                      {toMoney(item.totalPayable)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">

                          <div className="col-sm-12 text-left pull-left invoice-total mt-5">
                            <h1>
                              Total<br /> ₦
                              {toMoney(
                                orderResult.totalAmount -
                                orderResult.totalDiscount,
                              )}
                            </h1>
                          </div>
                          <div className="col-sm-12">
                            <img src={signature} alt="signature mt-3" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <div className="panel panel-default invoice" ref={receiptRef}>
                    <div className="panel-body p-3">
                      <div className="invoice-ribbon">
                        <div className="ribbon-inner  bg-primary">Paid</div>
                      </div>
                      <div className="row  p-5">
                        <div className="row">
                          <div className={width > 800 ? 'col-sm-6  mt-5' : 'col-sm-6'}>
                            <h3 className="marginright">{user.business}</h3>
                            <span className="marginright">
                              {orderResult.createdBy}
                            </span>
                          </div>
                          <div className={width > 800 ? 'col-sm-6  mt-5 top-right' : 'col-sm-6 top-right'}>
                            <h3 className="marginright">
                              REF-{orderResult.code}
                            </h3>
                            <span className="marginright">
                              {new Date(orderResult.createdAt).toISOString()}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="container mt-4  mb-3">
                          <span className="marginright">
                            Ordered by <strong>{orderResult.customerName}</strong>{' '}
                            with phone number{' '}
                            <strong>{orderResult.customerPhoneNumber}</strong>
                          </span>
                        </div>
                        <div className="row table-row">
                          <table className="table table-striped table-responsive">
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Quantity</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderResult.items.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="text-center">{++index}</td>
                                    <td>{item.name}</td>
                                    <td className="text-right">{item.type}</td>
                                    <td className="text-right">
                                      {item.quantity}
                                    </td>
                                    <td className="text-right">
                                      ₦{toMoney(item.totalPayable)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <img src={signature} alt="signature mt-3" />
                          </div>
                          <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                            <p>Subtotal : ₦{toMoney(orderResult.totalAmount)}</p>
                            <p>
                              Discount : ₦{toMoney(orderResult.totalDiscount)}
                            </p>
                            <p>VAT : ₦0 </p>
                            <p>
                              Total : ₦
                              {toMoney(
                                orderResult.totalAmount -
                                orderResult.totalDiscount,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          ) : (
            <div className="row">
              <p>Unable to generate receipt</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {width > 800 ?
            <button
              className="btn btn-secondary btn-round mr-2"
              onClick={() => {
                downloadImage();
                createNewSale();
                setOpenReceiptModal(false)
                toast.success("Download in progress...")
              }}
            >
              Download
            </button>
            : <></>}

          <button
            className="btn btn-primary btn-round mr-2"
            onClick={() => {
              handlePrint();
              createNewSale();
              setOpenReceiptModal(false)
              toast.success("Printing in progress...")
            }}
          >
            Print
          </button>

          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {
              createNewSale(false);
            }}
          >
            Done
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={allProductsModal}>
        <DialogTitle>Select Products</DialogTitle>
        <DialogContent>
          {allProducts.length > 0 ? (
            <div className="popup-search">
              <SearchField
                value={searchText}
                placeholder="Search services e.g Tecno or Honda"
                onChange={(e) => {
                  const q = e.target.value;
                  setSearchText(q);
                  if (q.length > 2) {
                    setTimeout(() => {
                      performSearch(q);
                    }, 300);
                  } else {
                    if (q.length <= 0) {
                      getAllProducts();
                    }
                  }
                }}
                onClick={() => {
                  performSearch(searchText);
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="table-striped table-responsive">
            {inProgress ? (
              <Loader />
            ) : allProducts.length > 0 ? (
              allProducts.map((product, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    data-toggle="tooltip"
                    title={product.title}
                    className={
                      select[index]
                        ? 'btn default-btn  p-1 m-1'
                        : 'btn default-btn btn-secondary p-1 m-1'
                    }
                    data-original-title="Edit Task"
                    onClick={() => {
                      //select and deselect function
                      if (select[index]) {
                        handleDelete(product._id);
                      } else {
                        setSelectedProducts({ ...product, quantity: 1 });
                      }

                      setSelect((prevState) => {
                        const selectArr = [...prevState];
                        selectArr[index] = !selectArr[index];
                        return selectArr;
                      });
                    }}
                  >
                    {select[index] ? <i className="fa fa-check"></i> : <></>}{' '}
                    {product.title}
                  </button>
                );
              })
            ) : (
              <div className="container text-center">
                <small className="disabled">No product found</small>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-danger btn-border btn-round mr-2"
            onClick={() => {
              setAllProductsModal(false);
            }}
          >
            Done
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Create New Order</h4>

                    <button
                      className="btn btn-primary  btn-round ml-auto btn-large"
                      onClick={createNewSale}
                    >
                      <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                      Create New Order
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ gap: '10px' }}
              className="d-flex align-items-start mt-0 mb-4 group-action"
            >
              <button
                onClick={() => {
                  navigate('/inventory/sales');
                }}
                className="btn default-btn btn-danger btn-round p-2 link-btn"
              >
                <i className="fa fa-eye"></i> View All Sales
              </button>

              <button
                onClick={() => {
                  navigate('/inventory/products');
                }}
                className="btn default-btn btn-secondary btn-round p-2 link-btn"
              >
                All Products
              </button>
            </div>

            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card">

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <InputField
                          type="number"
                          name="Account Code"
                          value={salesInput.customerAccountCode}
                          placeholder="Account Code (Optional)"
                          label="Customer Account Number"
                          onChange={(e) => {
                            onAccountCodeInput(e);
                            setSalesInput({
                              ...salesInput,
                              customerAccountCode: e.target.value,
                            });
                          }}
                        />
                        {inquiryProgress ? (
                          <span className="text-warning ml-em inquired-name mt--10 ">
                            Verifying Account...
                          </span>
                        ) : inquiredUser != null ? (
                          <span className="text-success ml-em inquired-name mt--10 ">
                            {' '}
                            {inquiredUser.name}
                          </span>
                        ) : (
                          <span className="text-danger ml-em inquired-name mt--10 ">
                            Account not found
                          </span>
                        )}

                        <InputField
                          type="text"
                          name="name"
                          disabled={inquiredUser === null ? false : true}
                          value={salesInput.customerName}
                          placeholder="Name (Optional)"
                          label="Customer Name"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              customerName: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputField
                          type="text"
                          name="Phone Number"
                          disabled={inquiredUser === null ? false : true}
                          value={salesInput.customerPhoneNumber}
                          placeholder="Phone Number (Optional)"
                          label="Customer Number"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              customerPhoneNumber: e.target.value,
                            })
                          }
                        />
                        <InputField
                          type="number"
                          name="discount"
                          value={salesInput.discount}
                          placeholder="Discount (Optional)"
                          label="Discount"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              discount: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <table className="table table-striped table-responsive">
                      <tbody>
                        {productsSelected.map((product, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex justify-center">
                                  <div className="mx-3">
                                    <button
                                      onClick={() => {
                                        handleQuantity('dec', product._id);
                                      }}
                                      className="btn btn-primary btn-round m-8"
                                    >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  </div>
                                  <h4>{product.quantity}</h4>
                                  <div className="mx-3">
                                    <button
                                      onClick={() =>
                                        handleQuantity('inc', product._id)
                                      }
                                      className="btn btn-primary btn-round m-8"
                                    >
                                      <i className="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <h4>
                                  ₦
                                  {product.totalPayable > 0
                                    ? toMoney(product.totalPayable)
                                    : toMoney(product.sellingPrice)}
                                </h4>
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    handleDelete(product._id);
                                    setSelect((prevState) => {
                                      const selectArr = [...prevState];
                                      selectArr[index] = !selectArr[index];
                                      return selectArr;
                                    });
                                  }}
                                  className="btn btn-danger btn-round m-8"
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {productsSelected.length > 0 ? (
                      <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                        <p>Subtotal : ₦{toMoney(totalPayable)}</p>
                        <p>
                          Discount : ₦{toMoney(Number(salesInput.discount))}
                        </p>
                        <p>VAT : ₦0 </p>
                        <p>
                          Total : ₦{toMoney(totalPayable - salesInput.discount)}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    <label className="mb-3">
                      <input type="checkbox"
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                      &nbsp; Backdated transaction?
                    </label>
                    <div className="action-buttons ml-md-auto py-2 py-md-0">
                      <button
                        className="btn btn-secondary btn-round mr-2 btn-large mb-3"
                        onClick={() => {
                          setAllProductsModal(true);
                        }}
                      >
                        <i className="fa fa-plus"></i> Select Product
                      </button>
                      <button
                        onClick={!salesProgress ? checked ? () => setOpenBackdateModal(true) : createSale : null}
                        className="btn default-btn btn-round btn-large mb-3"
                      >
                        {salesProgress ? <TinyLoader /> : 'Submit Oder'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OtherPageBody >
  );
};

export default InventorySale;

import React, { useContext } from 'react';
import OtherPageBody from '../users/OtherPageBody';
import Logo from '../../assets/img/avatar.jpg';
import NoImage from '../../assets/img/noimage.png';
import { UserContext } from '../../Access';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import { userService } from '../../service/user.service';
import OptionField from './components/select/OptionField';
import { util } from '../../utils/constants/constants';
import { storage } from '../../utils/constants/storage';
import FileInput from './components/inputs/FileInput';
import { ActionKey } from '../../enums/ActionKey';
import { fileService } from '../../service/file.service';
import toMoney from 'number-to-money';
import Loader from './components/loaders/Loader';
import { UserType } from '../../enums/UserType';
import { BusinessTarget } from '../../enums/BusinessTarget';
import TinyLoader from './components/loaders/TinyLoader';
import { UserRole } from '../../enums/UserRole';


const Profile = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [businessInfo, setBusinessInfo] = useState(user.additionalInfo ? user.additionalInfo : null);
  const [uploadProfileProgress, setUploadProfileProgress] = useState(false);
  const [editProfileProgress, setEditProfileProgress] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);

  const [openContactModal, setOpenContactModal] = useState(false);
  const [addContactProgress, setAddContactProgress] = useState(false);
  const [addContactInput, setAddContactInput] = useState({
    key: '',
    value: ''
  });

  const [openSocialModal, setOpenSocialModal] = useState(false);
  const [addSocialProgress, setAddSocialProgress] = useState(false);
  const [addSocialInput, setAddSocialInput] = useState({
    key: '',
    value: ''
  });

  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [addServiceProgress, setAddServiceProgress] = useState(false);
  const [addServiceInput, setAddServiceInput] = useState({
    title: '',
    description: '',
    image: ''
  });

  const [openProductModal, setOpenProductModal] = useState(false);
  const [addProductProgress, setAddProductProgress] = useState(false);
  const [addProductInput, setAddProductInput] = useState({
    title: '',
    description: '',
    image: '',
    price: 0
  });

  const [openKeywordsModal, setOpenKeywordsModal] = useState(false);
  const [addKeywordsProgress, setAddKeywordsProgress] = useState(false);
  const [addKeywordsInput, setAddKeywordsInput] = useState({
    keywords: businessInfo && businessInfo.keywords ? businessInfo.keywords : '',
  });

  const [editProfileInput, setEditProfileInput] = useState({
    name: user.name,
    alias: user.alias,
    color: businessInfo && businessInfo.color ? businessInfo.color : '',
    shortDescription: businessInfo && businessInfo.shortDescription ? businessInfo.shortDescription : '',
    description: businessInfo && businessInfo.description ? businessInfo.description : '',
    address: user.address,
    map: user.map ? user.map : ''
  });



  const performEditProfile = () => {
    if (editProfileInput.name === '' || editProfileInput.name.length < 3)
      toast.error('Enter valid name');
    else if (
      editProfileInput.address === '' ||
      editProfileInput.address.length < 3
    )
      toast.error('Enter valid address');

    if (
      editProfileInput.name !== '' &&
      editProfileInput.name.length >= 3 &&
      editProfileInput.address !== '' &&
      editProfileInput.address.length >= 3
    ) {

      const request = {
        name: editProfileInput.name,
        alias: editProfileInput.alias,
        color: editProfileInput.color,
        shortDescription: editProfileInput.shortDescription,
        description: editProfileInput.description,
        address: editProfileInput.address,
        map: editProfileInput.map,
      };

      setEditProfileProgress(true);
      userService
        .updateUser(request, user.uuid)
        .then((result) => {
          setEditProfileProgress(false);
          if (result.data.success) {
            const user = result.data.data;
            toast.success('Saved successful');
            userSession.setUser(user);
            storage.save('user', JSON.stringify(user));
            setOpenEditProfileModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenEditProfileModal(false);
        });
    }
  }


  const uploadFile = async (file) => {
    if (file) {
      const fileData = new FormData();
      fileData.append('file', file);
      const fileResponse = await fileService.uploadFile(fileData);
      console.log(fileResponse);
      if (fileResponse.data.success) {
        const url = fileResponse.data.data.url;
        return url;
      } else {
        toast.error(fileResponse.data.message);
      }
    } else {
      toast.error('No file selected');
    }
  }

  const addContact = () => {
    if (addContactInput.key === '' || addContactInput.key === 'Select type...')
      toast.error("Select contact type")
    else if (addContactInput.value === '' || addContactInput.value.length < 3)
      toast.error("Provide valid " + addContactInput.key.toLowerCase())
    else {
      let checkPassed = true;
      if (addContactInput.key === 'EMAIL') {
        if (!addContactInput.value.match(/@/g) || !addContactInput.value.match(/./g)) {
          toast.error('Invalid email address')
          checkPassed = false;
        }
      }
      if (addContactInput.key === 'PHONE') {
        if (addContactInput.value.length !== 11 || !Number(addContactInput.value)) {
          toast.error('Invalid phone number')
          checkPassed = false;
        }
      }

      if (addContactInput.key !== 'PHONE' && addContactInput.key !== 'EMAIL') {
        if (!addContactInput.value.startsWith('http') || !addContactInput.value.match(/./g)) {
          toast.error('Invalid ' + addContactInput.key.toLowerCase + ' url')
          checkPassed = false;
        }
      }

      if (checkPassed) {
        const request = {
          key: ActionKey.Contact,
          value: {
            key: addContactInput.key,
            value: addContactInput.value
          }
        }

        setAddContactProgress(true);
        userService.addAdditionalInfo(request).then((result) => {
          setOpenContactModal(false);
          setAddContactProgress(false);
          if (result.data.success) {
            const user = result.data.data;
            toast.success('Saved successful');
            userSession.setUser(user);
            storage.save('user', JSON.stringify(user));
            setBusinessInfo(result.data.data.additionalInfo)
            setAddContactInput({
              key: '',
              value: ''
            })
          } else {
            toast.error(result.data.message);
          }
        })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenContactModal(false);
            setAddContactProgress(false);
          });
      }
    }
  }



  const addSocialMedia = () => {
    if (addSocialInput.key === '' || addSocialInput.key === 'Select type...')
      toast.error("Select social media type")
    else if (addSocialInput.value === '' || !addSocialInput.value.match(/^http/))
      toast.error("Provide valid " + addSocialInput.key.toLowerCase() + " profile link")
    else {
      const request = {
        key: ActionKey.SOCIALMEDIALINK,
        value: {
          key: addSocialInput.key,
          value: addSocialInput.value
        }
      }

      setAddSocialProgress(true);
      userService.addAdditionalInfo(request).then((result) => {
        setOpenSocialModal(false);
        setAddSocialProgress(false);
        if (result.data.success) {
          const user = result.data.data;
          toast.success('Saved successful');
          userSession.setUser(user);
          storage.save('user', JSON.stringify(user));
          setBusinessInfo(result.data.data.additionalInfo)
          setAddContactInput({
            key: '',
            value: ''
          })
        } else {
          toast.error(result.data.message);
        }
      })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenSocialModal(false);
          setAddSocialProgress(false);
        });
    }
  }

  const addService = async () => {
    if (addServiceInput.title === '' || addServiceInput.title.length < 3)
      toast.error("Provide valid service title")
    else {

      setAddServiceProgress(true);

      let fileURL;
      if (addServiceInput.image && addServiceInput.image.length > 0)
        fileURL = await uploadFile(addServiceInput.image[0]);

      const request = {
        key: ActionKey.Service,
        value: {
          title: addServiceInput.title,
          description: addServiceInput.description,
          image: fileURL
        }
      }
      userService.addAdditionalInfo(request).then((result) => {
        setOpenServiceModal(false);
        setAddServiceProgress(false);
        if (result.data.success) {
          const user = result.data.data;
          toast.success('Saved successful');
          userSession.setUser(user);
          storage.save('user', JSON.stringify(user));
          setBusinessInfo(result.data.data.additionalInfo)
          setAddServiceInput({
            title: '',
            description: '',
            image: ''
          })
        } else {
          toast.error(result.data.message);
        }
      })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenServiceModal(false);
          setAddServiceProgress(false);
        });

    }
  }

  const addProduct = async () => {
    if (addProductInput.title === '' || addProductInput.title.length < 3)
      toast.error("Provide valid product title")
    else if (addProductInput.description === '' || addProductInput.description.length < 3)
      toast.error("Provide valid product description")
    else {

      setAddProductProgress(true);

      let fileURL;
      if (addProductInput.image && addProductInput.image.length > 0)
        fileURL = await uploadFile(addProductInput.image[0]);

      const request = {
        key: ActionKey.Product,
        value: {
          title: addProductInput.title,
          description: addProductInput.description,
          price: addProductInput.price,
          image: fileURL
        }
      }
      userService.addAdditionalInfo(request).then((result) => {
        setOpenProductModal(false);
        setAddProductProgress(false);
        if (result.data.success) {
          const user = result.data.data;
          toast.success('Saved successful');
          userSession.setUser(user);
          storage.save('user', JSON.stringify(user));
          setBusinessInfo(result.data.data.additionalInfo)
          setAddContactInput({
            title: '',
            description: '',
            image: '',
            price: 0
          })
        } else {
          toast.error(result.data.message);
        }
      })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenProductModal(false);
          setAddProductProgress(false);
        });

    }
  }

  const setKeywords = async () => {
    if (addKeywordsInput.keywords === '' || addKeywordsInput.keywords.length < 3)
      toast.error("Provide at least 3 keywords")
    else {

      if (addKeywordsInput.keywords.match(/,/)) {
        const keywords = addKeywordsInput.keywords.split(',')
        setAddKeywordsProgress(true);

        const request = {
          key: ActionKey.Keyword,
          value: keywords
        }
        userService.addAdditionalInfo(request).then((result) => {
          setOpenKeywordsModal(false);
          setAddKeywordsProgress(false);
          if (result.data.success) {
            const user = result.data.data;
            toast.success('Saved successful');
            userSession.setUser(user);
            storage.save('user', JSON.stringify(user));
            setBusinessInfo(result.data.data.additionalInfo)
          } else {
            toast.error(result.data.message);
          }
        })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenKeywordsModal(false);
            setAddKeywordsProgress(false);
          });
      } else {
        toast.error('Keywords most be comma separated')
      }

    }
  }

  const uploadLogo = async (e) => {
    const file = e.target.files;
    if (file && file.length > 0) {
      setUploadProfileProgress(true);
      const fileURL = await uploadFile(file[0]);
      if (fileURL) {

        const userData = user;
        if (!userData.additionalInfo)
          userData.additionalInfo = {};

        userData.dp = fileURL;
        userData.additionalInfo.logo = fileURL;
        userSession.setUser(userData);
        storage.save('user', JSON.stringify(userData));
        setBusinessInfo(user.additionalInfo)

        const request = {
          dp: fileURL
        };
        userService.updateUser(request, user.uuid)
          .then((result) => {
            setUploadProfileProgress(false)
            if (result.data.success) {
              toast.success('Uploaded successful');
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            setUploadProfileProgress(false)
            toast.error(e.response.data.message);
          });
      }
    }
  }

  const deleteContact = (id) => {

    const request = {
      key: ActionKey.Contact,
      value: id
    }

    toast.success('Deleting...')
    userService.deleteAdditionalInfo(request).then((result) => {
      if (result.data.success) {
        const user = result.data.data;
        toast.success('Deleted successful');
        userSession.setUser(user);
        storage.save('user', JSON.stringify(user));
        setBusinessInfo(result.data.data.additionalInfo)
      } else {
        toast.error(result.data.message);
      }
    })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  }

  const deleteSocialLink = (id) => {

    const request = {
      key: ActionKey.SOCIALMEDIALINK,
      value: id
    }

    toast.success('Deleting...')
    userService.deleteAdditionalInfo(request).then((result) => {
      if (result.data.success) {
        const user = result.data.data;
        toast.success('Deleted successful');
        userSession.setUser(user);
        storage.save('user', JSON.stringify(user));
        setBusinessInfo(result.data.data.additionalInfo)
      } else {
        toast.error(result.data.message);
      }
    })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  }
  const deleteService = (id) => {

    const request = {
      key: ActionKey.Service,
      value: id
    }
    toast.success('Deleting...')
    userService.deleteAdditionalInfo(request).then((result) => {
      if (result.data.success) {
        const user = result.data.data;
        toast.success('Deleted successful');
        userSession.setUser(user);
        storage.save('user', JSON.stringify(user));
        setBusinessInfo(result.data.data.additionalInfo)
      } else {
        toast.error(result.data.message);
      }
    })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  }
  const deleteProduct = (id) => {

    const request = {
      key: ActionKey.Product,
      value: id
    }
    toast.success('Deleting...')
    userService.deleteAdditionalInfo(request).then((result) => {
      if (result.data.success) {
        const user = result.data.data;
        toast.success('Deleted successful');
        userSession.setUser(user);
        storage.save('user', JSON.stringify(user));
        setBusinessInfo(result.data.data.additionalInfo)
      } else {
        toast.error(result.data.message);
      }
    })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  }

  return (
    <OtherPageBody>
      <ToastContainer />

      <Dialog open={openContactModal}>
        <DialogTitle>Add Contact</DialogTitle>
        <DialogContent>
          <OptionField
            name="key"
            children={['Select type...'].concat(util.getContacts())}
            value={addContactInput.key}
            placeholder="Select Type"
            label="Contact Type"
            onChange={(e) => {
              setAddContactInput({
                ...addContactInput,
                key: e.target.value
              })
            }}
            required
          />

          <InputField
            type="text"
            name="value"
            placeholder={addContactInput.key === 'EMAIL' ? 'Email Address' :
              addContactInput.key === 'PHONE' ? 'Phone Number' :
                'Profile or Page URL e.g https://example.com/profile/111 '}
            value={addContactInput.value}
            label={addContactInput.key === 'EMAIL' ? 'Enter Email Address' :
              addContactInput.key === 'PHONE' ? 'Enter Phone Number' :
                'Enter the URL'}
            onChange={(e) => {
              setAddContactInput({
                ...addContactInput,
                value: e.target.value
              })
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenContactModal(false)}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={addContactProgress ? null : addContact}
          >
            {addContactProgress ? <TinyLoader /> : 'Add Contact'}
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={openSocialModal}>
        <DialogTitle>Add Social Link</DialogTitle>
        <DialogContent>
          <OptionField
            name="key"
            children={['Select type...'].concat(util.getsSocialLink())}
            value={addSocialInput.key}
            placeholder="Select Type"
            label="Contact Type"
            onChange={(e) => {
              setAddSocialInput({
                ...addSocialInput,
                key: e.target.value
              })
            }}
            required
          />

          <InputField
            type="text"
            name="value"
            placeholder="provide the Link "
            value={addSocialInput.value}
            label="Enter the profile URL"
            onChange={(e) => {
              setAddSocialInput({
                ...addSocialInput,
                value: e.target.value
              })
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenSocialModal(false)}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={addSocialProgress ? null : addSocialMedia}
          >
            {addSocialProgress ? <TinyLoader /> : 'Add Link'}
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={openServiceModal}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          <InputField
            name="title"
            value={addServiceInput.title}
            placeholder="Title"
            label="Service Title"
            onChange={(e) => {
              setAddServiceInput({
                ...addServiceInput,
                title: e.target.value
              })
            }}
            required
          />

          <TextArea
            name="description"
            defaultValue={addServiceInput.description}
            placeholder="Description"
            label="Service Description"
            onChange={(e) => {
              setAddServiceInput({
                ...addServiceInput,
                description: e.target.value
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenServiceModal(false)}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={addServiceProgress !== true ? addService : null}
          >
            {addServiceProgress === true ? <TinyLoader /> : 'Add Service'}
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={openProductModal}>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={addProductInput.title}
            placeholder="Title"
            label="Product Title"
            onChange={(e) => {
              setAddProductInput({
                ...addProductInput,
                title: e.target.value
              })
            }}
            required
          />
          <InputField
            type="number"
            name="price"
            value={addProductInput.price}
            placeholder="Price"
            label="Product Price"
            onChange={(e) => {
              setAddProductInput({
                ...addProductInput,
                price: e.target.value
              })
            }}
          />
          <FileInput
            type="file"
            name="image"
            value={addProductInput.image}
            placeholder="Select product image"
            label="Product Image"
            onChange={(e) => {
              setAddProductInput({
                ...addProductInput,
                image: e.target.files
              })
            }}
          />

          <TextArea
            name="description"
            defaultValue={addProductInput.description}
            placeholder="Description"
            label="Product Description"
            onChange={(e) => {
              setAddProductInput({
                ...addProductInput,
                description: e.target.value
              })
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenProductModal(false)}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={addProductProgress !== true ? addProduct : null}
          >
            {addProductProgress === true ? <TinyLoader /> : 'Add Product'}
          </button>
        </DialogActions>
      </Dialog>



      <Dialog open={openKeywordsModal}>
        <DialogTitle>Set Keywords</DialogTitle>
        <DialogContent>

          <TextArea
            name="keywords"
            defaultValue={addKeywordsInput.keywords}
            placeholder="Keywords e.g  POS, Barbing saloon"
            label="Search Keywords"
            onChange={(e) => {
              setAddKeywordsInput({
                ...addKeywordsInput,
                keywords: e.target.value
              })
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenKeywordsModal(false)}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-round"
            onClick={addKeywordsProgress !== true ? setKeywords : null}
          >
            {addKeywordsProgress === true ? <TinyLoader /> : 'Save Keywords'}
          </button>
        </DialogActions>
      </Dialog>


      <div className="profile panel-header bg-primary-gradient">
        <div className="header-card">
          <div className="card-header">
            <div className="page-inner py-5">
              <div className="profile-info-container d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div className="row">
                  <div className="profile-img-wrapper row">
                    <div className="col-6 profile-img-wrapper">
                      <img src={businessInfo && businessInfo.logo ? businessInfo.logo : Logo} alt="Profile" onError={(e) => e.target.src = Logo} className="card profile-img" />
                      <div className="">

                        <input type="file" id="logo-chooser" className="hidden" name="file" accept="image/*" onChange={uploadLogo} />
                        {uploadProfileProgress ? <label className="btn profile-card-btn edit-btn camera-btn"><Loader width="30px" /></label> :
                          <label htmlFor="logo-chooser" className="btn profile-card-btn edit-btn camera-btn"> <i className="fa fa-camera"></i> </label>}
                      </div>
                    </div>
                    <div className="col-6 profile-detail">
                      <div className="profile-name-wrapper">
                        <h3>{user.name}</h3>
                        <span className="link-address"><i className="fa fa-external-link"></i>{user.alias ? <a rel="noreferrer" href={'https://phopis.com/' + user.alias} target="_blank">https://phopis.com/{user.alias}</a> : <> <span className="code-text">{user.phoneNumber}</span></>}</span>
                      </div>
                      {
                        user.accountType !== UserType.Individual ?
                          <div className="d-flex align-items-center">
                            <div className="detail"><h4>Visits</h4> <span className="text-secondary">{businessInfo && businessInfo.visists ? businessInfo.visits : 0}</span></div>
                            <div className="detail"><h4>Reviews</h4> <span className="text-secondary">{businessInfo && businessInfo.reviews ? businessInfo.reviews.length : 0}</span></div>
                            <div className="detail"><h4>Messages</h4> <span className="text-secondary">0</span></div>
                          </div> : <></>
                      }
                    </div>
                  </div>
                </div>
                {
                  user.role !== UserRole.User?
                    <div className="edit-profile-btn-wrap ml-md-auto py-2 py-md-0">
                      <button
                        className="btn btn-white btn-round mr-2 long-button"
                        onClick={() => {
                          setOpenEditProfileModal(!openEditProfileModal);
                        }}
                      >
                        <i className="fa fa-edit"></i> Edit Profile
                      </button>
                    </div>
                    : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        user.role !== UserRole.User ?
          <div className="profile-card-wrapper">
            {openEditProfileModal ?
              <div className="card profile-card">
                <div className="card-header">
                  <h2>Edit Profile</h2>
                </div>
                <div className="card-body profile-data-card-wrapper">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-3 col-6">
                      <InputField
                        type="text"
                        name="name"
                        value={editProfileInput.name}
                        placeholder="Business name"
                        label="Business name"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-6">
                      <InputField
                        type="text"
                        name="alias"
                        value={editProfileInput.alias}
                        placeholder="Business alias"
                        label="Business Alias"
                        maxLength="20"
                        onChange={(e) => {
                          var nameVal = e.target.value;
                          setEditProfileInput({
                            ...editProfileInput,
                            alias: nameVal.replaceAll(' ', '').toLowerCase().substring(0, 20),
                          })
                        }
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-6">
                      <OptionField
                        children={util.getColors()}
                        name="color"
                        value={editProfileInput.color}
                        placeholder="Color"
                        label="Color"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            color: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-6">
                      <InputField
                        type="text"
                        name="shortDescription"
                        value={editProfileInput.shortDescription}
                        placeholder="Short description about your business"
                        label="Business title"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            shortDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <TextArea
                        name="description"
                        value={editProfileInput.description}
                        placeholder="Full description of your business"
                        label="Tell people more about your Business"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <TextArea
                        name="address"
                        value={editProfileInput.address}
                        label="Business Address"
                        placeholder="Where is your business located?"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            address: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                      <TextArea
                        name="map"
                        value={editProfileInput.map}
                        label="Embed a Map"
                        placeholder="Copy and paste your emending map link here e.g <iframe src=..></iframe>"
                        onChange={(e) =>
                          setEditProfileInput({
                            ...editProfileInput,
                            map: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <button className="btn default-btn" onClick={editProfileProgress ? null : performEditProfile}> {editProfileProgress ? <TinyLoader /> : 'Save Changes'}</button>
                  </div>
                </div>
              </div>
              : <></>}

                <div className="card profile-card">
                  <div className="card-header">
                    <h2>Contacts</h2>
                    <button className="btn default-btn profile-card-btn" onClick={() => {
                      setOpenContactModal(!openContactModal);
                    }} ><i className="fa fa-plus"></i> Add Contact</button>
                  </div>
                  <div className="card-body row profile-data-card-wrapper">
                    {businessInfo && businessInfo.contacts ?
                      businessInfo.contacts.map((item, index) => {
                        return (
                          <div key={index} className="card contact-card col-xl-2 col-lg-3 col-md-3 col-6">
                            <div className="card-header">
                              <span className="fa fa-envelope icon"></span> {item.key}
                              <button className="btn profile-card-btn edit-btn" onClick={() => deleteContact(item.id)}><i className="fa fa-times"></i> </button>
                            </div>
                            <div className="card-body">
                              {item.value}
                            </div>
                          </div>
                        )
                      })
                      : <div className="card-body text-center align-items-center"><p>No contact found</p></div>}
                  </div>
                </div>
                <div className="card profile-card">
                  <div className="card-header mb-3">
                    <h2>Services</h2>
                    <button className="btn default-btn profile-card-btn" onClick={() => {
                      setOpenServiceModal(!openServiceModal)
                    }}><i className="fa fa-plus"></i> Add Service</button>
                  </div>
                  <div className="card-body row profile-data-card-wrapper">

                    {businessInfo && businessInfo.services ?
                      businessInfo.services.map((item, index) => {
                        return (
                          <div key={index} className=" contact-card col-xl-3 col-lg-3 col-md-3 col-6">
                            <div className="card">
                              <div className="card-header">
                                {item.title}
                                <button className="btn profile-card-btn edit-btn" onClick={() => deleteService(item.id)}><i className="fa fa-times"></i> </button>
                              </div>
                              <div className="card-body">
                                {
                                  item.image ?
                                    <img className="card-image" src={item.image} alt="service-pic" onError={(e) => {
                                      e.target.src = NoImage;
                                    }} />
                                    : <></>
                                }
                                {
                                  item.description ?
                                    <p>{item.description}</p>
                                    : <></>
                                }
                              </div>
                            </div>
                          </div>
                        )
                      })
                      : <div className="card-body text-center align-items-center"><p>No service found</p></div>}

                  </div>
                </div>

                {user.businessTarget === BusinessTarget.Product ?
                  <div className="card profile-card">
                    <div className="card-header mb-3">
                      <h2>Products</h2>
                      <button className="btn default-btn profile-card-btn" onClick={() => {
                        setOpenProductModal(!openProductModal)
                      }} ><i className="fa fa-plus"></i> Add Product</button>
                    </div>
                    <div className="card-body row profile-data-card-wrapper">

                      {businessInfo && businessInfo.products ?
                        businessInfo.products.map((item, index) => {
                          return (
                            <div key={index} className="contact-card col-xl-3 col-lg-3 col-md-3 col-6">
                              <div className="card">
                                <div className="card-header">
                                  {item.title}
                                  <button className="btn profile-card-btn edit-btn" onClick={() => deleteProduct(item.id)}><i className="fa fa-times"></i> </button>
                                </div>
                                <div className="card-body">
                                  {item.image ? <img className="card-image" src={item.image} onError={(e) => {
                                    e.target.src = NoImage;
                                  }} alt="service-pic" />
                                    : <></>}
                                  {item.description ?
                                    <p>{item.description}</p>
                                    : <></>}
                                </div>
                                {
                                  item.price ?
                                    <div className="card-footer">
                                      <h5>₦{toMoney(Number(item.price))}</h5>
                                    </div>
                                    : <></>
                                }
                              </div>
                            </div>
                          )
                        })
                        : <div className="card-body text-center align-items-center"><p>No product found</p></div>
                      }
                    </div>
                  </div>
                  : <></>}


                <div className="card profile-card">
                  <div className="card-header">
                    <h2>Social Media Links</h2>
                    <button className="btn default-btn profile-card-btn" onClick={() => {
                      setOpenSocialModal(!openSocialModal);
                    }} ><i className="fa fa-plus"></i> Add Social Link</button>
                  </div>
                  <div className="card-body row profile-data-card-wrapper">
                    {businessInfo && businessInfo.socialMediaLinks ?
                      businessInfo.socialMediaLinks.map((item, index) => {
                        return (
                          <div key={index} className="card contact-card col-xl-2 col-lg-3 col-md-3 col-6">
                            <div className="card-header">
                              {item.key}
                              <button className="btn profile-card-btn edit-btn" onClick={() => deleteSocialLink(item.id)}><i className="fa fa-times"></i> </button>
                            </div>
                            <div className="card-body">
                              {item.value}
                            </div>
                          </div>
                        )
                      })
                      : <div className="card-body text-center align-items-center"><p>No social link found</p></div>}
                  </div>
                </div>

                <div className="card profile-card">
                  <div className="card-header">
                    <h2>Keywords</h2>
                    <button className="btn default-btn profile-card-btn" onClick={() => {
                      setOpenKeywordsModal(!openKeywordsModal)
                    }} ><i className="fa fa-plus"></i> Set Keyword</button>
                  </div>
                  <div className="card-body">

                    {businessInfo && businessInfo.keywords ?
                      businessInfo.keywords.map((item, index) => {
                        return (
                          <div key={index} className="card keyword-card">
                            <div className="card-header">
                              {item}
                            </div>
                          </div>
                        )
                      })
                      : <div className="card-body text-center align-items-center"><p>No keyword found</p></div>}
                  </div>
                </div>
              </div>
              : <></>}
          </OtherPageBody >
  );
};

export default Profile;

import React, { useContext, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import OtherPageBody from './OtherPageBody';
import InputField from './components/inputs/InputField';
import { resourceService } from '../../service/resource.service';
import { UserContext } from '../../Access';
import { ResourceCategory } from '../../enums/ResourceCategory';
import { Status } from '../../enums/Status';
import toMoney from 'number-to-money';
import QRCode from 'react-qr-code';
import { useScreenshot } from 'use-react-screenshot';
import { triggerBase64Download } from 'react-base64-downloader';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userService } from '../../service/user.service';
import signature from './../../assets/img/signature.png';
import { UserRole } from '../../enums/UserRole';
import { UserType } from '../../enums/UserType';
import TinyLoader from './components/loaders/TinyLoader';

const VerifyResource = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [verificationProgress, setVerificationProgress] = useState(false);
  const [verificationResult, setVerificationResult] = useState();
  const [openPinModal, setOpenPinModal] = useState(false);
  const [pinProgress, setPinProgress] = useState();
  const [request, setRequest] = useState();
  const [pin, setPin] = useState();
  const componentRef = useRef();

  const [verificationInput, setVerificationInput] = useState({
    resourceNumber: 0,
    ownerAccountNumber: 0,
    ownerIdentity: '',
  });

  const verifyResource = () => {
    setVerificationProgress(true);
    resourceService
      .verifyResource(request)
      .then((result) => {
        setVerificationProgress(false);
        if (result.data.success) {
          setVerificationResult(result.data.data);
          toast.success('Verification successful');
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message
            ? Array.isArray(e.response?.data?.message)
              ? e.response?.data?.message.join(' ')
              : e.response?.data?.message
            : e.message,
        );
        setVerificationProgress(false);
      });
  };

  const verifyPin = () => {
    if (pin && pin.length < 4) toast.error('Invalid PIN');

    if (pin && pin.length >= 4) {
      setPinProgress(true);
      userService
        .verifyPin(pin)
        .then((response) => {
          setPinProgress(false);
          if (response.data.success) {
            setOpenPinModal(false);
            verifyResource();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          setPinProgress(false);
        });
    }
  };

  const performVerification = () => {
    if (verificationInput.resourceNumber < 6)
      toast.error('Valid Resource Number');
    else if (verificationInput.ownerAccountNumber < 6)
      toast.error('Valid Owner Account Number required');
    else if (verificationInput.ownerIdentity.length < 11)
      toast.error('Valid Owner Identity required');

    if (
      verificationInput.resourceNumber >= 6 &&
      verificationInput.ownerAccountNumber >= 6 &&
      verificationInput.ownerIdentity >= 11
    ) {
      const requestData = {
        resourceNumber: Number(verificationInput.resourceNumber),
        ownerAccountNumber: Number(verificationInput.ownerAccountNumber),
        ownerIdentity: verificationInput.ownerIdentity,
      };
      setRequest(requestData);
      verifyPin();
    }
  };

  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => {
    takeScreenshot(componentRef.current);
    console.log(image);
    triggerBase64Download(image, 'verification');
  };

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openPinModal}>
        <DialogTitle>Verify PIN</DialogTitle>
        <DialogContent>
          <InputField
            type="password"
            name="pin"
            value={pin}
            maxLength="4"
            autoComplete="off"
            placeholder="****"
            label="Provide your 4 digit PIN"
            className="form-control pin-input"
            onChange={(e) => setPin(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-primary btn-round"
            onClick={pinProgress === true ? verifyPin : null}
          >
            {pinProgress === true ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title">Verify Resource</h4>
                  <button
                    className="btn btn-primary btn-round  "
                    onClick={() => {
                      toast.warn(
                        'This feature is not available on this device',
                      );
                    }}
                  >
                    <i className="fas fa-qrcode mx-2"></i>
                    Scan QR
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <InputField
                      type="password"
                      value={verificationInput.resourceNumber}
                      label="Resource Code"
                      maxLength="6"
                      placeholder="Resource Code"
                      name="resourceNumber"
                      onChange={(e) => {
                        setVerificationInput({
                          ...verificationInput,
                          resourceNumber: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col">
                    <InputField
                      type="password"
                      value={verificationInput.ownerAccountNumber}
                      placeholder="Account Code"
                      label="Owner Account Code"
                      name="ownerAccountNumber"
                      maxLength="6"
                      onChange={(e) =>
                        setVerificationInput({
                          ...verificationInput,
                          ownerAccountNumber: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <InputField
                      type="password"
                      value={verificationInput.ownerIdentity}
                      placeholder={
                        user.accountType !== UserType.Individual
                          ? 'Owner PIN'
                          : 'Owner identity number'
                      }
                      label={
                        user.accountType !== UserType.Individual
                          ? 'Provide Owner PIN'
                          : 'Owner identity number'
                      }
                      onChange={(e) =>
                        setVerificationInput({
                          ...verificationInput,
                          ownerIdentity: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col">
                    <button
                      onClick={() => {
                        performVerification();
                      }}
                      className="btn btn-primary btn-round   btn-verify"
                    >
                      {verificationProgress
                        ? <TinyLoader />
                        : 'Verify Resource'}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {verificationProgress ? (
              <Loader />
            ) : verificationResult != null ? (
              <div className="card">
                <div className="card-body row">
                  <div className="col-md-12">
                    <div className="panel panel-default invoice" id="invoice">
                      <div className="panel-body" ref={componentRef}>
                        {verificationResult.resourceDetail.status ===
                          Status.ACTIVE ? (
                          <div className="invoice-ribbon">
                            <div className="ribbon-inner  bg-success">
                              Verified
                            </div>
                          </div>
                        ) : (
                          <div className="invoice-ribbon">
                            <div className="ribbon-inner bg-success">
                              Blacklisted
                            </div>
                          </div>
                        )}
                        <div className="row  p-5">
                          <div className="row">
                            <div className="col-sm-6  top-left mb-2">
                              <QRCode
                                className="card"
                                value={
                                  verificationResult.status === Status.ACTIVE
                                    ? 'Verified'
                                    : 'Blacklisted'
                                }
                              />
                            </div>

                            <div className="col-sm-6  top-right mt-5">
                              <h3 className="marginright">
                                RECEIPT-{verificationResult.transactionRef}
                              </h3>
                              <span className="marginright">
                                {new Date().toISOString()}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-md-4 from">
                              <p className="lead marginbottom">Verified by</p>
                              <p>Name: {user.name}</p>
                              <p>Address: {user.address}</p>
                              <p>Phone: {user.phoneNumber}</p>
                              <p>{user.name}</p>
                            </div>

                            <div className="col-md-4 to">
                              <p className="lead marginbottom">Owned by</p>
                              <p>Name: {verificationResult.ownerDetail.name}</p>
                              <p>
                                Phone:{' '}
                                {verificationResult.ownerDetail.phoneNumber}
                              </p>
                              <p>
                                Account Code:
                                {verificationResult.ownerDetail.code}
                              </p>
                              <p>
                                Owned on:{' '}
                                {verificationResult.resourceDetail.ownedDate}
                              </p>
                            </div>

                            <div className="col-md-4 text-right payment-details">
                              <p className="lead marginbottom payment-info">
                                Resource
                              </p>
                              <p>
                                Code: {verificationResult.resourceDetail.code}
                              </p>
                              <p>
                                Title: {verificationResult.resourceDetail.name}
                              </p>
                              <p>
                                {verificationResult.resourceDetail.type ===
                                  ResourceCategory.SMARTDEVICE
                                  ? 'IMEI'
                                  : 'Identity'}
                                :{' '}
                                {
                                  verificationResult.resourceDetail
                                    .identityNumber
                                }
                              </p>
                              <p>
                                Type: {verificationResult.resourceDetail.type}
                              </p>
                              <p>
                                Registered :{' '}
                                {verificationResult.resourceDetail.createdAt}
                              </p>
                            </div>
                          </div>

                          {verificationResult.resourceDetail
                            .verificationHistory ? (
                            <div className="row table-row">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th className="text-center">#</th>
                                    <th>Resource</th>
                                    <th className="text-right">
                                      transactionRef
                                    </th>
                                    <th className="text-right">verifiedBy</th>
                                    <th className="text-right">verifiedDate</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {verificationResult.resourceDetail.verificationHistory.map(
                                    (verification, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="text-center">
                                            {++index}
                                          </td>
                                          <td>
                                            {
                                              verificationResult.resourceDetail
                                                .name
                                            }
                                          </td>
                                          <td className="text-right">
                                            {verification.transactionRef}
                                          </td>
                                          <td className="text-right">
                                            {verification.verifiedByName}
                                          </td>
                                          <td className="text-right">
                                            {verification.verifiedDate}
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="row">
                            <div className="col-sm-6 margintop">
                              <img src={signature} alt="signature mt-3 mb-5" />

                              <button
                                className="btn btn-success"
                                onClick={getImage}
                              >
                                <i className="fa fa-download"></i> Download
                              </button>
                            </div>
                            <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                              <p>
                                Subtotal : ₦
                                {toMoney(verificationResult.chargedAmount)}
                              </p>
                              <p>
                                Discount : ₦
                                {1000 - verificationResult.chargedAmount}
                              </p>
                              <p>VAT : ₦0 </p>
                              <p>
                                Total : ₦
                                {toMoney(verificationResult.chargedAmount)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </OtherPageBody>
  );
};

export default VerifyResource;

import React, { useState, useEffect, useContext } from 'react';
import OtherPageBody from './OtherPageBody';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { titleCase } from 'title-case';
import Loader from './components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import { Status } from '../../enums/Status';
import TextArea from './components/inputs/TextArea';
import { branchService } from '../../service/branch.service';
import TinyLoader from './components/loaders/TinyLoader';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../Access';
import { userService } from '../../service/user.service';
import { UserType } from '../../enums/UserType';

const AllBranches = () => {

  const userSession = useContext(UserContext);
  const currentUser = userSession.user;

  const [allBranches, setAllBranches] = useState([]);
  const [totalPages, setTotalPage] = useState();
  const [inProgress, setInProgress] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [activateProgress, setActivateProgress] = useState(false);
  const [openEditBranchModal, setOpenEditBranchModal] = useState(false);
  const [openAddBranchModal, setOpenAddBranchModal] = useState(false);
  const [editProgress, setEditProgress] = useState(false);
  const [addProgress, setAddProgress] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState();
  const { width } = useWindowDimensions();
  const [editInput, setEditInput] = useState({
    name: '',
    phoneNumber: '',
    address: '',
  });

  const [addInput, setAddInput] = useState({
    name: '',
    phoneNumber: '',
    address: '',
    nin: '',
    password: '',
  });

  let [currentPage, setCurrentPage] = useState(0);

  const getBranches = () => {
    setInProgress(true);
    branchService
      .getAllBranches(currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          const { data } = result.data;
          setAllBranches(page);
          if (page.length > 0) {
            setTotalPage(data.totalPages);
            setCurrentPage(data.currentPage);
          }
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getBranches();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      setInProgress(true);
      branchService
        .searchBranches(currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setAllBranches(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setAllBranches([]);
          }
        })
        .catch((e) => {
          setAllBranches([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const createBranch = () => {
    if (addInput.name === '' || addInput.name.length < 3)
      toast.error('Valid name required');
    else if (addInput.phoneNumber === '' || addInput.phoneNumber.length < 11)
      toast.error('Valid Phone number required');
    else if (addInput.address === '' || addInput.address.length < 3)
      toast.error('Valid Address required');
    else if (addInput.password === '' || addInput.password.length < 3)
      toast.error('Provide strong password');

    if (
      addInput.name !== '' &&
      addInput.name.length >= 3 &&
      addInput.phoneNumber !== '' &&
      addInput.phoneNumber.length === 11 &&
      addInput.address !== '' &&
      addInput.address.length >= 3 &&
      addInput.password !== '' &&
      addInput.password.length >= 3
    ) {
      const request = {
        name: addInput.name,
        phoneNumber: addInput.phoneNumber,
        address: addInput.address,
        nin: addInput.nin,
        password: addInput.password,
      };
      setAddProgress(true);
      branchService
        .createBranch(request)
        .then((result) => {
          setAddProgress(false);
          if (result.data.success) {
            getBranches();
            toast.success('Created successful');
            setOpenAddBranchModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setAddProgress(false);
        });
    }
  };

  const updateBranch = () => {
    if (editInput.name === '' || editInput.name.length < 3)
      toast.error('Valid name required');
    else if (editInput.phoneNumber === '' || editInput.phoneNumber.length < 11)
      toast.error('Valid Phone number required');
    else if (editInput.address === '' || editInput.address.length < 3)
      toast.error('Valid Address required');

    if (
      editInput.name !== '' &&
      editInput.name.length >= 3 &&
      editInput.phoneNumber !== '' &&
      editInput.phoneNumber.length === 11 &&
      editInput.address !== '' &&
      editInput.address.length >= 3
    ) {
      setEditProgress(true);
      const request = {
        name: editInput.name,
        phoneNumber: editInput.phoneNumber,
        address: editInput.address,
      };
      branchService
        .updateBranch(request, selectedBranch.uuid)
        .then((result) => {
          setEditProgress(false);
          if (result.data.success) {
            toast.success('Saved successful');
            setOpenEditBranchModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setEditProgress(false);
        });
    }
  };

  const performDeactivate = () => {
    setActivateProgress(true);
    branchService
      .deactivateBranch(selectedBranch.uuid)
      .then((result) => {
        setOpenDeactivateModal(false);
        setActivateProgress(false);
        if (result.data.success) {
          getBranches();
          toast.success('Deactivated successful');
          setOpenDeactivateModal(false);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeactivateModal(false);
        setActivateProgress(false);
      });
  };

  const performActivate = () => {
    setActivateProgress(true);
    toast.success('Activating...');
    console.log(selectedBranch);
    branchService
      .activateBranch(selectedBranch.uuid)
      .then((result) => {
        setActivateProgress(false);
        if (result.data.success) {
          getBranches();
          toast.success('Activated successful');
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setActivateProgress(false);
      });
  };

  const performDelete = (userId) => {
    setDeleteProgress(true);
    userService
      .deleteUser(selectedBranch.uuid)
      .then((result) => {
        setOpenDeleteModal(false);
        setDeleteProgress(false);
        if (result.data.success) {
          getBranches();
          toast.success('Deleted successful');
          setOpenDeleteModal(false);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeleteModal(false);
        setDeleteProgress(false);
      });
  };
  useEffect(() => {
    getBranches();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />


      <Dialog open={openDeleteModal}>
        <DialogTitle>Delete Branch</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this branch?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={openDeactivateModal}>
        <DialogTitle>Deactivate Branch</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to deactivate this branch?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeactivateModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!activateProgress ? performDeactivate : null}
            className="btn btn-primary btn-round"
          >
            {activateProgress ? <TinyLoader /> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddBranchModal}>
        <DialogTitle>Create Branch</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="name"
            placeholder="Name"
            value={addInput.name}
            label="Branch Name"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                name: e.target.value,
              });
            }}
            required
          />

          <InputField
            type="tel"
            maxLength="11"
            max="11"
            min="11"
            name="phoneNumber"
            value={addInput.phoneNumber}
            placeholder="Phone Number e.g 08099999999"
            label="Branch Phone Number"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                phoneNumber: e.target.value,
              });
            }}
            required
          />

          <TextArea
            name="address"
            placeholder="Address"
            label="Address"
            value={addInput.address}
            onChange={(e) => {
              setAddInput({
                ...addInput,
                address: e.target.value,
              });
            }}
            required
          />

          <InputField
            type="password"
            name="password"
            placeholder="Set Password"
            value={addInput.password}
            label="Login Password"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                password: e.target.value,
              });
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenAddBranchModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!addProgress ? createBranch : null}
            className="btn btn-primary btn-round"
          >
            {addProgress ? <TinyLoader /> : 'Create Branch'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditBranchModal}>
        <DialogTitle>Update Branch</DialogTitle>
        <DialogContent>
          <InputField
            name="name"
            placeholder="Name"
            value={editInput.name}
            label="Name"
            onChange={(e) => {
              setEditInput({
                ...editInput,
                name: e.target.value,
              });
            }}
            required
          />

          <InputField
            maxLength="11"
            max="11"
            min="11"
            name="phoneNumber"
            value={editInput.phoneNumber}
            placeholder="Phone Number"
            label="Phone Number"
            onChange={(e) => {
              setEditInput({
                ...editInput,
                phoneNumber: e.target.value,
              });
            }}
            required
          />

          <TextArea
            name="address"
            placeholder="Address"
            label="Address"
            value={editInput.address}
            onChange={(e) => {
              setEditInput({
                ...editInput,
                address: e.target.value,
              });
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenEditBranchModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!editProgress ? updateBranch : null}
            className="btn btn-primary btn-round"
          >
            {editProgress ? <TinyLoader /> : 'Save'}
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Branches</h4>

                  <SearchField
                    value={searchText}
                    placeholder="Search Branches"
                    onChange={onSearch}
                    onClick={performSearch}
                  />

                  <button className="btn btn-primary btn-round ml-auto">
                    <i className="fa fa-plus"></i>
                    <button
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setOpenAddBranchModal(true);

                      }}
                    >
                      Add Branch
                    </button>
                  </button>
                </div>
              </div>
            </div>
            {inProgress ? (
              <div className="card">
                <div className="table-responsive table-hover">
                  <Loader></Loader>
                </div>
              </div>
            ) : allBranches.length > 0 ?
              width > 800 ?
                (
                  <div className="card">
                    <div className="table-responsive table-hover">

                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Branch</th>
                            <th>Branch Code</th>
                            <th>Address</th>
                            <th>Telephone</th>
                            <th>Unit</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th style={{ width: '10%' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allBranches.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{titleCase(user.name)}</td>
                                <td>{user.code.toString().substring(0, 2)}****</td>
                                <td>
                                  {user.address},{user.state}
                                </td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.unitCode}</td>
                                <td>{format(user.createdAt)}</td>
                                <td
                                  className={
                                    user.status === 'ACTIVE'
                                      ? 'text-success'
                                      : 'text-danger'
                                  }
                                >
                                  {user.status}
                                </td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary  "
                                      data-original-title="Edit Task"
                                      onClick={() => {
                                        setSelectedBranch(user);
                                        setEditInput(user);
                                        setOpenEditBranchModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      className={
                                        user.status === Status.ACTIVE
                                          ? 'btn  btn-danger'
                                          : 'btn  btn-success'
                                      }
                                      data-original-title="Deactivate"
                                      onClick={(e) => {
                                        setSelectedBranch(user);
                                        user.status === Status.ACTIVE
                                          ? setOpenDeactivateModal(true)
                                          : performActivate();
                                      }}
                                    >
                                      <i
                                        className={
                                          user.status === Status.ACTIVE
                                            ? '  fa fa-ban'
                                            : ' fa fa-check-square'
                                        }
                                      ></i>
                                    </button>

                                    {currentUser.accountType === UserType.Admin ?
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-danger"
                                        data-original-title="Delete"
                                        onClick={(e) => {
                                          setSelectedBranch(user);
                                          setOpenDeleteModal(true);
                                        }}
                                      >
                                        <i
                                          className="fa fa-times"
                                        ></i>
                                      </button>
                                      : <></>}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) :
                allBranches.map((item, index) => {
                  return (
                    <div
                      className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                      key={index}
                    >
                      <div className="image-flip">
                        <div className="mainflip flip-0">
                          <div className="frontside">
                            <div className="card">
                              <div className="invoice-ribbon">
                                <div className={item.status === 'ACTIVE' ? 'ribbon-inner  bg-primary' : 'ribbon-inner  bg-danger'}>
                                  {item.status}
                                </div>
                              </div>
                              <div className="card-body text-left">
                                <h4 className="card-title text-primary">
                                  {item.type} {item.name}
                                </h4>
                                <ul className="card-text">
                                  <li>
                                    {item.address}
                                    <span className="text-danger">
                                    </span>
                                  </li>
                                  <li>
                                    Tel: {item.phoneNumber}
                                    <span className="text-success">
                                    </span>
                                  </li>
                                  <li>
                                    Unit: {item.unitCode}
                                    <span className="text-success">
                                    </span>
                                  </li>
                                  <li>
                                    Account :{' '}
                                    <span className="text-success">
                                      {item.code}
                                    </span>
                                  </li>
                                  <li>
                                    Created : {format(item.createdAt)}
                                  </li>
                                </ul>

                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn  btn-primary  "
                                    data-original-title="Edit Task"
                                    onClick={() => {
                                      setSelectedBranch(item);
                                      setEditInput(item);
                                      setOpenEditBranchModal(true);
                                    }}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    className={
                                      item.status === Status.ACTIVE
                                        ? 'btn  btn-danger'
                                        : 'btn  btn-success'
                                    }
                                    data-original-title="Deactivate"
                                    onClick={(e) => {
                                      setSelectedBranch(item);
                                      item.status === Status.ACTIVE
                                        ? setOpenDeactivateModal(true)
                                        : performActivate();
                                    }}
                                  >
                                    <i
                                      className={
                                        item.status === Status.ACTIVE
                                          ? '  fa fa-ban'
                                          : ' fa fa-check-square'
                                      }
                                    ></i>
                                  </button>


                                  {currentUser.accountType === UserType.Admin ?
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-danger"
                                      data-original-title="Delete"
                                      onClick={(e) => {
                                        setSelectedBranch(item);
                                        setOpenDeleteModal(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-times"
                                      ></i>
                                    </button>
                                    : <></>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
              (
                <div className="card">
                  <div className="table-responsive table-hover">
                    <div className="text-center message-box">
                      <p>
                        {' '}
                        {searchText && searchText.length >= 3
                          ? 'Search not found'
                          : 'No Branch found'}
                      </p>
                    </div>
                  </div>
                </div>
              )}

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className="page-item">
                  <button className="page-link" onClick={() => changePage(-1)}>
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((e, i) => (
                  <li className="page-item" key={i}>
                    <button
                      className={
                        i === currentPage
                          ? 'page-link btn-primary text-white'
                          : 'page-link'
                      }
                      onClick={() => changePage(i)}
                    >
                      {i}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button className="page-link" onClick={() => changePage(+1)}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div >
    </OtherPageBody >
  );
};

export default AllBranches;

const messages = {
  processingMessage: "Please Wait..",
  invalidCredentials: "Invalid Username or Password",
  usernameMessage: "Enter valid username",
  usernameLengthMessage: "Username should be at least grater 3 chars",
  passwordLengthMessage: "Password at least 6 chars",
  phoneLengthMessage: "Enter valid phone number",
  ninLengthMessage: "Enter valid NIN",
  reqNoLengthMessage: "Enter valid RC number",
  otpLengthMessage: "Enter valid OTP",
  passwordMisMatch: "Password Mismatch",
  requestFailed: "Oops! Request failed"
};

export { messages };

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let debtService = {
  createDebt: async (body) => {
    return await http.post(`${util.endPoint}/debt`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  repayDebt: async (body) => {
    return await http.post(`${util.endPoint}/debt/repayment`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateDebt: async (id, body) => {
    return await http.put(`${util.endPoint}/debt/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteDebt: async (id) => {
    return await http.delete(`${util.endPoint}/debt/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllDebt: async (filter, currentPage) => {
    let url = `/debt/list?page=${currentPage}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchDebts: async (filter, currentPage, searchText) => {
    let url = `/debt/search?page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`


    return await http.get(`${util.endPoint}/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getDebtAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/debt/analytics`
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { debtService };

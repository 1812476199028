//guest pages..
import NotFound from "./pages/guest/NotFound";
import { Routes, Route } from "react-router-dom";
import routes from "./utils/routes/userRoutes";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/404" element={<NotFound />} />
        {routes.map((route, index) => {
          return route.protected ? (
            <Route
              key={index}
              path={route.path}
              element={<ProtectedRoute>{<route.component />}</ProtectedRoute>}
            />
          ) : (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

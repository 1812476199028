import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { resourceService } from '../../service/resource.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';

const ResourceOwnershipHistory = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    getAllResources();
  }, []);

  const getAllResources = () => {
    setInProgress(true);
    resourceService
      .getOwnershipHistory()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };
  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">My Old Resources</h4>
                    <button className="btn btn-primary btn-round ml-auto">
                      <Link
                        className="btn btn-primary btn-round ml-auto"
                        to="/resource/all"
                      >
                        View All Resources
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {inProgress ? (
                      <Loader></Loader>
                    ) : response.length > 0 ? (
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>S/n</th>
                            <th>Name</th>
                            <th>Owner</th>
                            <th>Identity Number</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Date Added</th>
                            <th style={{ width: '10%' }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {response.map((resource) => {
                            return (
                              <tr key={resource.ruid}>
                                <td>1</td>
                                <td>{resource.name}</td>
                                <td>{resource.newOwnerName}</td>
                                <td>{resource.color}</td>
                                <td>{resource.model}</td>
                                <td
                                  className={
                                    resource.status === 'ACTIVE'
                                      ? 'text-success'
                                      : 'text-danger'
                                  }
                                >
                                  {resource.status}
                                </td>
                                <td>{format(resource.createdAt)}</td>

                                <td>
                                  <div className="form-button-action">
                                    <Link to="/resource/view">
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-primary  "
                                        data-original-title="Edit Task"
                                      >
                                        <i className="fa fa-eye mx-4"></i>
                                      </button>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center message-box">
                        <p>No resource history found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default ResourceOwnershipHistory;

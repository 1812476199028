import React, { useState, useEffect } from 'react';
import { resourceService } from '../../service/resource.service';
import OtherPageBody from './OtherPageBody';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import { ToastContainer, toast } from 'react-toastify';
import OptionField from './components/select/OptionField';
import { useNavigate } from 'react-router-dom';

const RequestTracking = () => {
  const inputData = {
    name: '',
    model: '',
    color: '',
    description: '',
    identityNumber: '',
  };
  const [resources, setResources] = useState(['Select missing resource...']);
  const [isProcessing, setIsProcessing] = useState(false);
  const [input, setInput] = useState(inputData);
  const navigate = useNavigate();

  const getAllResources = () => {
    resourceService
      .getAllMyResources()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          const resourceList = ['Select missing resource...'];
          resources.forEach((resource) => {
            resourceList.push(resource.name);
          });

          setResources(resourceList);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  useEffect(() => {
    getAllResources();
  }, []);

  return (
    <>
      <ToastContainer />
      <OtherPageBody>
        <div className="page-inner">
          <div className="row">
            <div className="col-xl-12">
              <div className="header-card">
                <div className="card-header">
                  <h4 className="text-left">
                    <i className="fa fa-pencil"></i> Request Tracking
                  </h4>
                  <small>
                    By providing your resource information below, we help you
                    track and find your missing resource{' '}
                  </small>
                </div>
              </div>

              <div className="col-xl-8">
                <div className="panel">
                  <div className="row">
                    <div className="col-md-12 offset-md-2 block">
                      <div className="wrapper-progressBar">
                        <ul className="progressBar">
                          <li className="active">Request Tracking</li>
                          <li className="active">Approve</li>
                          <li>Status</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body collapse-form-body">
                    <div className="row">
                      <div className="col-md-6">
                        <OptionField
                          children={resources}
                          label="Resource"
                          name="resource"
                          onChange={(e) => {
                            setInput({
                              ...input,
                              type: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <OptionField
                          children={[
                            'Select missing reason...',
                            'Stolen',
                            'Misplaced',
                            '',
                          ]}
                          placeholder="Reason"
                          label="How is it missing?"
                          name="reason"
                          onChange={(e) =>
                            setInput({
                              ...input,
                              reason: e.target.value,
                            })
                          }
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <InputField
                          type="date"
                          value={input.ownedDate}
                          placeholder="Missing Date"
                          name="date"
                          label="When did you miss it?"
                          onChange={(e) =>
                            setInput({
                              ...input,
                              date: e.target.value,
                            })
                          }
                          required={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextArea
                          value={input.description}
                          placeholder="Description"
                          label="Tell us about how you miss this resource"
                          onChange={(e) =>
                            setInput({
                              ...input,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row ">
                      <button
                        className="btn btn-primary p-3 btn-round ml-auto m-3  long-button"
                        width="100"
                      >
                        Submit Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default RequestTracking;

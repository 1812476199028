import React from "react";
import DashBody from "./DashBody";
import SiteBody from "./SiteBody";

const Dashboard = () => {
  return (
    <SiteBody>
      <DashBody />
    </SiteBody>
  );
};

export default Dashboard;

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let productService = {
  createProductCategory: async (body) => {
    return await http.post(`${util.endPoint}/product-category`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createProductType: async (body) => {
    return await http.post(`${util.endPoint}/product-type`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createProductSize: async (body) => {
    return await http.post(`${util.endPoint}/product-size`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateProductCategory: async (id, body) => {
    return await http.put(`${util.endPoint}/product-category/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateProductType: async (id, body) => {
    return await http.put(`${util.endPoint}/product-type/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateProductSize: async (id, body) => {
    return await http.put(`${util.endPoint}/product-size/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteProductCategory: async (id) => {
    return await http.delete(`${util.endPoint}/product-category/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteProductType: async (id) => {
    return await http.delete(`${util.endPoint}/product-type/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteProductSize: async (id) => {
    return await http.delete(`${util.endPoint}/product-size/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllProductCategory: async () => {
    return await http.get(`${util.endPoint}/product-category/list`, {
      headers: util.getAuthorizedHeaders(),
    })
  },
  getAllProductType: async () => {
    return await http.get(`${util.endPoint}/product-type/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllProductSize: async () => {
    return await http.get(`${util.endPoint}/product-size/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  },

};

export { productService };

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import OtherPageBody from './OtherPageBody';
import { useContext } from 'react';
import { UserContext } from '../../Access';
import SearchField from './components/inputs/SearchField';

const ManageInvestment = () => {

  const [searchText, setSearchText] = useState();
  const userSession = useContext(UserContext);
  const user = userSession.user;

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Investments</h4>
                    <SearchField
                      value={searchText}
                      placeholder="Search investors"
                      onChange={() => { }}
                    />

                    <button
                      className="btn default-btn btn-primary btn-round  btn-large ml-auto"
                      onClick={() => {
                        toast.warn("Please try again later")
                      }}
                    >
                      <i className="fa fa-plus"></i> &nbsp;
                      Request Funds
                    </button>
                  </div>
                </div>
                <div
                  style={{ gap: '10px' }}
                  className="d-flex align-items-start mt-0 group-action"
                >
                  <button
                    className="btn default-btn btn-primary btn-round p-2 link-btn"
                  >
                     Pay Revenue
                  </button>
                  <button
                    className="btn default-btn btn-secondary btn-round p-2 link-btn"
                  >
                     Return Funds
                  </button>
                </div>
                <br />
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="text-center message-box">
                        <p>Oops! You are not qualified to get funds now</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default ManageInvestment;

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { productService } from '../../service/product.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TinyLoader from './components/loaders/TinyLoader';

const AllProductSize = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [productSizeProgress, setProductSizeProgress] = useState(false);
  const [openProductSizeModal, setOpenProductSizeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productSizeInput, setProductSizeInput] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    getAllProductsSize();
  }, []);

  const getAllProductsSize = () => {
    setInProgress(true);
    productService
      .getAllProductSize()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createProductSize = () => {
    if (productSizeInput.title === '' || productSizeInput.title.length < 3)
      toast.error('Provide valid title');
    else if (
      productSizeInput.description === '' ||
      productSizeInput.description.length < 3
    )
      toast.error('Valid description required');

    if (
      productSizeInput.title !== '' &&
      productSizeInput.title.length >= 3 &&
      productSizeInput.description !== '' &&
      productSizeInput.description.length >= 3
    ) {
      const request = {
        title: productSizeInput.title,
        description: productSizeInput.description,
      };
      setProductSizeProgress(true);

      if (isEditing) {
        if (selectedProduct) {
          productService
            .updateProductSize(selectedProduct.psuid, request)
            .then((result) => {
              setOpenProductSizeModal(false);
              setProductSizeProgress(false);
              if (result.data.success) {
                toast.success('Updated successful');
                getAllProductsSize();
              } else {
                toast.error(result.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
              toast.error(e.response.data.message);
              setOpenProductSizeModal(false);
              setProductSizeProgress(false);
            });
        } else {
          toast.error('No product selected');
        }
      } else {
        productService
          .createProductSize(request)
          .then((result) => {
            setOpenProductSizeModal(false);
            setProductSizeProgress(false);
            if (result.data.success) {
              toast.success('Created successful');
              getAllProductsSize();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenProductSizeModal(false);
            setProductSizeProgress(false);
          });
      }
    }
  };

  const performDelete = () => {
    if (selectedProduct) {
      setDeleteProgress(true);
      productService
        .deleteProductSize(selectedProduct.psuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success && result.data.data.deletedCount > 0) {
            toast.success('Deleted successful');
          } else {
            toast.error('Unable to delete product size');
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No product type selected');
    }
  };

  const closeProductSizeModal = () => {
    setIsEditing(false);
    setOpenProductSizeModal(false);
  };

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openProductSizeModal}>
          <DialogTitle>{isEditing ? 'Update' : 'Add'}</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="title"
              value={productSizeInput.title}
              placeholder="Title"
              label="Title"
              onChange={(e) =>
                setProductSizeInput({
                  ...productSizeInput,
                  title: e.target.value,
                })
              }
              required
            />

            <InputField
              name="description"
              label="Description"
              placeholder="Description"
              value={productSizeInput.description}
              onChange={(e) =>
                setProductSizeInput({
                  ...productSizeInput,
                  description: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={closeProductSizeModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={!productSizeProgress ? createProductSize : null}
              className="btn btn-primary btn-round"
            >
              {productSizeProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteModal}>
          <DialogTitle>Delete Size</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this product size?</p>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : 'Yes'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Product Sizes</h4>

                    <button
                      type="button"
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setProductSizeInput({
                          title: '',
                          description: '',
                        });
                        setIsEditing(false);
                        setOpenProductSizeModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Product Size
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {inProgress ? (
                        <Loader></Loader>
                      ) : response.length > 0 ? (
                        <table
                          id="add-row"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>S/n</th>
                              <th>Size</th>
                              <th>Description</th>
                              <th>Type ID</th>
                              <th>Status</th>
                              <th>Date Added</th>
                              <th style={{ width: '10%' }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {response.map((product, index) => {
                              return (
                                <tr key={product.ruid}>
                                  <td>{++index}</td>
                                  <td>{product.title}</td>
                                  <td>{product.description}</td>
                                  <td>{product.psuid}</td>
                                  <td
                                    className={
                                      product.status === 'ACTIVE'
                                        ? 'text-success'
                                        : 'text-danger'
                                    }
                                  >
                                    {product.status}
                                  </td>
                                  <td>{format(product.createdAt)}</td>

                                  <td>
                                    <div className="form-button-action">
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn  btn-primary  "
                                          data-original-title="Edit Task"
                                          onClick={() => {
                                            setProductSizeInput({
                                              title: product.title,
                                              description: product.description,
                                            });
                                            setIsEditing(true);
                                            setSelectedProduct(product);
                                            setOpenProductSizeModal(true);
                                          }}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </button>
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-danger"
                                        onClick={() => {
                                          setSelectedProduct(product);
                                          setOpenDeleteModal(true);
                                        }}
                                        data-original-title="Remove"
                                      >
                                        <i className="  fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center message-box">
                          <p>No product size found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default AllProductSize;

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../assets/img/avatar.png';
import { ToastContainer, toast } from 'react-toastify';
import { UserContext } from '../../../Access';
import { storage } from '../../../utils/constants/storage';
import { titleCase } from 'title-case';
import { userService } from '../../../service/user.service';
import Loader from './loaders/Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const NavBar = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [showResource, setshowResource] = useState(false);
  const [showTheme, setShowTheme] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchProgress, setSearchProgress] = useState(false);

  const [theme, setTheme] = useState();
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const currentTheme = storage.get('theme');

  const profileDropDown = () => {
    setShowProfile(!showProfile);
  };
  const resourceDropDown = () => {
    setshowResource(!showResource);
  };
  const themeDropDown = () => {
    setShowTheme(!showTheme);
  };

  const logout = () => {
    storage.remove('userToken');
    storage.remove('user');
    toast.warn('Logging out...');
    setTimeout(() => {
      window.location = '/user/login';
    }, 500);
  };

  const lockScreen = () => {
    storage.save('locked', 'yes');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changeTheme = (theme) => {
    storage.save('theme', theme);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 2) {
      setSearchProgress(true);
      userService.searchBusinesses(searchText)
        .then((result) => {
          setSearchProgress(false);
          if (result.data.success) {
            setSearchResult(result.data.data.page);
          } else {
            setSearchResult(null);
          }
        })
        .catch((e) => {
          setSearchProgress(false);
          setSearchResult(null);
        });
    } else {
      setSearchResult(null);
    }
  }

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };
  useEffect(() => {
    let themeName = 'Change Theme';
    if (currentTheme === 'grayscale(1)') themeName = 'Dark';
    if (currentTheme === 'hue-rotate(45deg)') themeName = 'Hue';
    if (currentTheme === 'invert(1)') themeName = 'Invert';
    if (currentTheme === 'sepia(1)') themeName = 'Sepia';
    if (currentTheme === 'saturate(0.5)') themeName = 'Saturate';
    if (currentTheme === 'unset') themeName = 'Default';

    setTheme(themeName);
  }, [currentTheme]);

  return (
    <>
      <ToastContainer />
      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="blue2"
      >
        <div className="container-fluid">
          <div className="collapse" id="search-nav">
            <form className="navbar-left navbar-form nav-search mr-md-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <button type="submit" className="btn btn-search pr-1" onClick={onSearch}>
                    <i className="fa fa-search search-icon"></i>
                  </button>
                </div>
                <input
                  type="text"
                  onChange={onSearch}
                  placeholder="Search Businesses and shops around"
                  className="form-control head-search-input"
                />
              </div>
              <div className="nav-search-result" style={{ display: !searchResult && !searchProgress ? 'none' : 'block' }}>
                {searchProgress ? <div className="loader-wrapper text-align-center"><Loader /></div> :
                  <ul className="result-set">
                    {searchResult && searchResult.length > 0 ?
                      searchResult.map((business, index) => {
                        return <li key={index}>
                          <a target="_blank" rel="noreferrer" href={business.alias ? 'https://phopis.com/' + business.alias : '#'}>{business.name}<p className="sub-search-text">{business.businessType} at {business.address} {business.state}</p></a></li>
                      })
                      : <>Search not found</>}
                  </ul>
                }
              </div>
            </form>
          </div>
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item toggle-nav-search hidden-caret">
              <Link
                to="/"
                className="nav-link"
                data-toggle="collapse"
                href="#search-nav"
                role="button"
                aria-expanded="false"
                aria-controls="search-nav"
              >
                <i className="fa fa-search"></i>
              </Link>
            </li>

            {/* SHow wallet */}
            <li
              className={
                'nav-item dropdown hidden-caret' + (showTheme ? ' show' : '')
              }
            >
              <div
                className="nav-link dropdown-toggle"
                id="messageDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={showTheme ? 'true' : 'false'}
              >
                <p
                  onClick={() => themeDropDown()}
                  className="theme-select fa fa-themeisle"
                >
                  {theme} <i className="caret"></i>{' '}
                </p>
              </div>
              <ul
                className={
                  'dropdown-menu dropdown-user animated fadeIn' +
                  (showTheme ? ' show' : '')
                }
              >
                <div className="dropdown-user-scroll scrollbar-outer theme-option">
                  <li onClick={() => changeTheme('grayscale(1)')}>Dark</li>
                  <li onClick={() => changeTheme('hue-rotate(45deg)')}>Hue</li>
                  <li onClick={() => changeTheme('invert(1)')}>Invert</li>
                  <li onClick={() => changeTheme('sepia(1)')}>Sepia</li>
                  <li onClick={() => changeTheme('saturate(0.5)')}>Saturate</li>
                  <li onClick={() => changeTheme('unset')}>Default</li>
                </div>
              </ul>
            </li>

            <li
              className={
                'nav-item dropdown hidden-caret' + (showResource ? ' show' : '')
              }
            >
              <div
                className="nav-link dropdown-toggle"
                id="messageDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={showResource ? 'true' : 'false'}
              >
                <i
                  onClick={() => resourceDropDown()}
                  className="fa fa-bell"
                ></i>
              </div>
              <ul
                className={
                  'dropdown-menu dropdown-user animated fadeIn' +
                  (showResource ? ' show' : '')
                }
              >
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/">
                      No Notifications{' '}
                    </Link>
                  </li>
                </div>
              </ul>
            </li>

            {/* SHow Profile */}
            <li
              className={
                'nav-item dropdown hidden-caret' + (showProfile ? ' show' : '')
              }
            >
              <div
                className="dropdown-toggle profile-pic"
                data-toggle="dropdown"
                aria-expanded={showProfile ? 'true' : 'false'}
              >
                <div className="avatar-sm">
                  <img
                    src={user.dp ? user.dp : Avatar}
                    onError={(e) => e.target.src = Avatar}
                    alt="..."
                    onClick={() => profileDropDown()}
                    className="avatar-img rounded-circle card"
                  />
                </div>
              </div>
              <ul
                className={
                  'dropdown-menu dropdown-user animated fadeIn' +
                  (showProfile ? ' show' : '')
                }
              >
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="user-box">
                      <div className="avatar-lg">
                        <img
                          src={Avatar}
                          alt="/"
                          className="avatar-img rounded"
                        />
                      </div>
                      <div className="u-text">
                        <h4>{titleCase(user.name.toLowerCase())}</h4>
                        <p className="text-muted">
                          Account :{user.code.toString().substring(0, 2)}****
                        </p>
                        <Link
                          to="/user/profile"
                          className="btn btn-xs btn-secondary btn-sm"
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>

                    <Link className="dropdown-item" to="/user/reset-password">
                      Change Password
                    </Link>

                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item" onClick={() => lockScreen()}>
                      Lock Screen
                    </div>
                    <div className="dropdown-divider"></div>
                    <CopyToClipboard text={'https://dashboard.phopis.com/user/register/business/?referee=' + user.code} onCopy={() => {
                      toast.success('Referral Link Copied...');
                    }}>
                      <div className="dropdown-item" >
                        Copy Referral Link
                      </div>
                    </CopyToClipboard>

                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item" onClick={() => logout()}>
                      Logout
                    </div>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from './components/button/Button';
import InputField from './components/inputs/InputField';
import { auth } from '../../service/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../utils/constants/messages';
import { storage } from '../../utils/constants/storage';
import shape1 from './../../assets/img/shape/vector-shape11.png';
import shape2 from './../../assets/img/shape/shape2.png';
import shape3 from './../../assets/img/shape/shape3.png';
import SignNavBar from './components/SignNavBar';
import TinyLoader from './components/loaders/TinyLoader';

const VerifyUser = () => {
  const storedUsername = storage.get('username');
  const [input, setInput] = useState({
    phoneNumber:
      typeof storedUsername !== 'undefined' && storedUsername !== 'undefined'
        ? storedUsername
        : '',
    otp: '',
  });
  const { phoneNumber, otp } = input;
  const [isProcessing, setIsProcessing] = useState(false);
  const [isResendingProcessing, setIsResendingProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const verify = () => {
    if (input.otp.length < 6) toast.error('Enter valid OTP');
    if (input.otp.length >= 6) {
      const request = {
        username: input.phoneNumber,
        otp: input.otp,
      };
      setIsProcessing(true);
      auth
        .verifyAccount(request)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success(result.data.message);
            setTimeout(() => {
              window.location = '/user/login';
            }, 500);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          toast.error(e.response.data.message);
        });
    }
  };

  const resendOTP = () => {
    const request = {
      username: input.phoneNumber,
    };
    setIsResendingProcessing(true);
    auth
      .validateAccount(request)
      .then((result) => {
        setIsResendingProcessing(false);
        if (result.data.success) {
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        setIsResendingProcessing(false);
        toast.error(e.response.data.message);
      });
  };
  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Verify your Account</h2>
            <ul>
              <li>Already verified?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/login">Signin</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            {typeof storedUsername === 'undefined' ||
              storedUsername === 'undefined' ? (
              <div className="">
                <InputField
                  type="text"
                  value={input.phoneNumber}
                  maxLength="11"
                  placeholder="Enter your Phone Number"
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  required
                />
              </div>
            ) : (
              <></>
            )}

            <div className="">
              <InputField
                type="text"
                value={input.otp}
                maxLength="6"
                placeholder="Enter OTP"
                label="Enter OTP"
                name="otp"
                onChange={handleChange}
                required
              />
            </div>

            <div className="text-right">
              &nbsp; &nbsp;&nbsp;Not get the OTP yet?{' '}
              <Link
                className="btn"
                to="#"
                onClick={isResendingProcessing ? null : resendOTP}
              >
                {isResendingProcessing ? <TinyLoader /> : 'Resend'}
              </Link>
            </div>

            <div className="button">
              <Button
                isLoading={isProcessing}
                type="button"
                value={
                  isProcessing ? messages.processingMessage : 'Verify Account'
                }
                name="button"
                onClick={() => (!isProcessing ? verify() : null)}
                contain={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default VerifyUser;

import React from 'react';

const FileInput = ({ label, value, required = false, myCHange, ...props }) => {
  let handleChange = (cChange) => {
    myCHange = cChange;
  };
  return (
    <div className="form-group">
      {label && (
        <div>
          <label htmlFor="input-field">
            {label} <span className="required-star">{required ? '*' : ''}</span>
          </label>
        </div>
      )}
      <input
        type="file"
        className="form-control"
        accept="image/*,application/pdf"
        onChange={handleChange(myCHange)}
        {...props}
      />
    </div>
  );
};

export default FileInput;

import React from "react";
import "../../../../assets/css/inputs.css";

const InputField = ({
  inputRef,
  label,
  value,
  onChange,
  onClick,
  option,
  required = false,
  ...props
}) => {
  return (
    <div className="input-group head-search-wrapper">

      <div className="d-flex form-control rounded inner-search-field icon-input-wrapper" >
        <i className="fa fa-search icon"></i>
        <input
          type="search"
          onChange={onChange}
          {...props}
          aria-label="Search"
          aria-describedby="search-addon"
        />
      </div>
      <button
        ref={inputRef}
        type="button"
        onClick={onClick}
        className="btn btn-primary header-search-button btn-large"
      >
        <i className="fa fa-search"></i>
      </button>
    </div>
  );
};

export default InputField;

const Status = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    SUSPENDED: 'SUSPENDED',
    BLOCKED: 'BLOCKED',
    RELEASED: 'RELEASED',
    FAILED: 'FAILED',
    SUCCESSFUL: 'SUCCESSFUL',
    AVAILABLE: 'AVAILABLE',
    UNAVAILABLE: 'UNAVAILABLE',
    PENDING: 'PENDING',
    INPROGRESS: 'INPROGRESS',
    FOUND: 'FOUND'
}
export { Status };

import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let branchService = {

  createBranch: async (body) => {
    return await http.post(`${util.endPoint}/branch/add`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateBranch: async (body, userId) => {
    return await http.put(`${util.endPoint}/user/${userId}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deactivateBranch: async (userId) => {
    return await http.put(`${util.endPoint}/user/deactivate/${userId}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  activateBranch: async (userId) => {
    return await http.put(`${util.endPoint}/user/activate/${userId}`, {}, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  getAllBranches: async (currentPage) => {
    return await http.get(`${util.endPoint}/branch/list?page=${currentPage}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchBranches: async (currentPage, searchText) => {
    return await http.get(`${util.endPoint}/branch/search?page=${currentPage}&q=${searchText}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};
export { branchService };

function Location() {
    this.getCities = () => {
        return [
            'Select location...',
            'Abuja',
            'Abia',
            'Adamawa',
            'Akwa Ibom',
            'Anambra',
            'Bauchi',
            'Bayelsa',
            'Benue',
            'Borno',
            'Cross River',
            'Delta',
            'Ebonyi',
            'Edo',
            'Ekiti',
            'Enugu',
            'Gombe',
            'Imo',
            'Jigawa',
            'Kaduna',
            'Kano',
            'Katsina',
            'Kebbi',
            'Kogi',
            'Kwara',
            'Lagos',
            'Nassarawa',
            'Niger',
            'Ogun',
            'Ondo',
            'Osun',
            'Oyo',
            'Plateau',
            'Rivers',
            'Sokoto',
            'Taraba',
            'Yobe',
            'Zamfara'];
    }
    this.getArea = (state) => {
        if (state === "Adamawa") {

            return ['Select area...','Demsa', 'Fufore', 'Ganye', 'Gayuk', 'Girei', 'Gombi', 'Guyuk', 'Hong', 'Jada', 'Lamurde', 'Madagali', 'Maiha', 'Mayo Belwa', 'Michika', 'Mubi North', 'Mubi South', 'Numan', 'Shelleng', 'Song, Nigeria', 'Toungo', 'Yola North', 'Yola South']

        }
        else if (state === 'Bauchi') {


            return ['Select area...','Alkaleri', 'Bauchi', 'Bogoro', 'Damban', 'Darazo', 'Dass', 'Gamawa', 'Ganjuwa', 'Giade', 'Itas/Gadau', 'Jamaare', 'Katagum', 'Kirfi', 'Misau', 'Ningi', 'Shira', 'Tafawa Balewa', 'Toro', 'Warji', 'Zaki']

        }
        else if (state === 'Niger') {



            return ['Select area...','Agaie', 'Agwara', 'Bida', 'Borgu', 'Bosso', 'Chanchaga', 'Edati', 'Gbako', 'Gurara', 'Katcha', 'Kontagora', 'Lapai', 'Lavun', 'Magama', 'Mariga', 'Mashegu', 'Mokwa', 'Munya', 'Paikoro', 'Rafi', 'Rijau', 'Shiroro', 'Suleja', 'Tafa', 'Wushishi']

        }

        else if (state === "Borno") {



            return ['Select area...','Abadam', 'Askira/Uba', 'Bama', 'Bayo', 'Biu', 'Chibok', 'Damboa', 'Dikwa', 'Gubio', 'Guzamala', 'Gwoza', 'Hawul', 'Jere, Borno', 'Kaga', 'Kala/Balge', 'Konduga', 'Kukawa', 'Kwaya Kusar', 'Mafa', 'Magumeri', 'Maiduguri', 'Marte', 'Mobbar', 'Monguno', 'Ngala', 'Nganzai', 'Shani']

        }

        else if (state === "Gombe") {



            return ['Select area...','Akko', 'Balanga', 'Billiri', 'Dukku', 'Funakaye', 'Gombe', 'Kaltungo', 'Kumo', 'Kwami', 'Nafada', 'Shongom', 'Yamaltu/Deba']

        }

        else if (state === "Jigawa") {



            return ['Select area...','Auyo', 'Babura', 'Biriniwa', 'Birnin Kudu', 'Buji', 'Dutse', 'Gagarawa', 'Garki', 'Gumel', 'Guri', 'Gwaram', 'Gwiwa', 'Hadejia', 'Jahun', 'Kafin Hausa', 'Kaugama', 'Kazaure', 'Kiri Kasama', 'Kiyawa', 'Maigatari', 'Malam Madori', 'Miga', 'Ringim', 'Roni', 'Sule Tankarkar', 'Taura', 'Yankwashi']

        }
        else if (state === "Kaduna") {



            return ['Select area...','Birnin Gwari', 'Chikun', 'Giwa', 'Igabi', 'Ikara', 'Jaba', 'Jemaa', 'Kachia', 'Kaduna North', 'Kaduna South', 'Kagarko', 'Kajuru', 'Kaura', 'Kauru', 'Kubau', 'Kudan', 'Lere', 'Makarfi', 'Sabon Gari', 'Sanga,', 'Soba', 'Zangon', 'Kataf', 'Zaria']

        }
        else if (state === "Kano") {



            return ['Select area...','Ajingi', 'Albasu', 'Bagwai', 'Bebeji', 'Bichi', 'Bunkure', 'Dala, Kano', 'Dambatta', 'Dawakin Kudu', 'Dawakin Tofa', 'Doguwa', 'Fagge', 'Gabasawa', 'Garko', 'Garun Mallam', 'Gaya', 'Gezawa', 'Gwale', 'Gwarzo', 'Garo', 'Kabo', 'Kano Municipal', 'Karaye', 'Kibiya', 'Kiru', 'Kumbotso', 'Kunchi', 'Kura', 'Madobi', 'Makoda', 'Minjibir', 'Nasarawa', 'Rano', 'Rimin Gado', 'Rogo', 'Shanono', 'Sumaila', 'Takai', 'Tarauni', 'Tofa', 'Tsanyawa', 'Tudun Wada', 'Ungogo', 'Warawa', 'Wudil']

        }
        else if (state === "Katsina") {



            return ['Select area...','Bakori', 'Batagarawa', 'Batsari', 'Baure', 'Bindawa', 'Charanchi', 'Dan Musa', 'Dandume', 'Danja', 'Daura', 'Dutsin-Ma', 'Faskari', 'Funtua', 'Ingawa', 'Jibia', 'Kafur', 'Kaita', 'Kankara', 'Kankia', 'Katsina', 'Kurfi', 'Kusada', 'Mai Adua', 'Malumfashi', 'Mani', 'Mashi', 'Matazu', 'Musawa', 'Rimi', 'Sabuwa', 'Safana', 'Sandamu', 'Tsagem', 'Zango']

        }
        else if (state === "Nasarawa") {



            return ['Select area...','Karu', 'Akwanga', 'Awe', 'Keffi', 'Nasarawa', 'Egon', 'Doma', 'Kokona', 'Wamba', 'Keana', 'Nasarawa', 'Lafia', 'Toto', 'Obi']

        }
        else if (state === "Kebbi") {



            return ['Select area...','Aleiro', 'Arewa Dandi', 'Argungu', 'Augie', 'Bagudo', 'Birnin Kebbi', 'Bunza', 'Dandi', 'Fakai', 'Gwandu', 'Jega', 'Kalgo', 'Koko/Besse', 'Maiyama', 'Ngaski', 'Sakaba', 'Shanga', 'Suru', 'Wasagu/Danko', 'Yauri', 'Zuru']

        }
        else if (state === "Kogi") {



            return ['Select area...','Adavi', 'Ajaokuta', 'Ankpa', 'Bassa', 'Dekina', 'Ibaji', 'Idah', 'Igalamela-Odolu', 'Ijumu', 'Kabba/Bunu', 'Lokoja', 'Mopa-Muro', 'Ofu', 'Okehi', 'Okene', 'Olamaboro', 'Omala', 'Yagba East', 'Yagba West']

        }

        else if (state === "Kwara") {



            return ['Select area...','Asa', 'Baruten', 'Edu', 'Ekiti', 'Ifelodun', 'Ilorin East', 'Ilorin South', 'Ilorin West', 'Irepodun,', 'Isin', 'Kaiama', 'Moro', 'Offa', 'Oke Ero', 'Oyun', 'Pategi']

        }

        else if (state === "Plateau") {



            return ['Select area...','Barkin Ladi', 'Bassa', 'Bokkos', 'Jos East', 'Jos North', 'Jos South', 'Kanam', 'Kanke', 'Langtang North', 'Langtang South', 'Mangu, Nigeria', 'Mikang', 'Pankshin', 'Quaan Pan', 'Riyom', 'Shendam', 'Wase']

        }

        else if (state === "Taraba") {



            return ['Select area...','Ardo Kola', 'Bali', 'Donga', 'Gashaka', 'Gassol', 'Ibi', 'Jalingo', 'Karim Lamido', 'Kurmi', 'Lau', 'Sardauna', 'Takum', 'Ussa', 'Wukari', 'Yorro', 'Zing']

        }
        else if (state === "Yobe") {



            return ['Select area...','Bursari', 'Damaturu', 'Geidam', 'Bade', 'Gujba', 'Gulani', 'Fika', 'Fune', 'Karasuwa', 'Machina', 'Nangere', 'Nguru', 'Potiskum', 'Tarmuwa', 'Yunusari', 'Yusufari']

        }
        else if (state === "Zamfara") {



            return ['Select area...','Anka', 'Bakura', 'Birnin Magaji/Kiyaw', 'Bukkuyum', 'Bungudu', 'Tsafe', 'Gummi', 'Gusau', 'Kaura Namoda', 'Maradun', 'Maru', 'Shinkafi', 'Talata Mafara', 'Zurmi']

        }
        else if (state === "Sokoto") {



            return ['Select area...','Binji', 'Bodinga', 'Dange Shuni', 'Gada', 'Goronyo', 'Gudu', 'Gwadabawa', 'Illela', 'Isa', 'Kebbe', 'Kware', 'Rabah', 'Sabon Birni', 'Shagari', 'Silame', 'Sokoto North', 'Sokoto South', 'Tambuwal', 'Tangaza', 'Tureta', 'Wamako', 'Wurno', 'Yabo']

        }
        else if (state === "Abuja") {



            return ['Select area...','Abaji', 'Abuja', 'Gwagwalada', 'Kuje', 'Kwali Area Council']

        }
        else if (state === "Abia") {



            return ['Select area...','Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Ikwuano', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma Ngwa', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi']

        }
        else if (state === "Akwa Ibom") {



            return ['Select area...','Abak', 'Eastern Obolo', 'Eket', 'Esit Eket', 'Essien Udim', 'Etim Ekpo', 'Etinan', 'Ibeno', 'Ibesikpo Asutan', 'Ibiono-Ibom', 'Ika, Akwa Ibom', 'Ikono', 'Ikot Ekpene', 'Ikot-Abasi', 'Ini, Akwa Ibom', 'Itu, Akwa Ibom', 'Mbo, Akwa Ibom', 'Mkpat-Enin', 'Nsit-Atai', 'Nsit-Ibom', 'Nsit-Ubium', 'Obot-Akara', 'Okobo, Akwa Ibom', 'Onna', 'Oron, Akwa Ibom', 'Oruk Anam', 'Udung-Uko', 'Ukanafun', 'Uruan', 'Urue-Offong/Oruko', 'Uyo']

        }
        else if (state === "Anambra") {



            return ['Select area...','Aguata', 'Anambra East', 'Anambra West', 'Anaocha', 'Awka North', 'Awka South', 'Ayamelum', 'Dunukofia', 'Ekwusigo', 'Idemili North', 'Idemili South', 'Ihiala', 'Njikoka', 'Nnewi North', 'Nnewi South', 'Ogbaru', 'Onitsha North', 'Onitsha South', 'Orumba North', 'Orumba South']

        }
        else if (state === "Bayelsa") {



            return ['Select area...','Brass, Nigeria', 'Ekeremor', 'Kolokuma/Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa']

        }
        else if (state === "Bayelsa") {



            return ['Select area...','Ado, Benue', 'Agatu', 'Apa, Nigeria', 'Buruku', 'Gboko', 'Guma, Nigeria', 'Gwer East', 'Gwer West', 'Katsina-Ala', 'Konshisha', 'Kwande', 'Logo, Nigeria', 'Makurdi', 'Obi, Nigeria', 'Ogbadibo', 'Ohimini', 'Oju, Nigeria', 'Okpokwu', 'Otukpo', 'Tarka, Nigeria', 'Ukum', 'Ushongo', 'Vandeiky']

        }
        else if (state === "Cross Rivers") {



            return ['Select area...','kom', 'Akamkpa', 'Etung', 'Obanliku', 'Calabar South', 'Biase', 'Akpabuyo', 'Calabar Municipal', 'Ogoja', 'Odukpani', 'Obudu', 'Yakuur', 'Obubra', 'Boki', 'Bekwarra', 'Yala', 'Yakurr']

        }
        else if (state === "Delta") {



            return ['Select area...','Ndokwa East', 'Ughelli South', 'Ethiope East', 'Ndokwa West', 'Oshimili South', 'Ethiope West', 'Isoko North', 'Udu, Nigeria', 'Ika South', 'Ughelli North', 'Aniocha North', 'Warri North', 'Aniocha South', 'Ika North East', 'Uvwie', 'Oshimili North', 'Patani, Delta', 'Warri South West', 'Warri']

        }
        else if (state === "Ebonyi") {



            return ['Select area...','Ikwo', 'Ohaukwu', 'Onicha', 'Afikpo North', 'Ishielu', 'Ezza South', 'Ezza North', 'Ohaozara', 'Ivo, Ebonyi', 'Ebonyi, Ebon']

        }
        else if (state === "Edo") {



            return ['Select area...','Uhunmwonde', 'Oredo', 'Etsako West', 'Igueben', 'Ovia North-East', 'Etsako Central', 'Egor', 'Ovia South-West', 'Esan South-East', 'Esan West', 'Esan Central', 'Owan West', 'Ikpoba Okha', 'Orhionmwon', 'Owan East']

        }
        else if (state === "Ekiti") {



            return ['Select area...','Ekiti East', 'Ekiti West', 'Ekiti South-West', 'Ise/Orun', 'Ido-Osi', 'Gbonyin', 'Oye', 'Ilejemeje', 'Irepodun/Ifelodun', 'Moba']

        }
        else if (state === "Enugu") {



            return ['Select area...','Nkanu East', 'Enugu North', 'Ezeagu', 'Isi Uzo', 'Aninri', 'Uzo Uwani', 'Udenu', 'Igbo Eze North', 'Enugu South', 'Igbo Eze South', 'Enugu East', 'Igbo Etiti']

        }
        else if (state === "Imo") {



            return ['Select area...','Ikeduru', 'Mgbidi', 'Nwangele', 'Ngor Okpala', 'Obowo', 'Ehime Mbano', 'Ezinihitte-Mbaise', 'Ahiazu Mbaise', 'Oru West', 'Orsu', 'Owerri Municipal', 'Ohaji/Egbema', 'Ihitte/Uboma', 'Owerri West', 'Owerri North', 'Isiala Mbano', 'Ideato North']

        }
        else if (state === "Lagos") {



            return ['Select area...','Alimosho', 'Ajeromi-Ifelodun', 'Kosofe', 'Mushin', 'Oshodi-Isolo', 'Ojo', 'Ikorodu', 'Surulere', 'Agege', 'Ifako-Ijaiye', 'Shomolu', 'Amuwo-Odofin', 'Lagos Mainland', 'Ikeja', 'Eti-Osa', 'Badagry', 'Apapa', 'Lagos Island', 'Epe', 'Ibeju-Lekki']

        }
        else if (state === "Ogun") {



            return ['Select area...','Ogun Waterside', 'Ijebu North', 'Yewa South', 'Yewa North', 'Obafemi Owode', 'Ewekoro', 'Remo North', 'Ijebu North East', 'Ijebu East', 'Imeko Afon', 'Abeokuta North']

        }
        else if (state === "Ondo") {



            return ['Select area...','Odigbo', 'Ondo West', 'Ifedore', 'Ese Odo', 'Akure North', 'Ilaje', 'Akoko North-East', 'Ose, Nigeria', 'Ondo East']

        }
        else if (state === "Osun") {



            return ['Select area...','Boripe', 'Ola Oluwa', 'Orolu', 'Ede North', 'Ife North', 'Atakunmosa East', 'Ife South', 'Ilesa West', 'Ife East', 'Obokun', 'Irepodun', 'Olorunda', 'Isokan', 'Irewole', 'Aiyedire', 'Ede South', 'Odo Otin', 'Aiyedaade', 'Ifedayo', 'Boluwaduro', 'Ilesa East', 'Ife Central', 'Egbedore', 'Atakunmosa West', 'Ifelodun', 'Ila']

        }
        else if (state === "Oyo") {
            return ['Select area...','Oluyole', 'Surulere', 'Atisbo', 'Ibadan North', 'Ibadan North-West', 'Ona Ara', 'Akinyele', 'Ibarapa Central', 'Oyo West', 'Saki East', 'Kajola', 'Saki West', 'Itesiwaju', 'Ogo Oluwa', 'Olorunsogo', 'Ogbomosho North', 'Ibadan South-West', 'Ibadan South-East', 'Ibarapa East', 'Lagelu, Oyo', 'Orelope', 'Iwajowa', 'Oyo East', 'Ibarapa North', 'Irepo', 'Atiba', 'Ogbomosho South', 'Ibadan North-East', 'Idogz', 'Ori Ire']

        }
        else if (state === "Rivers") {
            return ['Select area...','Abua Odual', 'Ahoada East', 'Ahoada West', 'Akuku Toru', 'Andoni', 'Asari Toru', 'Bonny', 'Degema', 'Eleme', 'Emuoha', 'Etche', 'Gokana', 'Ikwerre', 'Khana', 'Obio/Akpor', 'Ogba Egbema Ndoni', 'Ogu Bolo', 'Okrika', 'Omumma', 'Opobo', 'Oyigbo', 'Port-Harcourt', 'Tai']

        }
        else {
            return [];
        }
    }
}
const location = new Location();
export { location };
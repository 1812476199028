import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let unitService = {
  getUnit: async () => {
    return await http.get(`${util.endPoint}/unit`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getUnitLogs: async () => {
    return await http.get(`${util.endPoint}/unit/logs`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getUnitWithdrawalLogs: async () => {
    return await http.get(`${util.endPoint}/unit/withdrawal/requests`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getUnitAnalytics: async () => {
    return await http.get(`${util.endPoint}/report/unit/analytics`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  withdrawFunds: async (body) => {
    return await http.post(`${util.endPoint}/unit/withdraw`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateWithdrawalStatus: async (body) => {
    return await http.put(`${util.endPoint}/unit/withdraw/status`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  fundsTransfer: async (body) => {
    return await http.post(`${util.endPoint}/unit/transfer`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { unitService };

import React from "react";
const OptionField = ({ label, children=[], required = false, option, ...props }) => (
  <div className="form-group">
    {label && (
      <label htmlFor="input-field">
        {label}  <span className="required-star">{required ? '*' : ''}</span>
        <p
          className="text-xs text-danger float-right"
          style={{ marginTop: "-5px" }}
        >
          {option}
        </p>
      </label>  
    )}
    <select {...props} className="form-control" id="exampleFormControlSelect1">
      {
      children.map((item, i) => {
        if(typeof item === 'object'){
          return <option key={i} value={item.id}>{item.name}</option>
        } else {
          return <option key={i} value={item}>{item}</option>
        }
       
      })}
    </select>
  </div>
);
export default OptionField;

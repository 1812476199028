import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/logoWhite.png';

const SignNavBar = () => {
  return (
    <div className="sign-header">
      <Link to="/">
        <img src={Logo} alt="images" />
      </Link>
    </div>
  );
};

export default SignNavBar;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/atlantis.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/demo.css";
import "./assets/css/fonts.css";
import "./assets/css/fonts.min.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Access from "./Access";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Access></Access>
  </BrowserRouter>
);
reportWebVitals();

import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { businessService } from '../../service/business.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TinyLoader from './components/loaders/TinyLoader';

const AllBusinessType = () => {
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [businessTypeProgress, setBusinessTypeProgress] = useState(false);
  const [openBusinessTypeModal, setOpenBusinessTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [businessTypeInput, setBusinessTypeInput] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    getAllBusinessTypes();
  }, []);

  const getAllBusinessTypes = () => {
    setInProgress(true);
    businessService
      .getAllBusinessType()
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createBusinessType = () => {
    if (businessTypeInput.title === '' || businessTypeInput.title.length < 3)
      toast.error('Provide valid title');
    else if (
      businessTypeInput.description === '' ||
      businessTypeInput.description.length < 3
    )
      toast.error('Valid description required');

    if (
      businessTypeInput.title !== '' &&
      businessTypeInput.title.length >= 3 &&
      businessTypeInput.description !== '' &&
      businessTypeInput.description.length >= 3
    ) {
      const request = {
        title: businessTypeInput.title,
        description: businessTypeInput.description,
      };
      setBusinessTypeProgress(true);
      businessService.createBusinessType(request)
        .then((result) => {
          setOpenBusinessTypeModal(false);
          setBusinessTypeProgress(false);
          if (result.data.success) {
            toast.success('Created successful');
            getAllBusinessTypes();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenBusinessTypeModal(false);
          setBusinessTypeProgress(false);
        });
    }
  };

  const performDelete = () => {
    if (selectedBusiness) {
      setDeleteProgress(true);
      businessService.deleteBusinessType(selectedBusiness.psuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success && result.data.data.deletedCount > 0) {
            toast.success('Deleted successful');
          } else {
            toast.error('Unable to delete business type');
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error('No business type selected');
    }
  };

  const closeBusinessTypeModal = () => {
    setOpenBusinessTypeModal(false);
  };

  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openBusinessTypeModal}>
          <DialogTitle>Add Business Type</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="title"
              value={businessTypeInput.title}
              placeholder="Title"
              label="Title"
              onChange={(e) =>
                setBusinessTypeInput({
                  ...businessTypeInput,
                  title: e.target.value,
                })
              }
              required
            />

            <InputField
              name="description"
              label="Description"
              placeholder="Description"
              value={businessTypeInput.description}
              onChange={(e) =>
                setBusinessTypeInput({
                  ...businessTypeInput,
                  description: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={closeBusinessTypeModal}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={!businessTypeProgress ? createBusinessType : null}
              className="btn btn-primary btn-round"
            >
              {businessTypeProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteModal}>
          <DialogTitle>Delete Type</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this business type?</p>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : 'Yes'}
            </button>
          </DialogActions>
        </Dialog>
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Business Types</h4>

                    <button
                      type="button"
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setBusinessTypeInput({
                          title: '',
                          description: '',
                        });
                        setOpenBusinessTypeModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Add Business Type
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {inProgress ? (
                        <Loader></Loader>
                      ) : response.length > 0 ? (
                        <table
                          id="add-row"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>S/n</th>
                              <th>Type</th>
                              <th>Description</th>
                              <th>Type ID</th>
                              <th>Status</th>
                              <th>Date Added</th>
                              <th style={{ width: '10%' }}>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {response.map((business, index) => {
                              return (
                                <tr key={business.ruid}>
                                  <td>{++index}</td>
                                  <td>{business.title}</td>
                                  <td>{business.description}</td>
                                  <td>{business.btuid}</td>
                                  <td
                                    className={
                                      business.status === 'ACTIVE'
                                        ? 'text-success'
                                        : 'text-danger'
                                    }
                                  >
                                    {business.status}
                                  </td>
                                  <td>{format(business.createdAt)}</td>

                                  <td>
                                    <div className="form-button-action">
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn  btn-danger"
                                        onClick={() => {
                                          setSelectedBusiness(business);
                                          setOpenDeleteModal(true);
                                        }}
                                        data-original-title="Remove"
                                      >
                                        <i className="  fa fa-times"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center message-box">
                          <p>No business type found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default AllBusinessType;

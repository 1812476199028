import React, { useState, useEffect } from 'react';
import OtherPageBody from './OtherPageBody';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { titleCase } from 'title-case';
import { inventoryService } from '../../service/inventory.service';
import Loader from './components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import OptionField from './components/select/OptionField';
import { serviceService } from '../../service/service.service';
import toMoney from 'number-to-money';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TinyLoader from './components/loaders/TinyLoader';

const InventoryServices = () => {
  const [allInventories, setAllInventories] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inProgress, setInProgress] = useState(true);
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const [newServiceInput, setNewServiceInput] = useState({
    title: '',
    type: '',
    charges: 0,
    revenue: 0,
    description: '',
  });
  const [serviceTypeInput, setServiceTypeInput] = useState({
    title: '',
    description: '',
  });
  const [serviceInputProgress, setServiceInputProgress] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateProgress, setOpenUpdateProgress] = useState(false);
  const [serviceTypeProgress, setServiceTypeProgress] = useState(false);
  const [openServiceTypeModal, setOpenServiceTypeModal] = useState(false);
  const [serviceType, setServiceType] = useState(['Select service type...']);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const getInventoryServices = () => {
    setInProgress(true);
    inventoryService
      .getInventoryServices(currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          console.log(result);
          const { page } = result.data.data;
          const { data } = result.data;
          setAllInventories(page);
          if (page.length > 0) {
            setTotalPage(data.totalPages);
            setCurrentPage(data.currentPage);
          }
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createNewService = () => {
    if (newServiceInput.title === '' || newServiceInput.title.length < 2)
      toast.error('Provide valid title');
    else if (
      newServiceInput.type === '' ||
      newServiceInput.type === 'Select service type...'
    )
      toast.error('Valid type required');
    else if (newServiceInput.charges <= 0)
      toast.error('Valid charges required');
    if (
      newServiceInput.title !== '' &&
      newServiceInput.title.length >= 2 &&
      newServiceInput.type !== '' &&
      newServiceInput.type !== 'Select service type...' &&
      newServiceInput.charges > 0
    ) {
      const request = {
        title: newServiceInput.title,
        description: 'N/A',
        type: newServiceInput.type,
        charges: Number(newServiceInput.charges),
        revenue: Number(newServiceInput.revenue),
      };
      setServiceInputProgress(true);
      inventoryService
        .createService(request)
        .then((result) => {
          setOpenServiceModal(false);
          setServiceInputProgress(false);
          if (result.data.success) {
            toast.success('Created Successfully');
            getInventoryServices();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.message
              ? Array.isArray(e.response?.data?.message)
                ? e.response?.data?.message.join(' ')
                : e.response?.data?.message
              : e.message,
          );
          setServiceInputProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      setInProgress(true);
      inventoryService
        .searchSales(currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setAllInventories(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setAllInventories([]);
          }
        })
        .catch((e) => {
          setAllInventories([]);
          setInProgress(false);
        });
    }
  };

  const updateService = () => {
    const request = {
      title: newServiceInput.title,
      description: 'N/A',
      charges: Number(newServiceInput.charges),
      revenue: Number(newServiceInput.revenue),
    };
    if (selectedService) {
      inventoryService
        .updateService(selectedService.suid, request)
        .then((result) => {
          setOpenUpdateModal(false);
          setOpenUpdateProgress(false);
          if (result.data.success) {
            toast.success('Updated successful');
            getInventoryServices();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenUpdateModal(false);
          setOpenUpdateProgress(false);
        });
    } else {
      toast.error('No service selected');
    }
  };

  const closeServiceInputModal = () => {
    setOpenServiceModal(false);
  };

  const closeUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getInventoryServices();
  };

  const getAllServiceType = () => {
    serviceService
      .getAllServiceType()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          const list = ['Select Type...'];
          resources.forEach((resource) => {
            list.push(resource.title);
          });
          setServiceType(list);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  const createServiceType = () => {
    if (serviceTypeInput.title === '' || serviceTypeInput.title.length < 3)
      toast.error('Provide valid title');

    if (serviceTypeInput.title !== '' && serviceTypeInput.title.length >= 3) {
      const request = {
        title: serviceTypeInput.title,
        category: serviceTypeInput.category,
        description: 'N/A',
      };
      setServiceTypeProgress(true);

      serviceService
        .createServiceType(request)
        .then((result) => {
          setOpenServiceTypeModal(false);
          setServiceTypeProgress(false);
          if (result.data.success) {
            toast.success('Created successful');
            getAllServiceType();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenServiceTypeModal(false);
          setServiceTypeProgress(false);
        });
    }
  };

  const closeServiceTypeModal = () => {
    setOpenServiceTypeModal(false);
  };

  useEffect(() => {
    getInventoryServices();
    getAllServiceType();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openServiceModal}>
        <DialogTitle>Add New Service</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={newServiceInput.title}
            placeholder="Service"
            label="Service Title"
            onChange={(e) =>
              setNewServiceInput({
                ...newServiceInput,
                title: e.target.value,
              })
            }
            required
          />

          <div className="row">
            <div className="col">
              <OptionField
                children={serviceType}
                label="Service Type"
                name="type"
                onChange={(e) => {
                  console.log(e.target.value);
                  setNewServiceInput({
                    ...newServiceInput,
                    type: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <InputField
                type="number"
                name="charges"
                value={newServiceInput.charges}
                placeholder="Amount"
                label="Service Charge"
                onChange={(e) =>
                  setNewServiceInput({
                    ...newServiceInput,
                    charges: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="col">
              <InputField
                type="number"
                name="revenue"
                value={newServiceInput.revenue}
                placeholder="Service Revenue"
                label="Service Income"
                onChange={(e) =>
                  setNewServiceInput({
                    ...newServiceInput,
                    revenue: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeServiceInputModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!serviceInputProgress ? createNewService : null}
            className="btn btn-primary btn-round"
          >
            {serviceInputProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdateModal}>
        <DialogTitle>Update Service</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={newServiceInput.title}
            placeholder="Title"
            label="Title"
            onChange={(e) =>
              setNewServiceInput({
                ...newServiceInput,
                title: e.target.value,
              })
            }
          />

          <InputField
            type="number"
            name="charges"
            value={newServiceInput.charges}
            placeholder="Amount"
            label="Service Charge"
            onChange={(e) =>
              setNewServiceInput({
                ...newServiceInput,
                charges: e.target.value,
              })
            }
            required
          />

          <InputField
            type="number"
            name="revenue"
            value={newServiceInput.revenue}
            placeholder="Revenue"
            label="Service Income"
            onChange={(e) =>
              setNewServiceInput({
                ...newServiceInput,
                revenue: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeUpdateModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!serviceInputProgress ? updateService : null}
            className="btn btn-primary btn-round"
          >
            {serviceInputProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openServiceTypeModal}>
        <DialogTitle>Add Type</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={serviceTypeInput.title}
            placeholder="Provide service type title e.g POS"
            label="Type"
            onChange={(e) =>
              setServiceTypeInput({
                ...serviceTypeInput,
                title: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeServiceTypeModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!serviceTypeProgress ? createServiceType : null}
            className="btn btn-primary btn-round"
          >
            {serviceTypeProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner overflow-y-auto">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Manage Services</h4>

                  <SearchField
                    value={searchText}
                    placeholder="Search services e.g POS or Barbing saloon"
                    onChange={onSearch}
                  />
                  <Link
                    to="/inventory/transaction"
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                    Create Transaction
                  </Link>
                </div>
              </div>
            </div>
            <div
              style={{ gap: '10px' }}
              className="d-flex align-items-start mt-0 group-action"
            >
              <button
                onClick={() => {
                  setServiceTypeInput({
                    title: '',
                    description: '',
                  });
                  setOpenServiceTypeModal(true);
                }}
                className="btn default-btn btn-secondary btn-round p-2"
              >
                Create Service Type
              </button>

              <button
                onClick={() => {
                  setNewServiceInput({
                    title: '',
                    type: '',
                    charges: 0,
                    revenue: 0,
                    description: '',
                  });
                  setOpenServiceModal(true);
                }}
                className="btn default-btn btn-round p-2"
              >
                Add New Service
              </button>
              <button
                onClick={() => {
                  navigate('/inventory/transactions');
                }}
                className="btn default-btn btn-success  btn-round p-2"
              >
                All transactions
              </button>
            </div>
            <br />
            <div className="row data-wrapper">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : allInventories.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="table-responsive table-hover">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Charges</th>
                            <th>Income</th>
                            <th>Description</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th style={{ width: '10%' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allInventories.map((inventory, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>
                                  <strong>{titleCase(inventory.title)}</strong>
                                </td>
                                <td>{inventory.type}</td>
                                <td>₦{toMoney(inventory.charges)}</td>
                                <td>₦{toMoney(inventory.revenue)}</td>
                                <td>{inventory.description}</td>
                                <td>{format(inventory.createdAt)}</td>
                                <td
                                  className={
                                    inventory.status === 'AVAILABLE'
                                      ? 'text-primary'
                                      : 'text-danger'
                                  }
                                >
                                  {inventory.status}
                                </td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-secondary "
                                      data-original-title="Edit Task"
                                      onClick={() => {
                                        setNewServiceInput({
                                          title: inventory.title,
                                          charges: inventory.charges,
                                          revenue: inventory.revenue,
                                          type: inventory.type,
                                          description: inventory.description,
                                        });
                                        setSelectedService(inventory);
                                        setOpenUpdateModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i> Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  allInventories.map((inventory, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    {inventory.code}
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {inventory.type} {inventory.title}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      Charges:{' '}
                                      <span className="text-danger">
                                        ₦{toMoney(inventory.charges)}
                                      </span>
                                    </li>
                                    <li>
                                      Revenue:{' '}
                                      <span className="text-success">
                                        {' '}
                                        ₦{toMoney(inventory.revenue)}
                                      </span>
                                    </li>
                                    <li>
                                      Status :{' '}
                                      <span className="text-success">
                                        {inventory.status}
                                      </span>
                                    </li>
                                    <li>
                                      Created : {format(inventory.createdAt)}
                                    </li>
                                  </ul>

                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary  "
                                      data-original-title="Edit Task"
                                      onClick={() => {
                                        setNewServiceInput({
                                          title: inventory.title,
                                          charges: inventory.charges,
                                          revenue: inventory.revenue,
                                          type: inventory.type,
                                          description: inventory.description,
                                        });
                                        setSelectedService(inventory);
                                        setOpenUpdateModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i> Edit
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>
                      {' '}
                      {searchText && searchText.length >= 3
                        ? 'Search not found'
                        : 'No service found'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(-1)}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((e, i) => (
                <li className="page-item" key={i}>
                  <button
                    className={
                      i === currentPage
                        ? 'page-link btn-primary text-white'
                        : 'page-link'
                    }
                    onClick={() => changePage(i)}
                  >
                    {i}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(+1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </OtherPageBody>
  );
};

export default InventoryServices;
